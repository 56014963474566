import { keyframes } from '@emotion/react';

const linkKeyFrames = keyframes({ from: { opacity: 0 }, to: { opacity: 1 } });

export default {
  container: {
    zIndex: 101,
    width: '250px',
    pt: '25px',
    transition: '0.5s',
    px: '10px',
    '@media screen and (max-width: 1023px)': {
      position: 'absolute',
      bottom: 0,
      top: '60px',
      flex: 1,
    },
    '&.closed': {
      width: [0, null, null, null, '60px'],
      px: [0, null, null, null, '5px'],
    },
    flexDirection: 'column',
    // boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
  },
  link: {
    color: 'text_secondary',
    textDecoration: 'none',
    transition: '0.5s',
    container: {
      alignItems: 'center',
      justifyContent: 'flex-start',
      py: '5px',
      '&:hover': {
        bg: 'header_sidebar_hover',
        borderRadius: '5px',
        pt: '5px',
      },
    },
    label: {
      whiteSpace: 'nowrap',
      color: 'text',
      textDecoration: 'none',
      animation: `${linkKeyFrames} 0.6s linear  alternate`,
    },
    icon: {
      color: 'text',
      zIndex: 10,
      cursor: 'pointer',
      mx: '0.5rem',
    },
  },
};
