export default {
  spinnerBox: {
    my: '150px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerBox: {
    display: 'flex',
    flexDirection: 'column',
    p: 4,
    my: 2,
    justifyContent: 'center',
    flexWrap: ['wrap', null, null, 'nowrap'],
  },
  title: {
    textAlign: 'center',
  },
  grid: {},
  inputBox: {
    mb: 3,
  },
  inputField: {
    height: '45px',
    '-webkit-text-fill-color': (t) => `${t.colors.text}`,
    '&:-webkit-autofill': {
      '-webkit-text-fill-color': (t) => `${t.colors.text}`,
    },
    '&:-webkit-autofill:focus': {
      '-webkit-text-fill-color': (t) => `${t.colors.text}`,
    },
  },
  submitBox: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    mt: 3,
  },
  credentialErrorBox: {
    alignSelf: 'center',
  },
  credentialErrorText: {
    fontSize: '14px',
    fontWeight: 'heading',
    mb: '1em',
  },
  errorBox: {
    flexDirection: 'row-reverse',
  },
  successfulText: {
    mx: 1,
    fontSize: 1,
    color: 'text',
  },
  inputContainer: {
    alignItems: 'center',
    position: 'relative',
  },
  cardContainer: {
    borderRadius: '5px',
    p: '10px',
    border: (t) => `0.5px solid ${t.colors.border_color}`,
  },
  cardLabel: {
    mb: 3,
  },
  cardElement: {
    base: {
      fontSize: '32px',
    },
  },
  iconButton: {
    position: 'absolute',
    right: '1rem',
    color: 'text',
    icon: {
      size: '2rem',
      alignSelf: 'center',
    },
  },
  spinnerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    width: null,
    alignSelf: 'center',
  },
  disabledButton: {
    width: null,
    alignSelf: 'center',
    bg: 'button_bg_pressed',
    cursor: 'not-allowed',
    color: 'text_disabled',
    '&:hover': {
      bg: 'button_bg_pressed',
      boxShadow: '0 0 0 transparent',
      border: (t) => `0.5px solid ${t.colors.border_color}`,
    },
  },
};

export const cardElementOptions = {
  style: {
    base: {
      iconColor: '#000000',
      color: '#000000',
      fontWeight: '500',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#373737',
      },
      '::placeholder': {
        color: '#999999',
      },
    },
    invalid: {
      iconColor: 'error',
      color: 'error',
    },
  },
};

export const darkCardElementOptions = {
  style: {
    base: {
      iconColor: '#FFFFFF',
      color: '#FFFFFF',
      fontWeight: '500',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#DADADA',
      },
      '::placeholder': {
        color: '#999999',
      },
    },
    invalid: {
      iconColor: 'error',
      color: 'error',
    },
  },
};
