export default {
  NEW: '#9B6C51',
  CANCELLED: '#870000',
  REJECTED: '#870000',
  PROPOSED_TIME: '#999999',
  ASKED_NEW_TIME: '#9B6C51',
  PROPOSED_NEW_TIME: '#999999',
  ACCEPTED: '#607D8B',
  APPRAISAL: '#F9E389',
  SERVICE_CONFIRMATION_ASKED: '#F16D22',
  SERVICE_ACCEPTED: '#F4C714',
  SERVICE_REJECTED: '#870000',
  READY: '#6BB5A2',
  FINISHED: 'text',
  DELETED: '#575757',
};
