import PropTypes from 'prop-types';
import { Button, Card, Divider, Flex, Text } from 'theme-ui';

import { SERVICE_SCOPES, STRINGS } from '../../../constants';
import styles from './ServiceCartCard.style';

const ServiceCartCard = (props) => {
  const {
    serviceCart,
    isEditable,
    isAdditionalServicesEditable,
    showAdditionalServices,
    showPrices,
    editServiceCart,
    showAppraisal,
    rejectedServiceScopeStatus,
    serviceScopeStatus,
    isPaid,
    cardStyle,
  } = props;
  const { fee, price, priceWithFee } = serviceCart ?? {};
  const localeString = 'pl';
  const localeCurrency = 'PLN';

  const getServiceCartItems = (items, additional = false) => {
    if (showAdditionalServices) {
      if (additional) {
        return items.filter((item) => {
          if (item.isAccepted === null || item.isAccepted === false) {
            return true;
          }
          return false;
        });
      }
      return items.filter((item) => {
        if (item.isAccepted === true) {
          return true;
        }
        return false;
      });
    }
    return items;
  };

  const serviceCartItems = getServiceCartItems(serviceCart.items);

  const emptyPrice = () => {
    return <Text sx={styles.emptyPriceText}>-</Text>;
  };

  const renderPerItemInfo = (formattedPriceEach, isAccepted) => {
    if (isAccepted === false) {
      return <Text sx={styles.itemRejectedText}>{STRINGS.visit.rejectedCartItem}</Text>;
    }
    if (showPrices) {
      return <Text sx={styles.itemQuantityResultText}>{formattedPriceEach}</Text>;
    }
    return emptyPrice();
  };

  const convertPrice = (data) => {
    return data
      ? new Intl.NumberFormat(localeString, { style: 'currency', currency: localeCurrency }).format(
          parseFloat(data),
        )
      : undefined;
  };

  const renderItem = (
    id,
    name,
    quantity,
    priceEachWithFee,
    priceTotalWithFee,
    strikeThrough = false,
    isAccepted = null,
  ) => {
    const formattedPriceEachWithFee = convertPrice(priceEachWithFee);
    const formattedPriceTotalWithFee = convertPrice(priceTotalWithFee);
    return (
      <Flex key={id} sx={styles.cartItemContainer}>
        <Flex sx={styles.itemRowContainer}>
          <Flex sx={styles.itemNameContainer}>
            <Text sx={strikeThrough ? styles.strikeThroughItemNameText : styles.itemNameText}>
              {name}
            </Text>
          </Flex>
          <Flex>
            {showPrices ? (
              <Text sx={strikeThrough ? styles.strikeThroughItemText : styles.itemText}>
                {formattedPriceTotalWithFee}
              </Text>
            ) : (
              emptyPrice()
            )}
          </Flex>
        </Flex>
        <Flex sx={styles.itemDetailsRowsOuterContainer}>
          <Flex sx={styles.itemDetailsRowContainer}>
            {isAccepted !== false && (
              <Text sx={styles.itemQuantityText}>{STRINGS.visit.pricePerItem}</Text>
            )}
            {renderPerItemInfo(formattedPriceEachWithFee, isAccepted)}
          </Flex>
          <Flex sx={styles.itemDetailsRowContainer}>
            <Text sx={styles.itemQuantityText}>{STRINGS.visit.quantity}</Text>
            <Text sx={styles.itemQuantityResultText}>{quantity}</Text>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const renderHeader = () => {
    return (
      <Flex sx={styles.itemHeaderContainer}>
        <Text sx={styles.headerText}>{STRINGS.visit.item}</Text>
        <Text sx={styles.headerText}>{STRINGS.visit.price}</Text>
      </Flex>
    );
  };

  const renderSummary = () => {
    const formattedPriceWithFee = new Intl.NumberFormat(localeString, {
      style: 'currency',
      currency: localeCurrency,
    }).format(priceWithFee);
    const formattedPrice = new Intl.NumberFormat(localeString, {
      style: 'currency',
      currency: localeCurrency,
    }).format(price);
    const formattedFee = new Intl.NumberFormat(localeString, {
      style: 'percent',
      minimumFractionDigits: 2,
    }).format(fee);

    return (
      <Flex sx={styles.summaryContainer}>
        <Flex sx={styles.itemRowContainer}>
          <Flex>
            <Text sx={styles.subHeaderText}>{STRINGS.visit.sumWorkshop}</Text>
          </Flex>
          <Flex>
            <Text
              sx={
                rejectedServiceScopeStatus
                  ? styles.strikeThroughSubHeaderText
                  : styles.subHeaderText
              }
            >
              {formattedPrice}
            </Text>
          </Flex>
        </Flex>

        <Flex sx={styles.itemRowContainer}>
          <Flex>
            <Text sx={styles.itemText}>{STRINGS.visit.fee}</Text>
          </Flex>
          <Flex>
            <Text sx={styles.itemText}>{formattedFee}</Text>
          </Flex>
        </Flex>
        <Divider />

        <Flex sx={styles.itemRowContainer}>
          <Flex>
            <Text sx={styles.sumTotalText}>
              {isPaid ? STRINGS.visit.sumTotalHistory : STRINGS.visit.sumTotal}
            </Text>
          </Flex>
          <Flex>
            <Text
              sx={
                rejectedServiceScopeStatus ? styles.strikeThroughSumTotalText : styles.sumTotalText
              }
            >
              {formattedPriceWithFee}
            </Text>
          </Flex>
        </Flex>
        <Text sx={styles.sumDescriptionText}>{STRINGS.visit.sumDescription}</Text>
      </Flex>
    );
  };

  const renderAddButton = () => {
    if (isEditable) {
      return (
        <Button sx={styles.editButton} onClick={editServiceCart}>
          {STRINGS.common.edit}
        </Button>
      );
    }
    return <></>;
  };

  const renderAdditionalServiceButton = () => {
    if (
      isAdditionalServicesEditable &&
      serviceScopeStatus !== SERVICE_SCOPES.SERVICE_SCOPE_ASKED_ADDITIONAL &&
      serviceScopeStatus !== SERVICE_SCOPES.SERVICE_SCOPE_REJECTED
    ) {
      return (
        <Flex sx={styles.additionalServiceContainer}>
          <Text sx={styles.descriptionAdditionalService}>
            {STRINGS.visit.additionalServiceDescriptionText}
          </Text>
          <Button sx={styles.additionalServiceButton} onClick={editServiceCart}>
            {STRINGS.visit.additionalServiceButtonText}
          </Button>
        </Flex>
      );
    }
    return null;
  };

  const renderAdditionalSection = () => {
    const additionalServiceCartItems = showAdditionalServices
      ? getServiceCartItems(serviceCart?.items, true)
      : [];
    if (additionalServiceCartItems.length > 0) {
      return (
        <Flex sx={styles.additionalSectionContainer}>
          <Flex sx={styles.labelContainer}>
            <Text sx={styles.label}>{STRINGS.visit.additionalHeader}</Text>
          </Flex>
          <Flex sx={styles.additionalHeader}>{renderHeader()}</Flex>
          <Divider />
          {additionalServiceCartItems.map((item) => {
            return renderItem(
              item?.id,
              item?.name,
              item?.quantity,
              item?.priceEachWithFee,
              item?.priceTotalWithFee,
              rejectedServiceScopeStatus,
              item?.isAccepted,
            );
          })}
        </Flex>
      );
    }
    return null;
  };

  return (
    <Flex sx={styles.mainContainer}>
      <Card variant="serviceCartCard" sx={styles.contentContainer}>
        <Flex sx={styles.labelContainer}>
          <Text sx={styles.label}>{STRINGS.visit.summary}</Text>
        </Flex>
        <Flex sx={{ ...styles.container, ...cardStyle }}>
          <Flex sx={styles.cartContainer}>
            {renderHeader()}
            <Divider />
            {showAppraisal &&
              renderItem(
                0,
                STRINGS.visit.appraisal,
                1,
                STRINGS.visit.appraisalValue,
                STRINGS.visit.appraisalValue,
                !rejectedServiceScopeStatus,
              )}
            {serviceCartItems.map((item) => {
              return renderItem(
                item?.id,
                item?.name,
                item?.quantity,
                item?.priceEachWithFee,
                item?.priceTotalWithFee,
                rejectedServiceScopeStatus,
              );
            })}
            {showPrices && (
              <>
                <Divider />
                {renderSummary()}
                {rejectedServiceScopeStatus && (
                  <Flex sx={styles.rejectedScopeInfoContainer}>
                    <Text sx={styles.rejectedScopeInfoText}>
                      {STRINGS.visit.lumpSumPaymentAmount}
                    </Text>
                  </Flex>
                )}
              </>
            )}
          </Flex>
          {showAdditionalServices && renderAdditionalSection()}
          {renderAddButton()}
          {renderAdditionalServiceButton()}
        </Flex>
      </Card>
    </Flex>
  );
};

ServiceCartCard.propTypes = {
  serviceCart: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  isEditable: PropTypes.bool.isRequired,
  isAdditionalServicesEditable: PropTypes.bool.isRequired,
  showAdditionalServices: PropTypes.bool.isRequired,
  showPrices: PropTypes.bool.isRequired,
  editServiceCart: PropTypes.func,
  showAppraisal: PropTypes.bool,
  rejectedServiceScopeStatus: PropTypes.bool,
  serviceScopeStatus: PropTypes.string.isRequired,
  isPaid: PropTypes.bool,
  cardStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ServiceCartCard.defaultProps = {
  editServiceCart: null,
  cardStyle: {},
  showAppraisal: false,
  rejectedServiceScopeStatus: false,
  isPaid: false,
};

export default ServiceCartCard;
