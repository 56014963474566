import { Button, Flex, Text } from 'theme-ui';

import { STRINGS, VISIT_STATUSES } from '../../../constants';
import styles from './VisitMessages.style';

const VisitMessages = (props) => {
  const { visitDetail, action } = props;
  const { messageFromClient, messageFromWorkshop, status } = visitDetail ?? {};

  const renderEditButton = () => {
    const editableStatus =
      status === VISIT_STATUSES.NEW ||
      status === VISIT_STATUSES.PROPOSED_TIME ||
      status === VISIT_STATUSES.ASKED_NEW_TIME ||
      status === VISIT_STATUSES.PROPOSED_NEW_TIME ||
      status === VISIT_STATUSES.ACCEPTED ||
      status === VISIT_STATUSES.APPRAISAL ||
      status === VISIT_STATUSES.SERVICE_ACCEPTED ||
      status === VISIT_STATUSES.SERVICE_CONFIRMATION_ASKED ||
      status === VISIT_STATUSES.SERVICE_REJECTED ||
      status === VISIT_STATUSES.READY;
    if (editableStatus) {
      return (
        <Button variant="mini" sx={styles.editButton} onClick={action}>
          {STRINGS.workshop.edit}
        </Button>
      );
    }
    return <></>;
  };

  const renderClientMessage = () => {
    return (
      <Flex sx={styles.innerContainer}>
        <Flex sx={styles.labelContainer}>
          <Text sx={styles.messageLabel}>{STRINGS.visit.messageFromClientLabel}</Text>
        </Flex>
        {messageFromClient ? (
          <Flex sx={styles.messageContainer}>
            <Text variant="clientMessage" sx={styles.messageText}>
              {messageFromClient}
            </Text>
          </Flex>
        ) : (
          <Text sx={styles.messageMissingText}>{STRINGS.visit.messageMissing}</Text>
        )}
      </Flex>
    );
  };

  const renderWorkshopMessage = () => {
    return (
      <Flex sx={styles.innerContainer}>
        <Flex sx={styles.labelContainer}>
          <Text sx={styles.messageLabel}>{STRINGS.visit.messageFromWorkshopLabel}</Text>
          {renderEditButton()}
        </Flex>
        {messageFromWorkshop ? (
          <Flex sx={styles.messageContainer}>
            <Text variant="clientMessage" sx={styles.messageText}>
              {messageFromWorkshop}
            </Text>
          </Flex>
        ) : (
          <Text sx={styles.messageMissingText}>{STRINGS.visit.messageMissing}</Text>
        )}
      </Flex>
    );
  };

  return (
    <Flex sx={styles.container}>
      {renderWorkshopMessage()}
      {renderClientMessage()}
    </Flex>
  );
};

export default VisitMessages;
