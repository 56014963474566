import Head from 'next/head';

import { STRINGS } from '../constants';

export default function SEO({
  description = STRINGS.seo.appDescription,
  author = STRINGS.seo.author,
  meta,
  title = STRINGS.seo.mainPage,
}) {
  const titleWithSuffix = `${title} - ${STRINGS.seo.author}`;
  const metaData = [
    {
      name: `description`,
      content: description,
    },
    {
      property: `og:title`,
      content: titleWithSuffix,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: author,
    },
    {
      name: `twitter:title`,
      content: titleWithSuffix,
    },
    {
      name: `twitter:description`,
      content: description,
    },
  ].concat(meta);
  return (
    <Head>
      <title>{titleWithSuffix}</title>
      {metaData.map(({ name, content }, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <meta key={i} name={name} content={content} />
      ))}
    </Head>
  );
}

SEO.defaultProps = {
  lang: `pl`,
  meta: [],
};
