export default {
  card: {
    mt: '1rem',
    p: '1rem',
  },
  cardDark: {
    mt: '1rem',
    p: '1rem',
    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.5)',
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    info: {
      flexDirection: 'column',
    },
    text: {
      color: 'heading',
    },
  },
  infoContainer: {
    mb: ['1rem', null, null, 0],
  },
  rating: {
    padding: '1rem',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'border_color',
    borderRadius: '10px',
    width: ['100%', null, null, '50%'],
    container: {
      px: '1rem',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    text: {
      fontSize: 25,
      textAlign: 'center',
    },
    subText: {
      px: '3px',
    },
    data: {
      flexDirection: 'column',
      width: '100%',
      borderLeft: '2px solid',
      borderLeftColor: 'border_color',
      px: 2,
    },
  },
  progress: {
    p: 0,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '1.5rem',
    left: {
      width: '1rem',
      alignItems: 'center',
      justifyContent: 'center',
    },
    star: {
      mx: 1,
    },
    right: {
      width: '1rem',
      alignItems: 'center',
      justifyContent: 'center',
      ml: '0.5rem',
    },
    bar: {},
  },
  opinions: {
    flexDirection: 'column',
  },
};
