export default {
  container: {
    flexDirection: 'row',
    m: '5px',
    cursor: 'pointer',
    boxShadow: (t) => `0 3px 6px ${t.colors.shadow}`,
    '&:hover': {
      boxShadow: (t) => `0 4px 8px ${t.colors.shadow}`,
    },
  },
  historyContent: {
    bg: 'content_bg',
    flex: 1,
    borderRadius: '10px',
    flexDirection: 'row',
    p: '0.5rem',
    textAlign: 'center',
    '&:hover': {
      bg: 'content_bg_hover',
    },
  },
  contentContainer: {
    flex: [3, null, null, null, 7],
    flexDirection: 'column',
  },
  dateContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  messageContainer: {
    flexDirection: 'column',
    mb: '5px',
  },
  messageLabel: {
    fontSize: ['12px', '14px', '16px'],
  },
  messageAreaContainer: {},
  messageValue: {
    fontSize: ['12px', '14px', '16px'],
    textAlign: 'left',
    maxHeight: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  mileageContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mileageLabel: {
    fontSize: ['12px', '14px', '16px'],
  },
  mileageText: {
    fontSize: ['12px', '14px', '16px'],
  },
};
