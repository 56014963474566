import { COLORS } from '../../../constants';

export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    px: '10px',
    flex: 1,
  },
  containerSmall: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    px: '5px',
  },
  timeValue: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: COLORS.LIGHT_TEXT,
  },
  timeValueSmall: {
    fontWeight: 'body',
    fontSize: '12px',
    color: COLORS.LIGHT_TEXT,
  },
  labelText: {
    fontSize: '12px',
    color: COLORS.LIGHT_TEXT,
  },
};
