export default {
  HELP: 'HELP',
  VISIT: 'VISIT',
  PAYMENT: 'PAYMENT',
  CAR_ISSUE: 'CAR_ISSUE',
  CAR_ADD: 'CAR_ADD',
  CAR_HISTORY: 'CAR_HISTORY',
  WORKSHOP_ISSUE: 'WORKSHOP_ISSUE',
  APP: 'APP',
  REPORT_REVIEW: 'REPORT_REVIEW',
  COMPLAINT: 'COMPLAINT',
  UNFAIR_CONTRACTOR: 'UNFAIR_CONTRACTOR',
  SCAM: 'SCAM',
  OTHER: 'OTHER',
};
