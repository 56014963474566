import { Flex, Spinner } from 'theme-ui';

import styles from './DimLoadingIndicator.style';

const DimLoadingIndicator = () => {
  return (
    <Flex sx={styles.container}>
      <Spinner />
    </Flex>
  );
};

export default DimLoadingIndicator;
