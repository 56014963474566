/* eslint-disable no-unused-vars */
import { IoLogoUsd } from 'react-icons/io';
import { Box, Flex, Text } from 'theme-ui';

import {
  STRINGS as COMMON_STRINGS,
  FUEL_TYPES,
  PAYMENT_STATUSES,
  STRINGS,
  VISIT_STATUSES,
} from '../../../constants';
import { getTimeFromDate } from '../../../utils/formatHours';
import colorSwitch from '../../../utils/visitColorSwitch';
import { Link } from '../../others/Link';
import styles from './VisitPreviewWebCalendar.style';

const VisitPreviewWebCalendar = (props) => {
  const { item, week = false, month = false } = props;
  const { id, status, car, scheduledTime, paymentStatus } = item ?? {};
  const { make, model, productionYear, fuel, registrationNumber } = car ?? {};
  const url = `/visits/${make}/${model}/${id}`;
  const scheduledDate = new Date(scheduledTime);
  const time = getTimeFromDate(scheduledDate);

  const renderWeekView = () => {
    return (
      <Flex sx={styles.week}>
        <Flex sx={styles.week.car}>
          <Flex sx={styles.week.car.container}>
            <Flex sx={styles.week.car.container.inner}>
              <Link path={url} href={url} sx={styles.link} target="_blank">
                <Text sx={styles.week.car.container.makeModel}>{make}</Text>
              </Link>
              <Link path={url} href={url} sx={styles.link} target="_blank">
                <Text sx={styles.week.car.container.makeModel}>{model}</Text>
              </Link>
            </Flex>
          </Flex>
          <Flex sx={styles.week.car.container}>
            <Link path={url} href={url} sx={styles.link} target="_blank">
              <Text sx={styles.week.car.container.date}>{time}</Text>
              <Text sx={styles.week.car.container.info}>{registrationNumber ?? ''}</Text>
              <Text sx={styles.week.car.container.info}>{productionYear ?? ''}</Text>
            </Link>
          </Flex>
        </Flex>
        <Flex
          sx={{
            ...styles.week.status,
            ...{ bg: colorSwitch(status), borderColor: colorSwitch(status) },
          }}
        >
          {paymentStatus === PAYMENT_STATUSES.COMPLETED && (
            <Flex sx={styles.paymentStatusContainer}>
              <IoLogoUsd />
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  };
  if (week) {
    return renderWeekView();
  }

  const renderMonthView = () => {
    return (
      <Flex sx={styles.month}>
        <Flex sx={styles.month.car}>
          <Flex sx={styles.month.car.container}>
            <Link path={url} href={url} sx={styles.link} target="_blank">
              <Text sx={styles.month.car.container.makeModel}>{make}</Text>
              <Text sx={styles.month.car.container.makeModel}>{model}</Text>
            </Link>
          </Flex>
          <Flex sx={styles.month.car.container}>
            <Link path={url} href={url} sx={styles.link} target="_blank">
              <Text sx={styles.month.car.container.date}>{time}</Text>
              <Text sx={styles.month.car.container.info}>{registrationNumber ?? ''}</Text>
              <Text sx={styles.month.car.container.info}>{productionYear ?? ''}</Text>
              <Text sx={styles.month.car.container.info}>{FUEL_TYPES[fuel] ?? ''}</Text>
            </Link>
          </Flex>
        </Flex>
        <Flex
          sx={{
            ...styles.month.status,
            ...{ bg: colorSwitch(status), borderColor: colorSwitch(status) },
          }}
        >
          {paymentStatus === PAYMENT_STATUSES.COMPLETED && (
            <Flex sx={styles.paymentStatusContainer}>
              <IoLogoUsd />
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  };
  if (month) {
    return renderMonthView();
  }

  const renderDayView = () => {
    const textStyle =
      status === VISIT_STATUSES.FINISHED ? styles.day.status.inverseText : styles.day.status.text;
    return (
      <Flex sx={styles.day}>
        <Flex sx={styles.day.car}>
          <Flex sx={styles.day.car.container}>
            <Link path={url} href={url} sx={styles.link} target="_blank">
              <Text sx={styles.day.car.container.makeModel}>{make}</Text>
              <Text sx={styles.day.car.container.makeModel}>{model}</Text>
            </Link>
          </Flex>
          <Flex sx={styles.day.car.container}>
            <Link path={url} href={url} sx={styles.link} target="_blank">
              <Text sx={styles.day.car.container.date}>{time}</Text>
              <Text sx={styles.day.car.container.info}>{registrationNumber ?? ''}</Text>
              <Text sx={styles.day.car.container.info}>{productionYear ?? ''}</Text>
              <Text sx={styles.day.car.container.info}>{FUEL_TYPES[fuel] ?? ''}</Text>
            </Link>
          </Flex>
        </Flex>
        <Flex
          sx={{
            ...styles.day.status,
            ...{ bg: colorSwitch(status), borderColor: colorSwitch(status) },
          }}
        >
          {paymentStatus === PAYMENT_STATUSES.COMPLETED && (
            <Text sx={{ ...textStyle, ...styles.day.status.infoPayment }}>
              {STRINGS.VISIT_PAID_STATUS_SHORT.COMPLETED}
            </Text>
          )}
          <Text sx={{ ...textStyle, ...{ borderColor: colorSwitch(status) } }}>
            {COMMON_STRINGS.VISIT_STATUS.WORKSHOP[status]}
          </Text>
        </Flex>
      </Flex>
    );
  };
  return renderDayView();
};

export default VisitPreviewWebCalendar;
