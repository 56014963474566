import { IoMdMore } from 'react-icons/io';
import { AspectRatio, Box, Divider, Flex, Grid, Image, Text } from 'theme-ui';

import { FUEL_TYPES, STRINGS } from '../../../constants';
import colorSwitch from '../../../utils/visitColorSwitch';
import CopyToClipboardButton from '../../others/CopyToClipboardButton';
import DropDown from '../../others/DropDown';
import styles from './CarVisitWorkshopPreviewCard.style';

const CarVisitWorkshopPreviewCard = (props) => {
  const { car, title, status, dropDown } = props;
  const {
    make,
    model,
    variant,
    version,
    vin,
    productionYear,
    fuel,
    engineCapacity,
    enginePowerKw,
    curbWeight,
    registrationNumber,
    mileageEntry,
    image,
  } = car ?? {};

  const renderImage = () => {
    if (image && image.url) {
      return (
        <Box sx={styles.imageContainer}>
          <Box sx={styles.bgImageContainer}>
            <AspectRatio ratio={8 / 5} sx={styles.imageInnerContainer}>
              <Image sx={styles.image} src={image.url} />
            </AspectRatio>
          </Box>
        </Box>
      );
    }
    return (
      <Box sx={styles.imageContainer}>
        <Box sx={styles.bgImageContainer}>
          <AspectRatio ratio={8 / 5} sx={styles.missingPhotoContainer}>
            <Text sx={styles.missingPhoto}>{STRINGS.car.missingPhoto}</Text>
          </AspectRatio>
        </Box>
      </Box>
    );
  };

  const renderHeader = () => {
    return (
      <Flex sx={styles.headerContainer}>
        <Text sx={{ ...styles.headerText, ...{ color: colorSwitch(status) } }}>{title}</Text>
      </Flex>
    );
  };

  const renderRow = (label, value, optional) => {
    if (value) {
      const renderValue = optional ? `${value} ${optional}` : value;
      return (
        <Flex sx={styles.textRow}>
          <Text sx={styles.label}>{label}</Text>
          <Flex sx={styles.rowCopyContainer}>
            <Text sx={styles.value}>{renderValue}</Text>
            <CopyToClipboardButton text={renderValue} />
          </Flex>
        </Flex>
      );
    }
    return <></>;
  };

  const renderCarInfo = () => {
    const makeModel = `${make} ${model}`;
    return (
      <Flex sx={styles.carInfoContainer}>
        <Flex sx={styles.makeModelContainer}>
          <Text sx={styles.makeModel}>{makeModel}</Text>
          {dropDown && (
            <DropDown items={dropDown}>
              <Flex sx={{ color: 'text' }}>
                <IoMdMore size={22} />
              </Flex>
            </DropDown>
          )}
        </Flex>
        <Divider />
        <Flex sx={styles.infoContainer}>
          {renderRow(STRINGS.car.make, make)}
          {renderRow(STRINGS.car.model, model)}
          {renderRow(STRINGS.car.variant, variant)}
          {renderRow(STRINGS.car.version, version)}
          {renderRow(STRINGS.car.vin, vin)}
          {mileageEntry &&
            renderRow(STRINGS.car.mileage, mileageEntry.mileage, STRINGS.car.kilometers)}
          {renderRow(STRINGS.car.registrationNumber, registrationNumber)}
          {renderRow(STRINGS.car.productionYear, productionYear)}
          {renderRow(STRINGS.car.fuel, FUEL_TYPES[fuel])}
          {renderRow(STRINGS.car.engineCapacity, engineCapacity)}
          {renderRow(STRINGS.car.enginePowerKw, enginePowerKw, STRINGS.car.powerKw)}
          {renderRow(STRINGS.car.curbWeight, curbWeight, STRINGS.car.kilograms)}
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex sx={styles.container}>
      {renderHeader()}
      <Grid sx={styles.grid}>
        {renderImage()}
        {renderCarInfo()}
      </Grid>
    </Flex>
  );
};

export default CarVisitWorkshopPreviewCard;
