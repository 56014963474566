export default {
  VALIDATORS: {
    EMPTY_FIELD: 'Proszę wypełnij pole.',
    MIN_LENGTH: 'Minimalna ilość znaków: ',
    INVALID_LENGTH: 'Niepoprawna ilość znaków: ',
    WRONG_EMAIL: 'Email nie jest prawidłowy.',
    PASSWORD_UPPER_AND_LOWER_CASE: 'Hasło powinno zawierać wielkie i małe litery.',
    PASSWORD_NUMBER: 'Hasło powinno zawierać liczbę.',
    WRONG_PHONE_NUMBER: 'Numer telefonu nie jest prawidłowy.',
    WRONG_PRICE: 'Format ceny nie jest prawidłowy',
    WRONG_MILEAGE_VALUE: 'Przebieg nie jest prawidłowy.',
    WRONG_POSITIVE_NUMBER_NON_ZERO: 'Wartość całkowita musi być większa od zera.',
    HISTORY_ENTRY_REPORT_VALUE: 'Ilość kilometrów musi być większa niż przy ostatnim wpisie',
  },
  PERMISSIONS: {
    NOT_GRANTED_CAMERA_PERMISSION: 'Niewystarczające uprawnienia aparatu',
  },
  WEEK_DAYS: {
    0: 'Poniedziałek',
    1: 'Wtorek',
    2: 'Środa',
    3: 'Czwartek',
    4: 'Piątek',
    5: 'Sobota',
    6: 'Niedziela',
  },
  MONTHS: {
    0: 'Styczeń',
    1: 'Luty',
    2: 'Marzec',
    3: 'Kwiecień',
    4: 'Maj',
    5: 'Czerwiec',
    6: 'Lipiec',
    7: 'Sierpień',
    8: 'Wrzesień',
    9: 'Październik',
    10: 'Listopad',
    11: 'Grudzień',
  },
  VISIT_STATUS: {
    CLIENT: {
      NEW: 'Oczekująca',
      CANCELLED: 'Anulowana',
      REJECTED: 'Odrzucona - wybierz termin',
      PROPOSED_TIME: 'Zaproponowano termin',
      ASKED_NEW_TIME: 'Zapytano o nowy termin',
      PROPOSED_NEW_TIME: 'Zaproponowano nowy termin',
      ACCEPTED: 'Potwierdzona',
      APPRAISAL: 'Wycena w trakcie',
      SERVICE_CONFIRMATION_ASKED: 'Potwierdź zakres usług',
      SERVICE_ACCEPTED: 'Zaakceptowano zakres usług',
      SERVICE_REJECTED: 'Odrzucono zakres usług',
      READY: 'Gotowe do odbioru',
      FINISHED: 'Zakończona',
      DELETED: 'Usunięta',
    },
    WORKSHOP_WITHOUT_CUSTOMER: {
      NEW: 'Nowa [Bez klienta]',
      CANCELLED: 'Anulowana [Bez klienta]',
      REJECTED: 'Odrzucona [Bez klienta]',
      PROPOSED_TIME: 'Zaproponowano termin [Bez klienta]',
      ASKED_NEW_TIME: 'Oczekuje na nowy termin [Bez klienta]',
      PROPOSED_NEW_TIME: 'Zaproponowano nowy termin [Bez klienta]',
      ACCEPTED: 'Nierozpoczęta [Bez klienta]',
      APPRAISAL: 'W trakcie [Bez klienta]',
      SERVICE_CONFIRMATION_ASKED: 'Oczekuje na potwierdzenie [Bez klienta]',
      SERVICE_ACCEPTED: 'Zaakceptowano zakres usług [Bez klienta]',
      SERVICE_REJECTED: 'Odrzucono zakres usług [Bez klienta]',
      READY: 'Gotowe do odbioru [Bez klienta]',
      FINISHED: 'Zakończona [Bez klienta]',
      DELETED: 'Usunięta [Bez klienta]',
    },
    WORKSHOP: {
      NEW: 'Nowa',
      CANCELLED: 'Anulowana',
      REJECTED: 'Odrzucona',
      PROPOSED_TIME: 'Zaproponowano termin',
      ASKED_NEW_TIME: 'Oczekuje na nowy termin',
      PROPOSED_NEW_TIME: 'Zaproponowano nowy termin',
      ACCEPTED: 'Zaakceptowana',
      APPRAISAL: 'Wycena w trakcie',
      SERVICE_CONFIRMATION_ASKED: 'Oczekuje na potwierdzenie',
      SERVICE_ACCEPTED: 'Zaakceptowano zakres usług',
      SERVICE_REJECTED: 'Odrzucono zakres usług',
      READY: 'Gotowe do odbioru',
      FINISHED: 'Zakończona',
      DELETED: 'Usunięta',
    },
  },
  VISIT_TIME_PREFERENCE: {
    IS_MORNING: 'MORNING',
    IS_AFTERNOON: 'AFTERNOON',
    MORNING: 'Rano',
    AFTERNOON: 'Popołudniu',
  },
  VISIT_PAID_STATUS: {
    WAITING: 'Wizyta nieopłacona',
    COMPLETED: 'Wizyta opłacona',
  },
  VISIT_PAID_STATUS_SHORT: {
    WAITING: 'Nieopłacona',
    COMPLETED: 'Opłacona',
  },
  SERVICE_SCOPE: {
    SERVICE_SCOPE_ACCEPTED: 'Zapłacono za wizytę',
    SERVICE_SCOPE_REJECTED_FIRST: 'Odrzucono zakres wizyty',
    SERVICE_SCOPE_REJECTED_SECOND: 'Opłata za usługę wyceny',
  },
  WORKSHOP_TYPE_COMMON: {
    COMMON: 'Podstawowe usługi',
  },
  WORKSHOP_TYPES: {
    WORKSHOP: 'Mechanika',
    TIRE_SHOP: 'Wulkanizacja',
    AIR_CONDITION: 'Serwis klimatyzacji',
    DETAILING: 'Detailing',
    CAR_INSPECTION: 'Przeglądy i kontrole',
    VEHICLE_CONTROL_STATION: 'Stacja kontroli',
    EMERGENCY: 'Awaria i pomoc drogowa',
    LPG: 'Serwis LPG',
    HYBRID: 'Elektryczne i hybrydy',
    BODYWORK: 'Blacharstwo i lakiernictwo',
    ELECTRONICS: 'Elektronika',
    ELECTRICAL: 'Elektryka',
    CAR_GLASS: 'Szyby i lusterka',
    GEARBOX: 'Skrzynie biegów',
    UPHOLSTERY: 'Tapicer',
    ADAS: 'ADAS',
    REGENERATION: 'Regeneracja części',
    AUDIO: 'Car audio',
    CNG: 'Serwis CNG',
    TUNING: 'Tuning i modyfikacje',
    OFF_ROAD: 'Serwis offroad',
    MOTORCYCLE: 'Serwis motocykli',
  },
  WORKSHOP_TYPES_OPENING_HOURS: {
    ALL: 'Wszystkie',
    WORKSHOP: 'Mechanika',
    TIRE_SHOP: 'Wulkanizacja',
    AIR_CONDITION: 'Serwis klimatyzacji',
    DETAILING: 'Detailing',
    CAR_INSPECTION: 'Przeglądy i kontrole',
    VEHICLE_CONTROL_STATION: 'Stacja kontroli',
    EMERGENCY: 'Awaria i pomoc drogowa',
    LPG: 'Serwis LPG',
    HYBRID: 'Elektryczne i hybrydy',
    BODYWORK: 'Blacharstwo i lakiernictwo',
    ELECTRONICS: 'Elektronika',
    ELECTRICAL: 'Elektryka',
    CAR_GLASS: 'Szyby i lusterka',
    GEARBOX: 'Skrzynie biegów',
    UPHOLSTERY: 'Tapicer',
    ADAS: 'ADAS',
    REGENERATION: 'Regeneracja części',
    AUDIO: 'Car audio',
    CNG: 'Serwis CNG',
    TUNING: 'Tuning i modyfikacje',
    OFF_ROAD: 'Serwis offroad',
    MOTORCYCLE: 'Serwis motocykli',
  },
  REGULAR_SERVICES: {
    COMMON: {
      COMMON_BATTERY_REPLACEMENT: 'Wymiana akumulatora',
      COMMON_OIL_AND_FILTER_CHANGE: 'Wymiana oleju i filtra oleju',
      COMMON_CABIN_FILTER_CHANGE: 'Wymiana filtra kabinowego',
      COMMON_FUEL_FILTER_CHANGE: 'Wymiana filtra paliwa',
      COMMON_AIR_FILTER_CHANGE: 'Wymiana filtra powietrza',
      COMMON_COOLANT_CHANGE: 'Wymiana płynu chłodniczego',
      COMMON_BRAKE_FLUID_CHANGE: 'Wymiana płynu hamulcowego',
      COMMON_WIPER_BLADES_REPLACEMENT: 'Wymiana piór wycieraczek',
      COMMON_LIGHT_BULB_REPLACEMENT_REAR: 'Wymiana żarówki klosza (Tył)',
      COMMON_LIGHT_BULB_REPLACEMENT_FRONT: 'Wymiana żarówki reflektora (Przód)',
      COMMON_XENON_FILAMENT_REPLACEMENT: 'Wymiana żarówki ksenonowej',
    },
    WORKSHOP: {
      FUEL_SYSTEM_CLEANING: 'Czyszczenie układu paliwowego',
      SUSPENSION_DIAGNOSIS: 'Diagnostyka zawieszenia',
      DIAGNOSIS: 'Diagnoza',
      GENERAL_ENGINE_OVERHAUL: 'Generalny remont silnika',
      SERVICE_INSPECTION_RESET: 'Kasowanie inspekcji serwisowej',
      FAULT_CODES_ERASING: 'Kasowanie kodów błędów',
      ROOF_RACK_INSTALL: 'Montaż bagażnika dachowego',
      ROOF_BEAMS_INSTALL: 'Montaż belek dachowych',
      ROOF_BOX_INSTALL: 'Montaż boxu dachowego',
      TOW_BAR_INSTALL: 'Montaż haka holowniczego',
      ABS_SYSTEM_REPAIR: 'Naprawa ABS',
      DRUM_BRAKES_REPAIR: 'Naprawa hamulców bębnowych',
      ENGINE_REPAIR: 'Naprawa silnika',
      COOLING_SYSTEM_REPAIR: 'Naprawa układu chłodzenia',
      INTAKE_SYSTEM_REPAIR: 'Naprawa układu dolotowego',
      BRAKE_SYSTEM_REPAIR: 'Naprawa układu hamulcowego',
      STEERING_SYSTEM_REPAIR: 'Naprawa układu kierowniczego',
      DRIVE_SYSTEM_REPAIR: 'Naprawa układu napędowego',
      FUEL_SYSTEM_REPAIR: 'Naprawa układu paliwowego',
      EXHAUST_SYSTEM_REPAIR: 'Naprawa układu wydechowego',
      DRIVE_SHAFT_REPAIR: 'Naprawa wałów i mostów napędowych',
      RADIATOR_FAN_REPAIR: 'Naprawa wentylatora chłodnicy',
      MUFFLER_HANGER_REPAIR: 'Naprawa wieszaków tłumika',
      SUSPENSION_REPAIR: 'Naprawa zawieszenia',
      HEAD_PLANNING: 'Planowanie głowicy',
      DPF_FAP_REGENERATION: 'Regeneracja filtra cząstek stałych DPF / FAP',
      MUFFLER_WELDING: 'Spawanie tłumika',
      GEOMETRY_AND_TOE_IN_SETTING: 'Ustawienie geometrii i zbieżności kół',
      EXHAUST_SEALING: 'Uszczelnianie wydechu',
      ENGINE_VERIFICATION: 'Weryfikacja silnika',
      COOLING_SYSTEM_VERIFICATION: 'Weryfikacja układu chłodzenia',
      BRAKE_SYSTEM_VERIFICATION: 'Weryfikacja układu hamulcowego',
      STEERING_SYSTEM_VERIFICATION: 'Weryfikacja układu kierowniczego',
      DRIVE_SYSTEM_VERIFICATION: 'Weryfikacja układu napędowego',
      FUEL_SYSTEM_VERIFICATION: 'Weryfikacja układu paliwowego',
      EXHAUST_SYSTEM_VERIFICATION: 'Weryfikacja układu wydechowego',
      SHOCK_ABSORBER_REPLACEMENT: 'Wymiana amortyzatora',
      BRAKE_DRUMS_REPLACEMENT: 'Wymiana bębnów hamulcowych',
      ENGINE_COOLER_REPLACEMENT: 'Wymiana chłodnicy silnika',
      BRAKE_CYLINDERS_REPLACEMENT: 'Wymiana cylinderków hamulcowych',
      FAN_SENSOR_REPLACEMENT: 'Wymiana czujnika / termowłącznika wentylatora',
      ABS_SENSOR_REPLACEMENT: 'Wymiana czujnika ABS',
      TEMPERATURE_SYSTEM_REPLACEMENT: 'Wymiana czujnika temperatury',
      CLUTCH_PRESSURE_PLATE_REPLACEMENT: 'Wymiana docisku sprzęgła',
      STEERING_ROD_REPLACEMENT: 'Wymiana drążka kierowniczego',
      DPF_FILTER_REPLACEMENT: 'Wymiana filtra DPF',
      CABIN_FILTER_REPLACEMENT: 'Wymiana filtra kabinowego',
      FUEL_FILTER_REPLACEMENT: 'Wymiana filtra paliwa',
      AIR_FILTER_CHANGE: 'Wymiana filtra powietrza',
      CATALYTIC_CONVERTER_REPLACEMENT: 'Wymiana katalizator',
      BRAKE_PADS_REPLACEMENT_FRONT: 'Wymiana klocków hamulcowych (Przód)',
      BRAKE_PADS_REPLACEMENT_REAR: 'Wymiana klocków hamulcowych (Tył)',
      INTAKE_MANIFOLD_REPLACEMENT: 'Wymiana kolektora dolotowego',
      EXHAUST_MANIFOLD_REPLACEMENT: 'Wymiana kolektora wydechowego',
      MCPHERSON_STRUT_REPLACEMENT: 'Wymiana kolumny McPhersona',
      PULLEY_REPLACEMENT: 'Wymiana koła pasowego',
      FLYWHEEL_REPLACEMENT: 'Wymiana koła zamachowego',
      CLUTCH_AND_DUAL_MASS_REPLACEMENT:
        'Wymiana kompletnego sprzęgła z dwumasowym kołem zamachowym',
      COMPLETE_MUFFLER_REPLACEMENT: 'Wymiana kompletnego tłumika',
      TIE_ROD_END_REPLACEMENT: 'Wymiana końcówki drążka kierowniczego',
      STEERING_COLUMN_CROSS_REPLACEMENT: 'Wymiana krzyżaka kolumny kierowniczej',
      THROTTLE_CABLE_REPLACEMENT: 'Wymiana linki gazu',
      HANDBRAKE_CABLE_REPLACEMENT: 'Wymiana linki hamulca ręcznego',
      CLUTCH_CABLE_REPLACEMENT: 'Wymiana linki sprzęgła',
      TIMING_CHAIN_REPLACEMENT: 'Wymiana łańcucha rozrządu',
      STABILIZER_LINK_REPLACEMENT: 'Wymiana łącznika stabilizatora',
      SHOCK_ABSORBER_BEARING_REPLACEMENT: 'Wymiana łożyska amortyzatora',
      CLUTCH_RELEASE_BEARING_REPLACEMENT: 'Wymiana łożyska oporowego sprzęgła',
      WHEEL_BEARING_REPLACEMENT: 'Wymiana łożyska w kole',
      STEERING_GEAR_REPLACEMENT: 'Wymiana maglownicy',
      HEATER_REPLACEMENT: 'Wymiana nagrzewnicy',
      V_BELT_TENSIONER_REPLACEMENT: 'Wymiana napinacza paska klinowego',
      AUTOMATIC_GEARBOX_OIL_AND_FILTER_CHANGE:
        'Wymiana oleju i filtra hydraulicznego w skrzyń automatycznej',
      OIL_AND_FILTER_CHANGE: 'Wymiana oleju i filtra oleju',
      MANUAL_GEARBOX_OIL_AND_FILTER_CHANGE: 'Wymiana oleju i filtra w skrzyni manualnej',
      DIFFERENTIAL_GEAR_OIL_CHANGE: 'Wymiana oleju w mechanizmie różnicowym',
      WORKSHOP_AUTOMATIC_GEARBOX_OIL_CHANGE: 'Wymiana oleju w skrzyni automatycznej',
      SHOCK_ABSORBER_ELASTIC_COVER_REPLACEMENT: 'Wymiana osłony elastycznej amortyzatora',
      FLEXIBLE_STEERING_GEAR_COVER_REPLACEMENT:
        'Wymiana osłony elastycznej przekładni kierowniczej',
      SWIVEL_COVER_REPLACEMENT: 'Wymiana osłony przegubu',
      BUSHING_REPLACEMENT: 'Wymiana panewek',
      ROD_BEARINGS_REPLACEMENT: 'Wymiana panewek korbowodowych',
      SEAT_BELT_REPLACEMENT: 'Wymiana pasa bezpieczeństwa',
      TIMING_BELT_REPLACEMENT: 'Wymiana paska rozrządu',
      MULTI_V_BELT_REPLACEMENT: 'Wymiana paska wielorowkowego',
      HUB_REPLACEMENT: 'Wymiana piasty',
      PISTON_RINGS_REPLACEMENT: 'Wymiana pierścieni tłokowych',
      WIPER_BLADES_REPLACEMENT: 'Wymiana piór wycieraczek',
      COOLANT_REPLACEMENT: 'Wymiana płynu chłodniczego',
      BRAKE_FLUID_CHANGE: 'Wymiana płynu hamulcowego',
      POWER_STEERING_FLUID_CHANGE: 'Wymiana płynu wspomagania kierownicy',
      SHOCK_ABSORBER_CUSHION_REPLACEMENT: 'Wymiana poduszki amortyzatora',
      UNIT_INJECTORS_REPLACEMENT: 'Wymiana pompowtryskiwaczy',
      ABS_PUMP_REPLACEMENT: 'Wymiana pompy ABS',
      BRAKE_PUMP_REPLACEMENT: 'Wymiana pompy hamulcowej',
      FUEL_PUMP_REPLACEMENT: 'Wymiana pompy paliwa',
      CLUTCH_PUMP_REPLACEMENT: 'Wymiana pompy sprzęgła',
      WATER_PUMP_REPLACEMENT: 'Wymiana pompy wody',
      POWER_STEERING_PUMP_REPLACEMENT: 'Wymiana pompy wspomagania',
      INJECTION_PUMP_REPLACEMENT: 'Wymiana pompy wtryskowej',
      DRIVESHAFT_REPLACEMENT: 'Wymiana półosi',
      JOINT_REPLACEMENT: 'Wymiana przegubu',
      AIR_MASS_SENSOR_REPLACEMENT: 'Wymiana przepływomierza - czujnika masy powietrza',
      FLEX_BRAKE_HOSES_REPLACEMENT: 'Wymiana przewodów hamulcowych elastycznych',
      METAL_BRAKE_HOSES_REPLACEMENT: 'Wymiana przewodów hamulcowych metalowych',
      FUEL_LINES_REPLACEMENT: 'Wymiana przewodów paliwowych',
      COOLING_SYSTEM_HOSES_REPLACEMENT: 'Wymiana przewodów układu chłodzenia',
      INTAKE_HOSE_REPLACEMENT: 'Wymiana przewodu dolotowego',
      HEATER_RESISTOR_REPLACEMENT: 'Wymiana rezystora nagrzewnicy',
      TENSIONER_ROLLER_REPLACEMENT: 'Wymiana rolki napinającej',
      TENSION_ROLLER_REPLACEMENT: 'Wymiana rolki napinającej',
      SPREADERS_REPLACEMENT: 'Wymiana rozpieraczy',
      TIMING_REPLACEMENT: 'Wymiana rozrządu',
      CONTROL_ARM_SILENTBLOCK_REPLACEMENT: 'Wymiana silentblocku wahacza',
      IDLE_SPEED_CONTROL_MOTOR_REPLACEMENT: 'Wymiana silnika regulacji biegu jałowego',
      LAMBDA_PROBE_REPLACEMENT: 'Wymiana sondy lambda',
      SUSPENSION_SPRINGS_REPLACEMENT: 'Wymiana sprężyn zawieszenia',
      CLUTCH_REPLACEMENT: 'Wymiana sprzęgła',
      RADIATOR_FAN_CLUTCH_REPLACEMENT: 'Wymiana sprzęgła wentylatora chłodnicy (wiskozy)',
      STABILIZER_REPLACEMENT: 'Wymiana stabilizatora',
      PISTON_PIN_REPLACEMENT: 'Wymiana sworznia tłokowego',
      BRAKE_SHOES_REPLACEMENT: 'Wymiana szczęk hamulcowych',
      BRAKE_DISCS_REPLACEMENT_FRONT: 'Wymiana tarcz hamulcowych (Przód)',
      BRAKE_DISCS_REPLACEMENT_REAR: 'Wymiana tarcz hamulcowych (Tył)',
      BRAKE_DRUMS_ANCHOR_DICSC_REPLACEMENT: 'Wymiana tarcz kotwicznych bębnów hamulcowych',
      CLUTCH_DISC_REPLACEMENT: 'Wymiana tarczy sprzęgła',
      BONNET_OR_TAILGATE_TELESCOPE_REPLACEMENT:
        'Wymiana teleskopu pokrywy silnika lub klapy bagażnika',
      THERMOSTAT_REPLACEMENT: 'Wymiana termostatu',
      PISTON_REPLACEMENT: 'Wymiana tłoków',
      VIBRATION_DAMPER_REPLACEMENT: 'Wymiana tłumika drgań',
      END_SILENCER_REPLACEMENT: 'Wymiana tłumika końcowego',
      MIDDLE_SILENCER_REPLACEMENT: 'Wymiana tłumika środkowego',
      STABILIZER_RUBBER_BUSHING_REPLACEMENT: 'Wymiana tulejek gumy stabilizatora',
      TURBOCHARGER_REPLACEMENT: 'Wymiana turbosprężarki',
      INTAKE_MANIFOLD_GASKET_REPLACEMENT: 'Wymiana uszczelki kolektora dolotowego',
      EXHAUST_MANIFOLD_GASKET_REPLACEMENT: 'Wymiana uszczelniacza półosi napędowej',
      OIL_PAN_GASKET_REPLACEMENT: 'Wymiana uszczelki miski olejowej',
      HEAD_GASKET_REPLACEMENT: 'Wymiana uszczelki pod głowicą',
      VALVE_COVER_GASKET_REPLACEMENT: 'Wymiana uszczelniaczy zaworowych',
      INTAKE_HOSE_GASKET_REPLACEMENT: 'Wymiana uszczelki przewodu dolotowego',
      DRIVE_SHAFT_SEAL_REPLACEMENT: 'Wymiana wahacza',
      CONTROL_ARM_REPLACEMENT: 'Wymiana sworznia wahacza',
      CAM_SHAFT_REPLACEMENT: 'Wymiana wałka rozrządu',
      CRANK_SHAFT_REPLACEMENT: 'Wymiana wału korbowego',
      INJECTORS_REPLACEMENT: 'Wymiana wtryskiwaczy',
      CLUTCH_RELEASE_REPLACEMENT: 'Wymiana wysprzęglika',
      VALVE_REPLACEMENT: 'Wymiana zaworów',
      EGR_VALVE_REPLACEMENT: 'Wymiana zaworu EGR',
      FUEL_TANK_REPLACEMENT: 'Wymiana zbiornika paliwa',
      STEERING_KNUCKLE_REPLACEMENT: 'Wymiana zwrotnicy koła',
    },
    TIRE_SHOP: {
      WHEEL_GEOMETRY_3D: 'Geometria kół 3D',
      TIRE_CHANGE_OFF_ROAD_CAR: 'Montaż/demontaż opon i felg Off Road (za sztukę)',
      TIRE_REPAIR: 'Naprawa opon',
      NITROGEN_PUMPING: 'Pompowanie opon azotem (za sztukę)',
      WHEEL_STORAGE: 'Przechowywanie opon',
      STEEL_RIM_RENOVATION: 'Renowacja felg stalowych',
      ALU_RIM_RENOVATION: 'Renowacja felg aluminiowych',
      TIRE_CHANGE_ALU_RIM_14_TO_16_INCH: 'Wymiana opon 14"-16" - felga aluminiowa (za sztukę)',
      TIRE_CHANGE_STEEL_RIM_14_TO_16_INCH: 'Wymiana opon 14"-16" - felga stalowa (za sztukę)',
      TIRE_CHANGE_ALU_RIM_17_TO_19_INCH: 'Wymiana opon 17"-19" - felga aluminiowa (za sztukę)',
      TIRE_CHANGE_STEEL_RIM_17_TO_19_INCH: 'Wymiana opon 17"-19" - felga stalowa (za sztukę)',
      TIRE_CHANGE_ALU_RIM_TO_13_INCH: 'Wymiana opon do 13" - felga aluminiowa (za sztukę)',
      TIRE_CHANGE_STEEL_RIM_TO_13_INCH: 'Wymiana opon do 13" - felga stalowa (za sztukę)',
      TIRE_CHANGE_ALU_RIM_OVER_20_INCH: 'Wymiana opon powyżej 20" - felga aluminiowa (za sztukę)',
      TIRE_CHANGE_RUNFLAT: 'Wymiana opon Runflat - dowolna felga (za sztukę)',
      TIRE_CHANGE_WITH_TPMS: 'Wymiana opon z czujnikiem TPMS (za sztukę)',
      TIRE_CHANGE_WITH_BALANCING: 'Wymiana kół z wyważaniem',
      TIRE_VALVE_REPLACEMENT: 'Wymiana zaworków',
      TIRE_BALANCING: 'Wyważanie kół',
    },
    AIR_CONDITION: {
      AIR_CON_FILLING: 'Napełnienie klimatyzacji',
      REFRIGERANT_R134A: 'Napełnienie klimatyzacji - stary czynnik (R134a)',
      REFRIGERANT_1234YF: 'Napełnienie klimatyzacji - nowy czynnik (R1234yf)',
      FUNGUS_REMOVAL: 'Odgrzybianie klimatyzacji',
      OZONATION: 'Ozonowanie klimatyzacji',
      AIR_CON_SERVICE: 'Serwis klimatyzacji samochodowej',
      PRESSURE_TEST: 'Sprawdzenie szczelności klimatyzacji - ciśnieniowe',
      ULTRAVIOLET_TEST: 'Sprawdzenie szczelności klimatyzacji - ultrafiolet',
      COOLER_REPLACEMENT: 'Wymiana chłodnicy klimatyzacji',
      DRYER_FILTER_REPLACEMENT: 'Wymiana filtra osuszacza klimatyzacji',
      COMPRESSOR_OIL_CHANGE: 'Wymiana oleju w sprężarce klimatyzacji',
      DRYER_REPLACEMENT: 'Wymiana osuszacza klimatyzacji',
      EVAPORATOR_REPLACEMENT: 'Wymiana parownika klimatyzacji',
      HOSES_REPLACEMENT: 'Wymiana przewodów klimatyzacji',
      COMPRESSOR_REPLACEMENT: 'Wymiana sprężarki klimatyzacji',
      AIR_CONDITION_VALVE_REPLACEMENT: 'Wymiana zaworu klimatyzacji',
    },
    DETAILING: {
      CONVERTIBLE_ROOF_IMPREGNATION: 'Czyszczenie i impregnacja dachów cabrio',
      LEATHER_UPHOLSTERY_CLEANING: 'Czyszczenie i pielęgnacja tapicerki skórzanej',
      ENGINE_BAY_CLEANING: 'Czyszczenie silnika',
      PAINT_INSPECTION: 'Inspekcja lakieru',
      BASIC_CORRECTION: 'Korekta jednoetapowa',
      STAGE_2_CORRECTION: 'Korekta dwuetapowa',
      PREMIUM_CORRECTION: 'Pełna korekta',
      EXTRNAL_WASHING: 'Mycie zewnętrzne',
      LEATHER_REPAIR_AND_REGENERATION: 'Naprawa i regeneracja skóry',
      INVISIBLE_WIPER: 'Niewidzialna wycieraczka',
      PAINTWORK_REFRESHING: 'Odświeżenie lakieru',
      INTERIOR_OZONATION: 'Ozonowanie',
      CERAMIC_COATING: 'Powłoka ceramiczna',
      QUARTZ_COATING: 'Powłoka kwarcowa',
      POLYMER_COATING: 'Powłoka polimerowa',
      FABRIC_UPHOLSTERY_WASHING: 'Pranie tapicerki materiałowej',
      FOR_SALE_CAR_PREPARATION: 'Przygotowanie auta do sprzedaży',
      LAMP_RENOVATION: 'Renowacja lamp',
      SCRATCH_REMOVAL: 'Usuwanie zarysowań',
      BASIC_INTERIOR_PACKAGE: 'Wnętrze podstawowy',
      WAX: 'Wosk (naturalny, syntetyczny)',
      EXCLUSIVE_WAX: 'Wosk premium',
      RIMS_PROTECTION: 'Zabezpieczenie felg',
    },
    CAR_INSPECTION: {
      CAR_COMPUTER_DIAGNOSTICS: 'Diagnostyka komputerowa samochodu',
      DATUM_POINTS_MEASUREMENT: 'Pomiar punktów bazowych nadwozia',
      AIR_CONDITION_OVERVIEW: 'Przegląd klimatyzacji',
      SUMMER_OVERVIEW: 'Przegląd letni',
      BASIC_OVERVIEW: 'Przegląd podstawowy',
      PRE_HOLIDAY_OVERVIEW: 'Przegląd przed wakacjami',
      EXTENDED_OVERVIEW: 'Przegląd rozszerzony',
      PRE_TRIP_OVERVIEW: 'Przegląd samochodu przed podróżą',
      PRE_PURCHASE_INSPECTION: 'Przegląd samochodu przed zakupem',
      WINTER_INSPECTION: 'Przegląd zimowy',
    },
    VEHICLE_CONTROL_STATION: {
      CAR_INSPECTION: 'Przegląd - okresowe badanie techniczne',
    },
    EMERGENCY: {
      EMERGENCY_CAR_OPENING: 'Awaryjne otwieranie samochodu',
      BATTERY_DELIVERY_WITH_REPLACEMENT: 'Dostawa akumulatora z wymianą',
      WHEELS_DELIVERY: 'Dostawa kół',
      TOWING: 'Holowanie',
      ROADSIDE_ASSISTANCE: 'Pomoc drogowa',
      ASSISTANCE_TIRE_CHANGE: 'Pomoc na drodze - wymiana opon (za sztukę)',
      WINTER_CAR_STARTER: ' Uruchomienie samochodu w zimie u klienta',
    },
    LPG: {
      LPG_TANK_LEGALIZATION: 'Legalizacja zbiornika LPG',
      LPG_LUBRIFICATION: 'Lubryfikacja LPG',
      LPG_INSTALLATION: 'Montaż instalacji LPG',
      LPG_REGULATION: 'Regulacja LPG',
      LPG_TANK_REPLACEMENT: 'Wymiana butli LPG',
      LPG_ELECTROVALVE_REPLACEMENT: 'Wymiana elektrozaworu LPG',
      LPG_FILTER_REPLACEMENT: 'Wymiana filtra LPG',
      LPG_MIXER_REPLACEMENT: 'Wymiana mieszalnika LPG',
      LPG_EVAPORATOR_REPLACEMENT: 'Wymiana parownika LPG',
      LPG_HOSES_REPLACEMENT: 'Wymiana przewodów LPG',
      LPG_REDUCER_REPLACEMENT: 'Wymiana reduktora LPG',
      LPG_FILLER_REPLACEMENT: 'Wymiana wlewu LPG',
      LPG_INJECTOR_REPLACEMENT: 'Wymiana wtryskiwacza LPG',
      LPG_VALVE_REPLACEMENT: 'Wymiana zaworu LPG',
    },
    HYBRID: {
      HYBRID_BATTERY_CONTROL: 'Kontrola akumulatora pojazdu hybrydowego',
      ELECTRIC_HIGH_VOLTAGE_BATTERY_CONTROL:
        'Kontrola akumulatora wysokonapięciowego pojazdu elektrycznego',
      HYBRID_BATTERY_REPLACEMENT: 'Wymiana baterii w układzie hybrydowym',
    },
    BODYWORK: {
      FULL_BODY_PAINTING: 'Lakierowanie całego nadwozia',
      FRONT_FENDER_PAINTING: 'Lakierowanie błotnika przedniego',
      REAR_FENDER_PAINTING: 'Lakierowanie błotnika tylnego',
      FRONT_QUARTER_PAINTING: 'Lakierowanie ćwiartki przedniej',
      REAR_QUARTER_PAINTING: 'Lakierowanie ćwiartki tylnej',
      ROOF_PAINTING: 'Lakierowanie dachu',
      FRONT_DOOR_PAINTING: 'Lakierowanie drzwi przednich',
      REAR_DOOR_PAINTING: 'Lakierowanie drzwi tylnych',
      MIRROR_PAINTING: 'Lakierowanie lusterka',
      FRONT_APRON_PAINTING: 'Lakierowanie pasa przedniego',
      REAR_APRON_PAINTING: 'Lakierowanie pasa tylnego',
      TAILGATE_PAINTING: 'Lakierowanie tylnej klapy',
      FRONT_BUMPER_PAINTING: 'Lakierowanie zderzaka przedniego',
      REAR_BUMPER_PAINTING: 'Lakierowanie zderzaka tylnego',
      ENGINE_COVER_PAINTING: 'Malowanie pokrywy silnika',
      ROCKER_PANEL_PAINTING: 'Malowanie progu',
      FENDER_REPAIR: 'Naprawa błotnika',
      ROOF_REPAIR: 'Naprawa dachu',
      FRONT_DOOR_REPAIR: 'Naprawa drzwi przednich',
      REAR_DOOR_REPAIR: 'Naprawa drzwi tylnych',
      FRONT_APRON_REPAIR: 'Naprawa pasa przedniego',
      REAR_APRON_REPAIR: 'Naprawa pasa tylnego',
      ENGINE_COVER_REPAIR: 'Naprawa pokrywy silnika',
      ROCKER_PANEL_REPAIR: 'Naprawa progu',
      FRONT_QUARTER_REPAIR: 'Naprawa przedniej ćwiartki',
      REAR_QUARTER_REPAIR: 'Naprawa tylnej ćwiartki',
      TAILGATE_REPAIR: 'Naprawa tylnej klapy',
      BUMPER_REPAIR: 'Naprawa zderzaka',
      FENDER_REPLACEMENT: 'Wymiana błotnika',
      FRONT_QUARTER_REPLACEMENT: 'Wymiana ćwiartki przedniej',
      REAR_QUARTER_REPLACEMENT: 'Wymiana ćwiartki tylnej',
      ROOF_REPLACEMENT: 'Wymiana dachu',
      FRONT_DOOR_REPLACEMENT: 'Wymiana drzwi przednich',
      REAR_DOOR_REPLACEMENT: 'Wymiana drzwi tylnych',
      TURN_SIGNAL_REPLACEMENT: 'Wymiana kierunkowskazu',
      TAILGATE_REPLACEMENT: 'Wymiana klapy bagażnika',
      MIRROR_REPLACEMENT: 'Wymiana lusterka',
      WHEEL_ARCH_REPLACEMENT: 'Wymiana nadkola',
      FRONT_APRON_REPLACEMENT: 'Wymiana pasa przedniego',
      REAR_APRON_REPLACEMENT: 'Wymiana pasa tylnego',
      FLOOR_UNDER_ENGINE_REPLACEMENT: 'Wymiana podłogi pod silnikiem',
      ENGINE_COVER_REPLACEMENT: 'Wymiana pokrywy silnika',
      ROCKER_PANEL_REPLACEMENT: 'Wymiana progu',
      HEADLIGHT_REPLACEMENT: 'Wymiana reflektora',
      TAIL_LIGHT_COVER_REPLACEMENT: 'Wymiana tylnego klosza',
      BUMPER_REPLACEMENT: 'Wymiana zderzaka',
    },
    ELECTRONICS: {
      ACCIDENT_SAFETY_SYSTEM_REPAIR_AHR_ACS:
        'Naprawa systemu bezpieczeństwa podczas wypadku - AHR, ACS',
      BRAKE_SYSTEM_REPAIR_ABS_BAS_EBD: 'Naprawa systemu wspomagającego hamowanie - ABS, BAS, EBD',
      DRIVING_ASSIST_SYSTEM_REPAIR_TSR_ACC_BLIS_AFS_LDW_HHC_SBR:
        'Naprawa systemu wspomagającego jazdę - TSR, ACC, BLIS, AFS, LDW, HHC, SBR',
      POWER_STEERING_SYSTEM_REPAIR_ACD_EDS:
        'Naprawa systemu wspomagającego układ napędowy - ACD, EDS',
      ANTI_SKID_SYSTEM_REPAIR_ASR_ESP_DSR_RSC_VSA:
        'Naprawa systemu zapobiegającego poślizgom - ASR, ESP, DSR, RSC, VSA',
      ELECTRICAL_INSTALLATION_VERIFICATION: 'Weryfikacja instalacji elektrycznej',
      IDLE_SENSOR_REPLACEMENT: 'Wymiana czujnika biegu jałowego',
      LPG_PRESSURE_SENSOR_REPLACEMENT: 'Wymiana czujnika ciśnienia gazu (LPG)',
      DENOX_PRESSURE_SENSOR_REPLACEMENT: 'Wymiana czujnika ciśnienia modułu tłoczącego DeNOx',
      OIL_PRESSURE_SENSOR_REPLACEMENT: 'Wymiana czujnika ciśnienia oleju',
      FUEL_PRESSURE_SENSOR_REPLACEMENT: 'Wymiana czujnika ciśnienia paliwa',
      TPMS_WHEEL_AIR_PRESSURE_SENSOR_REPLACEMENT:
        'Wymiana czujnika ciśnienia powietrza w kole TPMS',
      EXHAUST_PRESSURE_SENSOR_REPLACEMENT: 'Wymiana czujnika ciśnienia spalin',
      INTAKE_MANIFOLD_PRESSURE_SENSOR_REPLACEMENT: 'Wymiana czujnika ciśnienia w kolektorze ssącym',
      BRAKE_SYSTEM_PRESSURE_SENSOR_REPLACEMENT: 'Wymiana czujnika ciśnienia w układzie hamulcowym',
      HALL_INGITION_SENSOR_REPLACEMENT:
        'Wymiana czujnika Halla aparatu zapłonowego i wału korbowego',
      FUEL_INJECTION_CONTROL_SYSTEM_SENSOR_REPLACEMENT:
        'Wymiana czujnika i nadajnika układu sterowania wtryskiem paliwa',
      PULSE_SENSOR_REPLACEMENT: 'Wymiana czujnika impulsów / impulsatora',
      AIR_CONDITION_SENSOR_REPLACEMENT: 'Wymiana czujnika klimatyzacji',
      ALARM_TILT_SENSOR_REPLACEMENT: 'Wymiana czujnika nachylenia, układu alarmowego',
      TACHOMETER_SENSOR_REPLACEMENT: 'Wymiana czujnika obrotomierza',
      OIL_SENSOR_REPLACEMENT: 'Wymiana czujnika oleju',
      DOOR_OPENING_SENSOR_REPLACEMENT: 'Wymiana czujnika otwierania drzwi',
      GAS_PEDAL_POSITION_SENSOR_REPLACEMENT: 'Wymiana czujnika położenia pedału gazu',
      CLUTCH_PEDAL_POSITION_SENSOR_REPLACEMENT: 'Wymiana czujnika położenia pedału sprzęgła',
      THROTTLE_POSITION_SENSOR_REPLACEMENT: 'Wymiana czujnika położenia przepustnicy',
      CAMSHAFT_POSITION_SENSOR_REPLACEMENT: 'Wymiana czujnika położenia wałka rozrządu',
      CRANKSHAFT_POSITION_SENSOR_REPLACEMENT: 'Wymiana czujnika położenia wału korbowego',
      ENGINE_OIL_LEVEL_SENSOR_REPLACEMENT: 'Wymiana czujnika poziomu oleju silnikowego',
      FUEL_LEVEL_SENSOR_REPLACEMENT: 'Wymiana czujnika poziomu paliwa',
      COOLANT_LEVEL_SENSOR_REPLACEMENT: 'Wymiana czujnika poziomu płynu chłodzącego',
      BRAKE_FLUID_LEVEL_SENSOR_REPLACEMENT: 'Wymiana czujnika poziomu płynu hamulcowego',
      WASHER_FLUID_LEVEL_SENSOR_REPLACEMENT: 'Wymiana czujnika poziomu płynu spryskiwacza',
      AIR_SUSPENSION_LEVEL_SENSOR_REPLACEMENT:
        'Wymiana czujnika poziomu zawieszenia pneumatycznego',
      BRAKE_PEDAL_DISPLACEMENT_SENSOR_REPLACEMENT: 'Wymiana czujnika przesunięcia pedału hamulca',
      STEERING_WHEEL_SENSOR_REPLACEMENT: 'Wymiana czujnika skrętu koła kierownicy',
      GEARBOX_SENSOR_REPLACEMENT: 'Wymiana czujnika skrzyni biegów',
      KNOCK_SENSOR_REPLACEMENT: 'KWymiana czujnika spalania stukowego',
      SPEED_SENSOR_REPLACEMENT: 'Wymiana czujnika szybkości',
      BRAKE_LIGHT_SENSOR_REPLACEMENT: 'Wymiana czujnika świateł stop',
      OIL_TEMPERATURE_SENSOR_REPLACEMENT: 'Wymiana czujnika temperatury oleju',
      FUEL_TEMPERATURE_SENSOR_REPLACEMENT: 'Wymiana czujnika temperatury paliwa',
      COOLANT_TEMPERATURE_SENSOR_REPLACEMENT: 'Wymiana czujnika temperatury płynu chłodzącego',
      INTAKE_AIR_TEMPERATURE_SENSOR_REPLACEMENT: 'Wymiana czujnika temperatury powietrza wlotowego',
      EXHAUST_GAS_TEMPERATURE_SENSOR_REPLACEMENT: 'Wymiana czujnika temperatury spalin',
      AIR_CON_INTERNAL_TEMP_SENSOR_REPLACEMENT:
        'Wymiana czujnika temperatury wewnętrznej klimatyzacji',
      AIR_CON_EXTERNAL_TEMP_SENSOR_REPLACEMENT: 'Wymiana czujnika temperatury zewnętrznej',
      NOX_SENSOR_REPLACEMENT: 'Wymiana czujnika tlenków azotu NOx',
      COOLING_SYSTEM_SENSOR_REPLACEMENT: 'Wymiana czujnika układu chłodzenia',
      BRAKE_PAD_WEAR_SENSOR_REPLACEMENT: 'Wymiana czujnika zużycia klocka hamulcowego',
      SENSOR_OR_RELAY_REPLACEMENT: 'Wymiana czujnika/przekaźnika',
    },
    ELECTRICAL: {
      DISTANCE_SENSORS_INSTALL: 'Montaż czujników odległości',
      DEFA_ELECTRIC_PARKING_HEATER_INSTALL: 'Montaż elektrycznego ogrzewania postojowego DEFA',
      REAR_VIEW_CAMERA_INSTALL: 'Montaż kamery cofania',
      CENTRAL_LOCKING_ACTUATORS_INSTALL: 'Montaż siłowników centralnego zamka',
      WEBASTO_PARKING_HEATER_INSTALL: 'Montaż spalinowego ogrzewania postojowego (Webasto)',
      LOCKS_REPAIR_AND_REPLACEMENT: 'Naprawa i wymiana zamków',
      IGNITION_MODULE_REPAIR: 'Naprawa modułu zapłonowego',
      MANIFOLD_REPAIR: 'Naprawa rozdzielacza',
      BATTERY_CHARGE_CHECK: 'Sprawdzenie ładowania akumulatora',
      BATTERY_LOAD_TEST: 'Test obciążeniowy akumulatora',
      LIGHTS_ADJUSTMENT: 'Ustawienie świateł',
      ELECTRICAL_BATTERY_REPLACEMENT: 'Wymiana akumulatora',
      ALTERNATOR_REPLACEMENT: 'Wymiana alternatora',
      FUSES_REPLACEMENT: 'Wymiana bezpieczników',
      IGNITION_COIL_REPLACEMENT: 'Wymiana cewki zapłonowej',
      WIPER_LINKAGE_REPLACEMENT: 'Wymiana cięgna wycieraczki',
      MANIFOLD_CAP_REPLACEMENT: 'Wymiana kopułki rozdzielacza',
      WINDOW_LIFT_MECHANISM_REPLACEMENT: 'Wymiana mechanizmu podnoszenia szyby',
      ENGINE_HEATER_REPLACEMENT: 'Wymiana nagrzewnicy silnika',
      WASHER_PUMP_REPLACEMENT: 'Wymiana pompy spryskiwacza',
      INTERMITTENT_INDICATOR_LIGHTS_REPLACEMENT: 'Wymiana przerywnika kierunkowskazów',
      IGNITION_CABLES_REPLACEMENT: 'Wymiana przewodów zapłonowych',
      STARTER_REPLACEMENT: 'Wymiana rozrusznika',
      WIPER_MOTOR_REPLACEMENT: 'Wymiana silniczka wycieraczek',
      HEADLAMP_HEIGHT_ADJUSTMENT_MOTOR_REPLACEMENT: 'Wymiana silnika regulacji wysokości świateł',
      IGNITION_SWITCH_REPLACEMENT: 'Wymiana stacyjki',
      SPARK_PLUGS_REPLACEMENT: 'Wymiana świec zapłonowych/żarowych',
      DASHBOARD_CLOCKS_REPLACEMENT: 'Wymiana zegarów deski rozdzielczej',
      TAIL_LIGHT_REPLACEMENT: 'Wymiana żarówki klosza (Tył)',
      XENON_BULB_REPLACEMENT: 'Wymiana żarówki ksenonowej',
      HEAD_LIGHT_BULB_REPLACEMENT: 'Wymiana żarówki reflektora (Przód)',
      LICENSE_PLATE_BULB_REPLACEMENT_REPLACEMENT: 'Wymiana żarówki tablicy rejestracyjnej',
    },
    CAR_GLASS: {
      GLASS_CRACKS_REPAIR: 'Naprawa pęknięć lub odprysków szyb',
      HEATED_WINDSHIELD_REAR_WINDOW_REPAIR: 'Naprawa podgrzewania szyby przedniej lub tylnej',
      CAR_GLASS_INVISIBLE_WIPER: 'Niewidzialna wycieraczka',
      GLASS_TINTING: 'Przyciemnianie szyb',
      SIDE_WINDOW_TINTING_FRONT: 'Przyciemnianie szyby bocznej (Przód)',
      SIDE_WINDOW_TINTING_REAR: 'Przyciemnianie szyby bocznej (Tył)',
      SUNROOF_GLASS_TINTING: 'Przyciemnianie szyby szyberdachu',
      MIRROR_GLASS_REPLACEMENT: 'Wymiana szkła lusterka',
      FIXED_SIDE_WINDOW_REPLACEMENT: 'Wymiana szyby bocznej - nieotwieranej',
      OPENING_SIDE_WINDOW_REPLACEMENT: 'Wymiana szyby bocznej - otwieranej',
      BODY_GLASS_REPLACEMENT: 'Wymiana szyby karoseryjnej',
      WINDSHIELD_REPLACEMENT: 'Wymiana szyby przedniej',
      REAR_WINDOW_REPLACEMENT: 'Wymiana szyby tylnej',
      SUNROOF_REPAIR_OR_REPLACEMENT: 'Wymiana, naprawa szyberdachu',
    },
    GEARBOX: {
      AUTOMATIC_GEARBOX_MECHATRONICS_ADAPTATION:
        'Adaptacja mechatronika automatycznej skrzyni biegów',
      AUTOMATIC_GEARBOX_COMPUTER_DIAGNOSTICS:
        'Diagnostyka komputerowa automatycznej skrzyni biegów',
      MECHATRONICS_CLONING: 'Klonowanie mechatroniki',
      AUTOMATIC_GEARBOX_REPAIR: 'Naprawa automatycznych skrzyń biegów',
      MANUAL_GEARBOX_REPAIR: 'Naprawa manualnej skrzyni biegów',
      MECHATRONICS_REPAIR: 'Naprawa mechatroniki',
      VALVE_BODY_REPAIR: 'Naprawa sterownika hydraulicznego',
      TORQUE_CONVERTER_REGENERATION: 'Regeneracja sprzęgła hydrokinetycznego',
      MECHATRONICS_SOFTWARE_UPLOAD: 'Wgrywanie oprogramowania do mechatroniki',
      AUTOMATIC_GEARBOX_CLUTCH_DUAL_MASS_REPLACEMENT:
        'Wymiana automatycznej skrzyni biegów, sprzęgła, dwumasy',
      MANUAL_GEARBOX_CLUTCH_DUAL_MASS_REPLACEMENT:
        'Wymiana manualnej skrzyni biegów, sprzęgła, dwumasy',
      MECHATRONICS_DSG_MULTITRONIC_EASYTRONIC_REPLACEMENT:
        'Wymiana mechatroniki DSG, Multitronic, Easytronic',
      AUTOMATIC_GEARBOX_OIL_CHANGE: 'Wymiana oleju w automatycznej skrzyni biegów',
      MANUAL_GEARBOX_OIL_CHANGE: 'Wymiana oleju w manualnej skrzyni biegów',
      AUTOMATIC_GEARBOX_CLUTCH_CHANGE: 'Wymiana sprzęgła w automatycznej skrzyni biegów',
    },
    UPHOLSTERY: {
      ARMREST_SEWING: 'Obszycie podłokietnika',
      CAR_SEAT_RENOVATION: 'Renowacja fotela samochodowego',
      STEERING_WHEEL_RENOVATION: 'Renowacja kierownicy',
      SEAM_CRACK_SEWING: 'Szycie pęknięcia na szwie',
      FRONT_COVER_REPLACEMENT: 'Wymiana poszycia fotela samochodowego',
      BACK_SEAT_COVER_REPLACEMENT: 'Wymiana poszycia kanapy samochodowej',
      SEAT_ONLY_COVER_REPLACEMENT: 'Wymiana poszycia siedziska fotela samochodowego',
    },
    ADAS: {
      VEHICLE_STABILITY_CONTROL_ESC: 'System stabilizujący tor jazdy samochodu (ESC)',
      CRUISE_CONTROL_ASSEMBLY_AND_REPAIR: 'Tempomat montaż i naprawa',
      TPMS_TIRE_PRESSURE_SENSORS_REPLACEMENT:
        'Czujniki ciśnienia w oponach TPMS - wymiana (za sztukę)',
      HILL_DESCENT_CONTROL: 'Elektroniczny system bezpieczeństwa czynnego (Hill Descent Control)',
      RADAR_CALIBRATION: 'Kalibracja radarów',
      HILL_START_ASSIST: 'System wspomagający ruszanie pod górę (HAS)',
      DASH_CAM_SETTING: 'Ustawienia kamery samochodowej',
      RAIN_SENSOR_REPLACEMENT: 'Wymiana czujnika opadów deszczu',
    },
    REGENERATION: {
      ALTERNATOR_REGENERATION: 'Regeneracja alternatora',
      ABS_PUMP_REGENERATION: 'Regeneracja pompy ABS',
      MASTER_CYLINDER_REGENERATION: 'Regeneracja pompy hamulcowej',
      POWER_STEERING_PUMP_REGENERATION: 'Regeneracja pompy wspomagania',
      INJECTION_PUMP_REGENERATION: 'Regeneracja pompy wtryskowej',
      STEERING_GEAR_REGENERATION: 'Regeneracja przekładni kierowniczej',
      HEADLIGHT_REGENERATION: 'Regeneracja reflektorów',
      STARTER_REGENERATION: 'Regeneracja rozrusznika',
      TURBOCHARGER_REGENERATION: 'Regeneracja turbosprężarki',
      PETROL_AND_DIESEL_INJECTORS_REGENERATION: 'Regeneracja wtryskiwaczy benzynowych i diesla',
      BRAKE_CALIPERS_REGENERATION: 'Regeneracja zacisków hamulcowych',
    },
    AUDIO: {
      NAVIGATION_UPDATE: 'Aktualizacja nawigacji',
      ANTENNAS_INSTALLATION: 'Montaż anten',
      CAR_SPEAKERS_INSTALLATION: 'Montaż głośników samochodowych',
      NAVIGATION_INSTALLATION: 'Montaż nawigacji',
      RADIO_ASSEMBLY: 'Montaż radia',
      HANDS_FREE_KIT_INSTALLATION: 'Montaż zestawu głośnomówiącego',
      SUBWOOFER_CONNECTING: 'Podłączenie subwoofera',
      CAR_AMPLIFIERCONNECTING: 'Podłączenie wzmacniacza samochodowego',
      CAR_SOUNDPROOFING: 'Wygłuszenie samochodu',
    },
    CNG: {
      CNG_TANK_LEGALIZATION: 'Legalizacja zbiornika CNG',
      CNG_LUBRIFICATION: 'Lubryfikacja CNG',
      CNG_INSTALLATION: 'Montaż instalacji CNG z homologacją',
      CNG_REGULATION: 'Regulacja CNG',
      CNG_ELECTROVALVE_REPLACEMENT: 'Wymiana elektrozaworu CNG',
      CNG_MIXER_REPLACEMENT: 'Wymiana mieszalnika CNG',
      CNG_EVAPORATOR_REPLACEMENT: 'Wymiana parownika CNG',
      CNG_HOSES_REPLACEMENT: 'Wymiana przewodów CNG',
      CNG_REDUCER_REPLACEMENT: 'Wymiana reduktora CNG',
      CNG_FILLER_REPLACEMENT: 'Wymiana wlewu CNG',
      CNG_INJECTOR_REPLACEMENT: 'Wymiana wtryskiwacza CNG',
      CNG_VALVE_REPLACEMENT: 'Wymiana zaworu CNG',
      CNG_TANK_REPLACEMENT: 'Wymiana zbiornika CNG',
    },
    TUNING: {
      FUEL_PRESSURE_REGULATOR_ADAPTATION: 'Adaptacja regulatora ciśnienia paliwa',
      ENGINE_DECARBONIZATION_HYDROGENATION: 'Dekarbonizacja (wodorowanie) silnika',
      NITROUS_OXIDE_INSTALLATION: 'Instalacja podtlenku azotu',
      BODYKIT_ASSEMBLY: 'Montaż BodyKit',
      FORGED_PISTONS_INSTALLATION: 'Montaż kutych tłoków',
      HIGH_PERFORMANCE_TIRES_FITTING: 'Montaż opon High Performance (za sztukę)',
      TRACK_DAY_TIRES_FITTING: 'Montaż opon Track Day (za sztukę)',
      COMPETITION_TIRES_FITTING: 'Montaż opon wyczynowych (za sztukę)',
      XENON_LIGHTS_INSTALLATION: 'Montaż oświetlenia ksenonowego',
      POWER_BOX_INSTALLATION: 'Montaż Power Boxów podnoszących moc',
      BRAIDED_BRAKE_LINES_INSTALLATION: 'Montaż przewodów hamulcowych w oplocie',
      STRUTS_INSTALLATION: 'Montaż rozpórek',
      SPORT_OIL_FILTER_INSTALLATION: 'Montaż sportowego filtra oleju',
      SPORT_AIR_FILTER_INSTALLATION: 'Montaż sportowego filtra powietrza',
      SPORT_BRAKE_FLUID_INSTALLATION: 'Montaż sportowego płynu hamulcowego',
      SPORT_COOLING_SYSTEM_INSTALLATION: 'Montaż sportowego układu chłodzenia',
      SPORT_DRIVETRAIN_INSTALLATION: 'Montaż sportowego układu przeniesienia napędu',
      SPORT_EXHAUST_SYSTEM_INSTALLATION: 'Montaż sportowego układu wydechowego',
      SPORT_CAMSHAFT_INSTALLATION: 'Montaż sportowego wałka rozrządu',
      SPORT_SUSPENSION_KIT_INSTALLATION: 'Montaż sportowego zestawu zawieszenia',
      SPORT_FUEL_PUMP_INSTALLATION: 'Montaż sportowej pompy paliwa',
      SPORT_SHOCK_ABSORBERS_INSTALLATION: 'Montaż sportowych amortyzatorów',
      SPORT_BRAKE_PADS_INSTALLATION: 'Montaż sportowych klocków hamulcowych',
      SPORT_IGNITION_CABLES_INSTALLATION: 'Montaż sportowych przewodów zapłonowych',
      SPORT_STABILIZERS_INSTALLATION: 'Montaż sportowych stabilizatorów',
      SPORT_SPARK_PLUGS_INSTALLATION: 'Montaż sportowych świec zapłonowych',
      SPORT_BRAKE_DISCS_INSTALLATION: 'Montaż sportowych tarcz hamulcowych',
      SPORT_SUSPENSION_BUSHINGS_INSTALLATION: 'Montaż sportowych tulei zawieszenia',
      SPORT_INDICATORS_INSTALLATION: 'Montaż sportowych wskaźników',
      INCREASED_LOAD_SPRINGS_INSTALLATION: 'Montaż sprężyn do zwiększonego obciążenia',
      HIGH_PERFORMANCE_RODS_INSTALLATION: 'Montaż wyczynowych panewek',
      PERFORMANCE_VALVES_INSTALLATION: 'Montaż wyczynowych zaworów',
      CAR_LOWERING: 'Obniżenie samochodu',
      SPORT_BRAKE_SERVICE: 'Obsługa hamulców sportowych',
      SPORTS_SUSPENSION_SERVICE: 'Obsługa zawieszenia sportowego',
      ENGINE_POWER_INCREASE: 'Podniesienie mocy silnika',
      SPORT_TRANSMISSION_OIL_CHANGE: 'Wymiana sportowego oleju przekładniowego',
      SPORT_ENGINE_OIL_CHANGE: 'Wymiana sportowego oleju silnikowego',
      SPORT_COOLANT_CHANGE: 'Wymiana sportowego płynu chłodzącego',
    },
    OFF_ROAD: {
      OFF_ROAD_SHOCK_ABSORBERS_INSTALLATION: 'Montaż amortyzatorów Off Road',
      OFF_ROAD_STEERING_DAMPER_INSTALLATION: 'Montaż amortyzatorów skrętu Off Road',
      OFF_ROAD_REAR_AXLE_LOCK_INSTALLATION: 'Montaż blokady tylnego mostu Off Road',
      OFF_ROAD_ADDITIONAL_LIGHTING_INSTALLATION: 'Montaż oświetlenia dodatkowego Off Road',
      OFF_ROAD_LEAF_SPRINGS_INSTALLATION: 'Montaż resorów Off Road',
      OFF_ROAD_SNORKEL_ASSEMBLY: 'Montaż snorkeli Off Road',
      OFF_ROAD_SPRINGS_INSTALLATION: 'Montaż sprężyn Off Road',
      OFF_ROAD_HUB_CLUTCH_INSTALLATION: 'Montaż sprzęgiełka piast Off Road',
      OFF_ROAD_U_BOLT_STIRRUP_ASSEMBLY: 'Montaż strzemiono U-bolt Off Road',
      OFF_ROAD_WINCH_INSTALLATION: 'Montaż wyciągarki Off Road',
      OFF_ROAD_FENDER_EXTENSIONS: 'Poszerzenia błotników Off Road',
      OFF_ROAD_SUSPENSION_BUSHING_REPLACEMENT: 'Wymiana tulei zawieszenia Off Road',
    },
    MOTORCYCLE: {
      ELECTRONIC_COMPONENTS_DIAGNOSIS_AND_REPAIR:
        'Diagnostyka i naprawa elementów elektronicznych motocykli',
      DRIVETRAIN_DIAGNOSIS_AND_REPAIR: 'Diagnostyka i naprawa układów napędowych motocykli',
      SUSPENSION_DIAGNOSIS_AND_REPAIR: 'Diagnostyka i naprawa układów zawieszeń motocykli',
      ELECTRICAL_SYSTEM_DIAGNOSIS_AND_REPAIR:
        'Diagnostyka i naprawa układu elektrycznego motocykla',
      COMPUTER_DIAGNOSTICS: 'Diagnostyka komputerowa motocykli',
      MOTORCYCLE_PAINTING: 'Lakierowanie motocykli',
      MOTORCYCLE_ACCESSORIES_ASSEMBLY: 'Montaż akcesoriów motocyklowych',
      CRASHED_MOTORCYCLE_REPAIR: 'Naprawa motocykli powypadkowych',
      ECU_CONTROLLER_PROGRAMMING_AND_MODIFICATION: 'Programowanie i modyfikacje sterowników ECU',
      MOTORCYCLE_INSPECTION: 'Przegląd motocykla',
      CARBURETOR_SERVICE: 'Serwis gaźników motocyklowych (regulacja i czyszczenie)',
      CROSS_AND_ENDURO: 'Serwis i naprawa motocykli cross i enduro',
      ATV_UTV_VEHICLES_SERVICE_AND_REPAIR: 'Serwis i naprawa pojazdów ATV/UTV',
      BASIC_MOTORCYCLE_SERVICE: 'Serwis podstawowy motocykla',
      SCOOTERS_AND_MOPEDS_SERVICE: 'Serwis skuterów i motorowerów',
      MOTORCYCLE_TUNING: 'Tuning mechaniczny i elektroniczny motocykli',
      MOTORCYCLE_BATTERY_REPLACEMENT: 'Wymiana akumulatora w motocyklu',
      MOTORCYCLE_BRAKE_PADS_REPLACEMENT: 'Wymiana klocków hamulcowych w motocyklu',
      MOTORCYCLE_CHAIN_AND_GEARS_REPLACEMENT: 'Wymiana łańcucha i zębatek w motocyklu',
      MOTORCYCLE_OIL_AND_OIL_FILTER_CHANGE: 'Wymiana oleju i filtra oleju w motocyklu',
      MOTORCYCLE_FRONT_TIRE_REPLACEMENT: 'Wymiana przedniej opony w motocyklu (za sztukę)',
      BRAKE_DISCS_AND_PADS_REPLACEMENT: 'Wymiana tarcz i klocków hamulcowych',
      MOTORCYCLE_REAR_TIRE_REPLACEMENT: 'Wymiana tylnej opony w motocyklu (za sztukę)',
    },
  },
  HELPDESK_TYPES: {
    HELP: 'Pomoc',
    VISIT: 'Wizyta',
    PAYMENT: 'Płatność',
    CAR_ISSUE: 'Problem z samochodem',
    CAR_ADD: 'Dodawanie samochodu',
    CAR_HISTORY: 'Historia samochodu',
    WORKSHOP_ISSUE: 'Problem z warsztatem',
    APP: 'Aplikacja',
    REPORT_REVIEW: 'Zgłoszenie opinii',
    COMPLAINT: 'Reklamacja',
    UNFAIR_CONTRACTOR: 'Nieuczciwy kontrahent',
    SCAM: 'Oszustwo',
    OTHER: 'Inne',
  },
  CART_CONFIRM_STATUSES: {
    NOT_ASKED: 'Do potwierdzenia',
    ASKED: 'Wysłano zapytanie',
    CONFIRMED: 'Potwierdzono',
    REJECTED: 'Odrzucono',
  },
  CURRENCIES: {
    PLN: 'Polski złoty (PLN)',
  },
  COUNTRIES_ISO: {
    PL: 'Polska (PL)',
  },
  LEGAL_PERSON_TYPES: {
    BUSINESS: 'Spółka',
    ORGANIZATION: 'Fundacja',
    SOLETRADER: 'Jednoosobowa działalność gospodarcza',
  },
  KYC_LEVELS: {
    LIGHT: 'Profil niezweryfikowany',
    REGULAR: 'Profil zweryfikowany',
  },
  KYC_DOCUMENT_TYPES: {
    IDENTITY_PROOF: 'Dokument tożsamości',
    REGISTRATION_PROOF: 'Dokument rejestracji',
    ARTICLES_OF_ASSOCIATION: 'Dokument statutowy',
    SHAREHOLDER_DECLARATION: 'Deklaracja wspólników',
    ADDRESS_PROOF: 'Potwierdzenie adresu',
  },
  KYC_DOCUMENT_STATUSES: {
    CREATED: 'W trakcie',
    VALIDATION_ASKED: 'Przetwarzanie',
    VALIDATED: 'Zatwierdzony',
    REFUSED: 'Odrzucony',
    OUT_OF_DATE: 'Nieaktualny',
  },
  KYC_DOCUMENT_REFUSED_REASONS: {
    DOCUMENT_UNREADABLE: 'Dokument nieczytelny',
    DOCUMENT_NOT_ACCEPTED: 'Dokument niezaakceptowany',
    DOCUMENT_HAS_EXPIRED: 'Dokument wygasł',
    DOCUMENT_INCOMPLETE: 'Dokument niekompletny',
    DOCUMENT_MISSING: 'Brak dokumentu',
    DOCUMENT_DO_NOT_MATCH_USER_DATA: 'Dokument nie pasuje do danych użytkownika',
    DOCUMENT_DO_NOT_MATCH_ACCOUNT_DATA: 'Dokument nie pasuje do danych konta',
    SPECIFIC_CASE: 'Szczególny przypadek',
    DOCUMENT_FALSIFIED: 'Dokument sfałszowany',
    UNDERAGE_PERSON: 'Osoba nieletnia',
    COUNTERFEIT_PRODUCT: 'Fałszywy dokument',
    OTHER: 'Inne',
  },
  UBO_DECLARATION_REASON_TYPES: {
    MISSING_UBO: 'Brak UBO',
    WRONG_UBO_INFORMATION: 'Błędne informacje UBO',
    UBO_IDENTITY_NEEDED: 'Potrzebna tożsamość UBO',
    SHAREHOLDERS_DECLARATION_NEEDED: 'Wymagana deklaracja akcjonariuszy',
    ORGANIZATION_CHART_NEEDED: 'Potrzebny schemat organizacji',
    DOCUMENTS_NEEDED: 'Potrzebne dokumentu',
    DECLARATION_DO_NOT_MATCH_UBO_INFORMATION: 'Deklaracja nie pasuje do UBO',
    SPECIFIC_CASE: 'Szczególny przypadek',
  },
  UBO_DECLARATION_STATUSES: {
    CREATED: 'W trakcie',
    VALIDATION_ASKED: 'Przetwarzanie',
    INCOMPLETE: 'Niekompletny',
    VALIDATED: 'Zatwierdzony',
    REFUSED: 'Odrzucony',
  },
  NEXT_AUTH_ERRORS: {
    Signin: 'Spróbuj zalogować się przy użyciu innego konta.',
    OAuthSignin: 'Spróbuj zalogować się przy użyciu innego konta.',
    OAuthCallback: 'Spróbuj zalogować się przy użyciu innego konta..',
    OAuthCreateAccount: 'Spróbuj zalogować się przy użyciu innego konta.',
    EmailCreateAccount: 'Spróbuj zalogować się przy użyciu innego konta.',
    Callback: 'Spróbuj zalogować się przy użyciu innego konta.',
    OAuthAccountNotLinked:
      'Aby potwierdzić swoją tożsamość, zaloguj się na to samo konto, którego używałeś pierwotnie.',
    EmailSignin: 'Sprawdź swój adres e-mail.',
    CredentialsSignin: 'Logowanie nie powiodło się. Sprawdź, czy podane dane są poprawne.',
    default: 'Nie można się zalogować',
  },
  VISIT_INIT_OPTIONS: {
    INIT_CLIENT: 'Klient',
    INIT_WORKSHOP: 'Warsztat bez klienta',
    INIT_WORKSHOP_WITH_CLIENT: 'Warsztat z przypisanym klientem',
  },
  INVOICE_STATUSES: {
    draft: 'Nowe',
    open: 'Oczekuje na płatność',
    paid: 'Zapłacone',
    void: 'Błędna',
    uncollectible: 'Nieściągalne',
  },
  INVOICE_COLLECTION_ENABLED: {
    charge_automatically: 'Automatycznie',
    send_invoice: 'Ręcznie',
  },
  SUBSCRIPTION_STATUSES: {
    incomplete: 'Niekompletny',
    incomplete_expired: 'Niekompletny wygasły',
    trialing: 'Testowanie',
    active: 'Aktywny',
    past_due: 'Przeterminowany',
    canceled: 'Anulowany',
    unpaid: 'Nieopłacony',
  },
  SUBSCRIPTION_INTERVALS: {
    month: 'Miesiąc',
    day: 'Dzień',
  },
  STRIPE_CARD_ERROR_CODES: {
    card_declined: 'Karta odrzucona',
    expired_card: 'Karta wygasła',
    incorrect_cvc: 'Niepoprawny kod CV2',
    processing_error: 'Błąd procesownia',
    incorrect_number: 'Niepoprawny number karty',
  },
  car: {
    addMissingPhoto: 'Dodaj zdjęcie',
    missingPhoto: 'Brak zdjęcia',
    detailsButtonText: 'Więcej',
    make: 'Marka',
    model: 'Model',
    variant: 'Wariant',
    version: 'Wersja',
    vin: 'VIN',
    productionYear: 'Rok produkcji',
    fuel: 'Rodzaj paliwa',
    engineCapacity: 'Pojemność silnika',
    enginePowerKw: 'Moc silnika',
    curbWeight: 'Masa własna',
    firstRegistrationDate: 'Data pierwszej rejestracji',
    nickname: 'Nazwa pojazdu',
    mileage: 'Przebieg',
    color: 'Kolor',
    registrationNumber: 'Numer rejestracyjny',
    registrationNumberShort: 'nr rejestracyjny',
    kilometers: 'km',
    kilograms: 'kg',
    powerKw: 'KW',
    capacity: 'ccm',
    finish: 'Koniec',
    shareCarHistory: 'Udostępnij',
    shareCarHistoryAlert: 'Czy napewno chcesz udostępnić?',
    shareCarHistoryStopAllAlert: 'Czy napewno chcesz przestać udostępniać wszystkim pojazd?',
    sharesTo: 'Do: ',
    visitFor: 'dla',
    shareValidTo: 'Dostępne do: ',
    serviceHistory: 'Historia serwisowa',
    shareCardTitle: 'Udostępnienie',
    searchShareNotFound: 'Brak wyników wyszukiwania',
    searchShareCarHistory:
      'Wyszukaj osobę, której chcesz udostępnić po imieniu, nazwisku lub adresie email',
    stopShareAll: 'Przestań udostępniać wszystkim',
    finishCarAlertTitle: 'Zakończ posiadanie pojazdu',
    finishCarAlertMessage:
      'Czy chcesz zakończyć posiadanie pojazdu? Pojazd zostanie przeniesiony do listy starych pojazdów?',
    oldCarInfoTitle: 'To nie jest twój obecny pojazd',
    unfinishedVisitsPopup:
      'Zakończ lub anuluj wszystkie trwające wizyty dla samochodu, zanim zmienisz właściciela',
    ongoingVisit: 'Trwające wizyty',
    menuInfo: 'Info',
    menuSettings: 'Ustawienia',
    menuAddCourse: 'Dodaj przebieg',
    menuShareHistory: 'Udostępnij historię serwisową',
    menuFinish: 'Zmień właściciela',
    menuHistory: 'Przejdź do historii serwisowej',
    historyLoadMore: 'Załaduj więcej historii serwisowych',
    historyEmpty: 'Brak historii serwisowej',
    historyRefresh: 'Odśwież historie serwisową',
    errorShowingCar: 'Problem z wyświetleniem samochodu',
  },
  calendar: {
    date: 'Data',
    time: 'Czas',
    event: 'Wydarzenie',
    allDay: 'Cały dzień',
    week: 'Tydzień',
    work_week: 'Tydzień pracy',
    day: 'Dzień',
    month: 'Miesiąc',
    previous: 'Poprzedni',
    next: 'Następny',
    yesterday: 'Wczoraj',
    tomorrow: 'Jutro',
    today: 'Dziś',
    agenda: 'Agenda',
    noEventsInRange: 'Brak wydarzeń w tym zakresie.',
    showMore: 'więcej',
    legend: 'Legenda',
  },
  common: {
    appHeader: 'Aplikacja dla warsztatów',
    error400Template: 'Request failed with status code 400',
    error400Text: 'Błąd przy zapisywaniu danych',
    error404Template: 'Request failed with status code 404',
    error404Text: 'Problem z pobraniem danych',
    errorNoActiveText: 'Nie znaleziono aktywnego konta z podanymi danymi',
    requiredEmailField: 'Pole email jest wymagane',
    requiredPasswordText: 'Pole hasła jest wymagane',
    maxLength: 'Przekroczono dozwoloną liczbę znaków',
    loginTitle: 'Logowanie',
    loginText: 'Zaloguj się',
    emailText: 'Email',
    passwordText: 'Hasło',
    pageNotFound: 'Nie mogliśmy znaleźć tej strony',
    serverError: 'Błąd serwera',
    goBackToMainPage: 'Powrót do strony głównej',
    text404: '404',
    text500: '500',
    errorMissingCredentials: 'Missing credentials.',
    no: 'Nie',
    yes: 'Tak, zakończ',
    yesRemove: 'Tak, usuń',
    commonYes: 'Tak',
    send: 'Wyślij',
    goBack: 'Wróć',
    addInfoToProfile: 'Uzupełnij profil',
    currency: 'PLN',
    edit: 'Edytuj',
    remove: 'Usuń',
    refreshing: 'Odświeżanie',
    deviceAndroid: 'android',
    deviceIOS: 'ios',
    requiredFieldsInfo: '* Pole obowiązkowe',
    selectItem: 'Wybierz',
    and: ' i ',
    andSecond: ' oraz ',
    copied: 'Skopiowano',
    offline: 'Brak połączenia',
    meldmoto: 'MeldMoto',
    copyright: '© 2022 MeldMoto - Wszelkie prawa zastrzeżone',
    invalidPriceFormat: 'Nieprawidłowa cena',
    invalidNumberFormat: 'Nieprawidłowa liczba',
  },
  seo: {
    appDescription: 'Aplikacja dla warsztatów MeldMoto.',
    author: 'MeldMoto',
    mainPage: 'Strona główna',
    loading: 'Ładowanie',
    calendar: 'Kalendarz',
    cars: 'Samochody',
    panel: 'Aplikacja dla warsztatów',
    visits: 'Wizyty',
    signIn: 'Zaloguj się',
    subscription: 'Subskrypcja',
    invoices: 'Faktury',
    profile: 'Profil',
    terms: 'Regulamin',
  },
  menuItems: {
    dashboard: 'Pulpit',
    visits: 'Wizyty',
    calendar: 'Kalendarz',
    cars: 'Samochody',
    workshop: 'Warsztat',
    settings: 'Ustawienia',
  },
  maintenance: {
    maintenanceInfo: 'W tym momencie trwają prace serwisowe.',
    maintenanceDateInfo: 'Aplikacja będzie niedostępna do',
    maintenanceTitle: 'Pracujemy nad aplikacją',
  },
  map: {
    missingPhoto: 'Brak zdjęcia',
  },
  settings: {
    label: 'Ustawienia',
    profile: 'Profil',
    loginText: 'Zaloguj się',
    logoutText: 'Wyloguj się',
    saveText: 'Zapisz',
    userNameText: 'Nazwa użytkownika',
    slugText: 'Nazwa użytkownika', // Change in the future to slug.
    nameSurnameText: 'Imię i nazwisko',
    nameText: 'Imię',
    surnameText: 'Nazwisko',
    emailText: 'Email',
    phoneText: 'Telefon',
    addPhoneText: 'Dodaj telefon',
    bioText: 'O sobie',
    yourCardText: 'Twoja wizytówka',
    noInfoText: 'Brak informacji',
    editText: 'Edycja',
    editProfileText: 'Edytuj profil',
    setNotifications: 'Powiadomienia',
    notificationsHeaderText: 'Ustawienia powiadomień',
    cookiesHeaderText: 'Ustawienia ciasteczek',
    accountHeaderText: 'Ustawienia konta',
    technicalCookies: 'Ciasteczka techniczne',
    analyticsCookies: 'Ciasteczka analityczne',
    marketingCookies: 'Ciasteczka marketingowe',
    deleteAccount: 'Usuń konto',
    deleteAccountScreen: 'Usuwanie konta',
    consentScreen: 'Ustawienia prywatności',
    clientDeleteInfo: `Usunięcie konta wiąże się z rozwiązaniem umowy. Twoje konto zostanie dezaktywowane bezpośrednio po usunięciu. Dane znajdujące się na Twoim koncie, odnoszące się do rezerwacji, wykonanych serwisów i opinii dotyczących danego warsztatu zostaną zachowane. Pozostałe dane zostaną bezpowrotnie usunięte`,
    workshopDeleteInfo: `Usunięcie konta wiąże się z rozwiązaniem umowy. Twoje konto zostanie dezaktywowane bezpośrednio po usunięciu. Dane znajdujące się na Twoim koncie, odnoszące się do rezerwacji, wykonanych serwisów i opinii dotyczących warsztatu zostaną zapisane w ramach kopii zapasowej i będą przechowywane przez okres 24 miesięcy od dnia usunięcia konta. Przy ponownej rejestracji z podaniem adresu e-mail poprzednio przypisanego do konta, uzyskasz dostęp do danych historycznych, jeśli ponowna rejestracja nastąpi w okresie przechowywania danych.`,
    deleteCheckboxMessage:
      'Potwierdzam że wszystkie wizyty (Umowy Serwisu) zostały anulowane lub zakończone',
    deletePasswordLabel: 'Potwierdź hasło przed usunięciem',
    deletePasswordPlaceholder: 'Hasło',
    deleteReasonLabel: 'Pomóż nam się rozwijać',
    deleteReasonPlaceholder: 'Dlaczego usuwasz konto?',
    deleteAccountConfirm: 'Czy na pewno chcesz usunąć konto?',
    deleteCardConfirm: 'Czy napewno chcesz usunąć kartę?',
    sendErrorFeedback: 'Zgłoś błąd',
    policy: 'Polityka prywatności i regulaminy',
    sendButton: 'Wyślij',
    issueCategory: 'Typ zgłoszenia',
    issueTitle: 'Tytuł',
    issueTextContent: 'Treść',
    createTicket: 'Zgłoś błąd',
    yourTickets: 'Twoje zgłoszenia',
    category: 'Kategoria',
    accountSettings: 'Ustawienia konta',
    syncPaymentSystemText: 'Synchronizacja z systemem płatności',
    wallet: 'Portfel',
    walletLabel: 'Portfel MeldMoto',
    createWallet: 'Załóż portfel',
    createWalletButton: 'Zakładam portfel',
    createNaturalUser: 'Uzupełnij dane rozliczeniowe',
    createNaturalUserButton: 'Zapisz dane rozliczeniowe',
    createLegalUser: 'Uzupełnij dane rozliczeniowe',
    createLegalUserButton: 'Zapisz dane rozliczeniowe',
    availableFunds: 'Środki dostępne do wypłaty',
    goToPayout: 'Przejdź do wypłaty na konto',
    payout: 'Wypłać środki na konto',
    payOutAmount: 'Kwota wypłaty',
    makePayOut: 'Zleć wypłatę środków',
    payOutSucceededText: 'Dziękujemy!\nZleciliśmy wypłatę środków na konto',
    invalidPayOutValue: 'Niewystarczająca ilość środków',
    pendingFunds: 'Środki oczekujące',
    pendingFundsDescription:
      'Środki te zostaną przeniesione na \n"Środki dostępne do wypłaty" do 5 dni po zakończeniu wizyty.',
    pendingFundsTime:
      'Środki te zostaną przeniesione na \n"Środki dostępne do wypłaty" do 5 dni roboczych.',
    pendingFundsInfo:
      'Jeśli w portfelu pojawią się środki za zwrot usługi, zostaną one automatycznie przelane na Twoje konto.',
    addPaymentMethod: 'Dodaj metodę płatności',
    billingDataLabel: 'Dane rozliczeniowe',
    currency: 'Waluta',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    birthday: 'Data urodzenia',
    addressLine1: 'Adres (linia 1)',
    addressLine2: 'Adres (linia 2)',
    city: 'Miasto',
    birthplaceCity: 'Miasto urodzenia',
    postalCode: 'Kod pocztowy',
    region: 'Województwo',
    country: 'Kraj',
    birthplaceCountry: 'Kraj urodzenia',
    nationality: 'Obywatelstwo',
    companyLegalName: 'Firma',
    legalPersonType: 'Rodzaj działalności',
    legalRepresentativeFirstName: 'Imię właściciela',
    legalRepresentativeLastName: 'Nazwisko właściciela',
    legalRepresentativeEmail: 'Email właściciela',
    legalRepresentativeBirthday: 'Data urodzenia właściciela',
    legalRepresentativeAddressLine1: 'Adres (linia 1) właściciela',
    legalRepresentativeAddressLine2: 'Adres (linia 2) właściciela',
    legalRepresentativeCity: 'Miasto właściciela',
    legalRepresentativeRegion: 'Województwo właściciela',
    legalRepresentativePostalCode: 'Kod pocztowy właściciela',
    legalRepresentativeCountry: 'Kraj właściciela',
    legalRepresentativeNationality: 'Obywatelstwo właściciela',
    legalRepresentativeCountryOfResidence: 'Kraj zamieszkania właściciela',
    companyNumber: 'Numer firmy (KRS, REGON)',
    taxNumber: 'NIP',
    headquartersAddressAddressLine1: 'Adres (linia 1) siedziby',
    headquartersAddressAddressLine2: 'Adres (linia 2) siedziby',
    headquartersAddressCity: 'Miasto siedziby',
    headquartersAddressRegion: 'Województwo siedziby',
    headquartersAddressPostalCode: 'Kod pocztowy siedziby',
    headquartersAddressCountry: 'Kraj siedziby',
    createPaymentAccount: 'Załóż konto rozliczeniowe',
    createAddress: 'Dodaj adres rozliczeniowy',
    payments: 'Płatności',
    paymentsLabel: 'Menu płatności',
    paymentMethods: 'Metody płatności',
    bankAccountLabel: 'Konto bankowe do wypłaty',
    bankAccounts: 'Konta bankowe do wypłaty',
    addBankAccount: 'Dodaj konto bankowe',
    nameSurnameHolderText: 'Imię i nazwisko posiadacza',
    iban: 'Numer konta bankowego (IBAN)',
    createBankAccountButton: 'Zapisz konto bankowe',
    createCardRegistration: 'Wybierz walutę karty',
    createCardRegistrationCurrency: 'Dodaj kartę',
    addCardInfo: 'Podaj dane karty',
    cardNumber: 'Numer karty',
    cardExpirationMonth: 'Miesiąc ważności',
    cardExpirationYear: 'Rok ważności',
    cardExpiration: 'Data ważności',
    cardExpired: 'Karta wygasła',
    cardCvx: 'Kod bezpieczeństwa',
    confirmText: 'Wybierz',
    cancelText: 'Zamknij',
    refreshText: 'Odśwież',
    policyTitle: 'Polityka prywatnosci',
    policyTitleIn: 'Polityce prywatnosci',
    policyTitleFor: 'Polityką prywatnosci',
    cookiesTitle: 'Polityka cookies',
    cookiesTitleIn: 'Polityce cookies',
    cookiesTitleFor: 'Polityką cookies',
    termsTitle: 'Regulamin',
    payOutLabel: 'Wypłata środków',
    documentStatus: 'Status',
    documentTypeName: 'Typ dokumentu',
    IBANInfoTitle:
      'Przed numerem konta bankowego IBAN\npodaj dwucyfrowy kod kraju (bez spacji).\nDla polskiego konta kod to ',
    IBANPL: 'PL',
    IBANInfoText: 'Przykładowy IBAN:\nPL99990000111122223333444455',
    addDocument: 'Dodaj',
    documentRefusalReason: 'Powód: ',
    payoutDisabledDescription: 'Twój profil musi zostać zweryfikowany przed wypłatą środków',
    payoutDisabledNoBankAccountDescription: 'Dodaj konto bankowe do wypłaty',
    createKYCDocumentLabel: 'Dodawanie dokumentu',
    scanKYCDocumentNoCameraPermission: 'Prośba o dostęp do kamery',
    scanKYCDocumentForegroundText: 'Umieść dokument przed kamerą\n i złap ostrość',
    scanKYCDocumentProcessing: 'Przetwarzam...',
    scanKYCGuidelines:
      'Zadbaj o dobre oświetlenie i ostrość zdjęć dokumentów. Pozwoli to szybsze sprawdzenie i przyspieszy proces weryfikacji Twojego profilu.',
    scanKYCDocumentPageNumber: 'Zeskanowana ilość stron: ',
    acceptedDocumentsHeader: 'Akceptowane dokumenty',
    documentPassport: 'Paszport',
    documentNationalId: 'Dowód osobisty',
    documentDrivingLicense: 'Prawo jazdy',
    documentResidencePermit: 'Zezwolenie na pobyt',
    documentRegisterExtract: 'Wyciąg z Krajowego Rejestru Sądowego\n(nie starszy niż 3 miesiące)',
    documentNotarialDeed: 'Aktualny i podpisany akt notarialny',
    identityProofTutorialLabel: 'Zalecenia dot. skanowania dokumentu',
    documentUBODeclaration: 'Wypełnij listę beneficjentów rzeczywistych w formularzu poniżej',
    uboLists: 'Beneficjenci rzeczywiści',
    uboText: 'Beneficjent',
    createUboButton: 'Zapisz dane beneficjenta',
    createDocument: 'Stwórz dokument',
    createPage: 'Zeskanuj kolejną stronę',
    submitDocument: 'Zakończ skanowanie',
    submitDocumentSubTitle: 'i wyślij dokument do weryfikacji',
    createUBODeclarationLabel: 'Dodawanie deklaracji',
    uboGuidelines:
      'Uzupełnij listę wszystkich beneficjenów rzeczywistych (UBO) którzy są w posiadaniu co najmniej 25% udziałów (maksymalnie 4 osoby).',
    createUBODeclaration: 'Stwórz deklarację',
    createUBODeclarationAgain: 'Stwórz nową deklarację',
    submitUBODeclaration: 'Wyślij deklarację',
    addUboText: 'Dodaj',
    uboDeclarationPreview: 'Podgląd',
    createUboLabel: 'Uzupełnij dane beneficjenta',
    missingUboText: 'Brak',
    version: 'Wersja:',
    currentVersionText: 'Zainstalowana wersja: ',
    latestVersionText: 'Najnowsza wersja: ',
    requireUpdateTitle: 'Zaktualizuj aplikację',
    requireUpdateInfo:
      'Twoja aplikacja jest nieaktualna.\nWciśnij aktualizuj by móc w pełni z niej korzystać.',
    requireUpdateButton: 'Aktualizuj',
    deletePhotoAlert: 'Czy napewno chcesz usunąć zdjęcie?',
    fillFields: 'Wypełnij obowiązkowe pola',
    errorWallet: 'Problem z załadowaniem portfela',
    errorPayments: 'Problem z załadowaniem płatności',
    errorPayOut: 'Problem z załadowaniem środków',
    error400Template: 'Request failed with status code 400',
    error400Text: 'Błąd przy zapisywaniu danych',
    error404Template: 'Request failed with status code 404',
    error404Text: 'Problem z pobraniem danych',
    errorNotifications: 'Powiadomienia niedostępne',
    errorMissingCredentials: 'Missing credentials.',
    subscription: 'Subskrypcja',
    subscriptionCheckoutButton: 'Opłać subskrypcję',
    subscriptionCardLabel: 'Wpisz dane karty',
    invoices: 'Faktury',
    plans: 'Plany',
    plan: 'Plan',
    downloadInvoicePDF: 'Pobierz fakturę',
    dateCreated: 'Data',
    invoiceData: 'Dane do faktury',
    total: 'Kwota',
    status: 'Status',
    collectionMethod: 'Metoda rozliczenia',
    download: 'Pobierz',
    net: 'Netto',
    gross: 'Brutto',
    tax: 'VAT',
    subscriptionStatus: 'Status planu',
    createDate: 'Utworzona',
    currentPeriodStart: 'Rozpoczęcie okresu rozliczeniowego',
    currentPeriodEnd: 'Zakończenie okresu rozliczeniowego',
    period: 'Okres rozliczeniowy',
    yourSubscription: 'Twoja subskrypcja',
    noActiveSubscription: 'Brak aktywnej subskrypcji',
    missingInvoiceDate: 'Uzupełnij dane do faktury',
    cancelSubscription: 'Anuluj subskrypcję',
    editInvoiceData: 'Edytuj dane',
    address: 'Adres',
    cancelled3DSecureFlow: 'Transakcja przerwana. Proszę spróbować później',
    noAddressValue: '-',
  },
  workshop: {
    addMissingPhoto: 'Dodaj zdjęcie',
    missingPhoto: 'Brak zdjęcia',
    map: 'Mapa',
    details: 'Więcej',
    error400Text: 'Błąd przy zapisywaniu danych',
    phone: 'Telefon',
    noWorkshopText: 'Brak warsztatu',
    noInfoText: 'Brak informacji',
    noInfoPhone: 'Brak numeru telefonu',
    noInfoEmail: 'Brak adresu email',
    email: 'Email',
    edit: 'Edytuj',
    contactLabel: 'Kontakt',
    descriptionLabel: 'O warsztacie',
    openingHoursLabel: 'Godziny otwarcia',
    priceOfferLabel: 'Cennik',
    priceListLabel: 'Cennik',
    priceListText: 'Cennik warsztatu',
    priceListButtonText: 'Zobacz cennik',
    showOpinionsText: 'Zobacz opinie',
    temporaryInactive: 'Niedostępne',
    manHour: 'Godzina pracy',
    confirmText: 'Wybierz',
    cancelText: 'Zamknij',
    open24h: 'Otwarte 24h',
    closed: 'Zamknięte',
    chooseDate: 'Wybierz',
    from: 'od',
    to: 'do',
    saveText: 'Zapisz',
    addText: 'Dodaj',
    deleteText: 'Usuń',
    editWorkshopOpeningHoursButtonText: 'Edytuj godziny otwarcia',
    book: 'Rezerwuję',
    bookDescription: 'Umów wizytę w tym punkcie',
    preferredVisitTime: 'Preferowane godziny wizyt',
    addNewPreferredVisitTime: 'Dodaj nową godzinę',
    noPreferredTimeText: 'Brak preferowanych godzin',
    searchEmployees: 'Szukaj pracowników',
    ownerText: 'Właściciel',
    activeText: 'Aktywny',
    preferredTimeDescription:
      'Ustawienie godzin pozwoli Ci szybciej zaproponować termin wizyty klientom.',
    finishPreferredHoursAlertTitle: 'Usuń godzinę',
    finishPreferredHoursAlertMessage:
      'Czy chcesz usunąć godzinę? Dzieki nim szybciej zaproponujesz termin wizyty klientom',
    workshopType: 'Specjalizacje',
    workshopTypesDescription:
      'Wybierz specjalizacje swojej działalności.\nUłatwi to znalezienie Cię klientom',
    addEmployeeConfirm: 'Czy napewno chcesz dodać pracownika?',
    removeEmployeeConfirm: 'Czy napewno chcesz usunąć pracownika?',
    allTypes: 'Wszystkie serwisy',
    priceList: 'Usługi i cennik',
    priceWithVat: 'Cena od (z VAT)',
    noPriceText: '-',
    editPriceList: 'Edytuj cennik',
    priceListDescription: 'Ustal ceny usług w swoim warsztacie.',
    menuInfo: 'Info',
    menuSettings: 'Ustawienia',
    menuEmployees: 'Zespół',
    yesAdd: 'Tak, dodaj',
    searchEmployeesDescription: 'Wyszukaj pracownika po imieniu, nazwisku lub adresie email',
    searchEmployeesNotFound: 'Brak wyników wyszukiwania',
    reviewLabel: 'Opinie',
    reviewTitle: 'Opinie dla',
    reviewInfo: 'Opinie wystawione przez klientów',
    reviewReleased: 'Opublikowano: ',
    reviewDelete: 'Usuń Opinie',
    reviewReport: 'Zgłoś opinie',
    reviewScope: '/5',
    reviewScreenInfoEnd: 'opinii',
    reviewScreenInfoEndSingular: 'opinia',
    reviewUnknown: 'Anonimowy',
    reviewNoRate: 'brak',
    reviewReported: 'Opinia zgłoszona',
  },
  visit: {
    todaysVisits: 'Dziś',
    ongoingVisits: 'W trakcie',
    newVisits: 'Nowe',
    bookLabel: 'Rezerwacja',
    chooseCarText: 'Wybierz pojazd',
    preferredVisitTime: 'Preferowana pora wizyty',
    chosenWorkshop: 'Wybrany warsztat',
    book: 'Rezerwuję',
    morning: 'Rano',
    afternoon: 'Popołudniu',
    search: 'Wyszukiwarka',
    clientsNumber: 'Numer klienta',
    errorBooking: 'Błąd przy tworzeniu rezerwacji',
    noPhoneBookingAlertTitle: 'Dodaj numer telefonu',
    noPhoneBookingAlertMessage: 'Przed rozpoczęciem wizyty uzupełnij numer telefonu w Ustawieniach',
    noWalletBookingAlertTitle: 'Dodaj konto rozliczeniowe i portfel',
    noWalletBookingAlertMessage:
      'Przed rozpoczęciem wizyty uzupełnij konto rozliczeniowe i portfel w Ustawieniach',
    beginVisitAlertTitle: 'Rozpocznij wizytę',
    beginVisitAlertMessage: 'Czy na pewno chcesz rozpocząć wizytę?',
    cancelVisitAlertTitle: 'Anuluj wizytę',
    cancelVisitAlertMessage: 'Czy na pewno chcesz anulować rezerwacje wizyty?',
    chooseVisitAlertTitle: 'Potwierdź termin wizyty',
    chooseVisitAlertMessage: 'Czy na pewno chcesz wybrać ten termin wizyty?',
    completeVisitAlertTitle: 'Potwierdź gotowość',
    completeVisitAlertMessage: 'Czy pojazd na pewno jest gotowy od odbioru?',
    finishVisitAlertTitle: 'Zakończ wizytę',
    finishVisitAlertMessage: 'Czy na pewno chcesz zakończyć wizytę?',
    rejectVisitAlertTitle: 'Odrzuć wizytę',
    rejectVisitAlertMessage: 'Czy na pewno chcesz odrzucić wizytę?',
    payForOrderAlertTitle: 'Zapłać za wizytę',
    payForOrderAlertMessage: 'Czy na pewno chcesz zapłacić za wizytę?',
    askNewTimeTitle: 'Zapytanie o inne terminy',
    askNewTimeMessage: 'Czy na pewno chcesz poprosić o inne terminy wizyty',
    yesBegin: 'Tak, rozpocznij',
    yesCancel: 'Tak, anuluj',
    yesChoose: 'Tak, wybierz',
    yesComplete: 'Tak, gotowe',
    yesFinish: 'Tak, zakończ',
    yesReject: 'Tak, odrzuć',
    yesPay: 'Tak, zapłać',
    yesConfirm: 'Tak, potwierdź',
    yesAsk: 'Tak, poproś',
    yesFill: 'Uzupełnij',
    noClose: 'Zamknij',
    no: 'Nie',
    confirmText: 'Wybierz',
    cancelText: 'Zamknij',
    saveText: 'Zapisz',
    sendText: 'Wyślij',
    finishText: 'Zakończ',
    beginVisit: 'Rozpocznij wizytę',
    openVisitButton: 'Otwórz wizytę',
    visitAlreadyExists: 'Wizyta już istnieje',
    selectVisitTime: 'Wybierz termin wizyty',
    messageFromClientLabel: 'Wiadomość dla warsztatu',
    messageFromWorkshopLabel: 'Wiadomość do klienta',
    messageMissing: 'Brak wiadomości',
    visitWithoutCustomerTitleText: 'Wizyta bez klienta',
    visitWithoutCustomerText:
      'Poproś klienta o dołączenie do aplikacji i zeskanownie dowodu rejestracyjnego. Pozwoli to na płatność przez aplikację, zapisanie historii serwisowej i ułatwi rezerwację następnym razem.',
    visitPlace: 'Miejsce usługi',
    visitTime: 'Termin',
    visitTimeLabel: 'Termin wizyty',
    visitNewTimeLabel: 'Nowe terminy wizyty',
    visitTimeAlternativeLabel: 'Alternatywny termin wizyty',
    previousVisitsLabel: 'Poprzednie propozycje',
    visitHour: 'Godzina',
    firstVisitProposition: 'Termin 1',
    secondVisitProposition: 'Termin 2',
    status: 'Status',
    checkPaymentStatus: 'Sprawdź status płatności',
    checkStatus: 'Sprawdź status',
    summary: 'Podsumowanie',
    item: 'Usługa',
    service: 'Usługa',
    part: 'Część',
    itemService: 'Nazwa',
    addItem: 'Dodaj',
    addParts: 'Dodaj części',
    addCustomItem: 'Dodaj niestandardowe',
    emptyCartAlert: 'Uzupełnij zawartość koszyka, przed wysłaniem prośby o potwierdzenie',
    emptyCartText: 'Pusty koszyk',
    addItemFromPriceList: 'Z cennika',
    quantityNumber: 'Ilość',
    quantity: 'Ilość / Czas',
    price: 'Cena (z VAT)',
    priceNoVat: 'Cena',
    priceFrom: 'Cena od (z VAT)',
    pricePerItem: 'Cena jedn.',
    pricePerItemLabel: 'Cena jednostkowa',
    resultsPrice: 'Kwota zamówienia ',
    withVatClosed: '(z VAT): ',
    priceForClientFrom: 'Cena dla klienta od (z VAT)',
    withVat: 'z VAT',
    invalidPrice: 'Niepoprawna wartość',
    finishEditingCart: 'Zakończ edytowanie koszyka',
    itemName: 'Nazwa',
    fee: 'Opłata serwisowa',
    sumWorkshop: 'Należność dla warsztatu',
    sumTotal: 'Do zapłaty',
    priceForClient: 'Cena dla klienta',
    sumTotalHistory: 'Zapłacono',
    clientPriceDescription: 'Dla klienta',
    sumDescription: 'Kwota na rachunku dla klienta',
    payForVisit: 'Zapłać w aplikacji',
    payRemind: 'Przypomnij o zapłacie',
    editCart: 'Edycja koszyka',
    cartText: 'Koszyk',
    serviceCartModalTitleAddCustom: 'Dodaj niestandardową usługę',
    serviceCartModalTitleAddParts: 'Dodaj części',
    serviceCartModalTitleEdit: 'Edytuj usługę lub przedmiot',
    serviceCartModalTitleAddPriceList: 'Dodaj usługę z cennika',
    serviceCartModalServiceInfo: 'Opisz usługę lub przedmiot',
    createReport: 'Stwórz raport',
    lastMileage: 'Ostatni wpis przy',
    reportDescription: 'Oceń stan pojazdu',
    report: 'Raport',
    mileage: 'Przebieg',
    brake: 'Płyn hamulcowy',
    coolant: 'Płyn chłodniczy',
    oil: 'Olej',
    tires: 'Opony',
    lights: 'Oświetlenie',
    conditionGood: 'Dobry',
    conditionBad: 'Zły',
    reportUnavailable: 'Raport niedostępny',
    reportNonVisible: 'Raport dostępny do opłaceniu wizyty',
    errorCreatingServiceReport: 'Błąd przy tworzeniu raportu',
    wishList: 'Lista życzeń',
    editWishList: 'Edytuj listę życzeń',
    emptyWishList: 'Brak wybranych usług',
    addItemToWishList: 'Wybierz z listy',
    addWishListItemFromPriceList: 'Dodaj usługę z cennika',
    itemsPriceList: 'Usługi do wyboru',
    noItemsToChoose: 'Brak usług w cenniku',
    priceListEditInstructionText:
      'Cennik możesz edytować w menu\nWarsztat - Ustawienia - Usługi i cennik',
    order: 'Zamów',
    selectItem: 'Wybierz',
    historyEntriesKm: 'Wpisz liczbę kilometrów',
    cartConfirmText: 'Potwierdzenie usług do wykonania',
    cartConfirmClientDescription:
      'Przed rozpoczęciem usługi, serwis poprosi Cię o potwierdzenie wyceny',
    cartConfirmWorkshopDescription: 'Przed rozpoczęciem usługi\npoproś klienta o potwierdzenie',
    askConfirmButtonText: 'Poproś klienta o potwierdzenie',
    additionalCartConfirmText: 'Potwierdzenie dodatkowych usług',
    additionalServiceButtonText: 'Niezaplanowany wydatek',
    additionalServiceDescriptionText:
      'W przypadku niezaplanowanej awarii, skontaktuj się z klientem i dodaj wydatek do listy. Wymaga to dodatkowego potwierdzenia przez klienta.',
    acceptedCartItem: 'Zaakceptowano',
    rejectedCartItem: 'Odrzucono',
    additionalHeader: 'Dodatkowe usługi',
    refreshVisitsText: 'Kliknij aby odświeżyć',
    additionalCartConfirmClientDescription:
      'Serwis poprosi Cię o potwierdzenie dodatkowych usług. Niezaakceptowane usługi, nie zostaną wykonane',
    additionalCartConfirmWorkshopDescription:
      'Przed rozpoczęciem dodatkowych usług\npoproś klienta o potwierdzenie',
    additionalServicesWaitForClient: 'Poczekaj na akceptację dodatkowych usług',
    additionalServicesAcceptOrReject: 'Zaakceptuj lub odrzuć dodatkowe usługi przed płatnością',
    confirmServiceScopeButtonText: 'Kupuję i płacę',
    confirmAlert: 'Potwierdź koszt i zakres usług',
    rejectServiceScopeButtonText: 'Odrzucam',
    rejectAlert: 'Odrzuć koszt i zakres usług',
    cartAskedWorkshopStatus: 'Wysłano prośbę o potwierdzenie usług',
    missingPaymentInfoAlert: 'Niepełny profil',
    missingWalletAlert: 'Dodaj portfel w menu portfel zanim poprosisz o akceptację Wyceny',
    missingPaymentDetailsAlert:
      'Podaj dane do płatności oraz stwórz portfel zanim poprosisz o akceptację Wyceny',
    cartConfirmedWorkshopStatus: 'Klient potwierdził koszt i zakres usług',
    cartRejectedWorkshopStatus: 'Klient odrzucił koszt i zakres usług',
    cartNotAskedClientStatus:
      'Sprawdź nowe zmiany w koszyku usług \nSerwis wkrótce poprosi Cię o potwierdzenie',
    cartConfirmedClientStatus: 'Potwierdzono koszt i zakres usług',
    cartRejectedClientStatus: 'Odrzucono koszt i zakres usług',
    rejectReasonLabel: 'Dlaczego odrzucasz wizytę?',
    rejectReasonPlaceholder: 'Podaj powód odrzucenia',
    rejectScreenHeader: 'Odrzucanie wizyty',
    rejectionReason: 'Powód odrzucenia',
    invoiceCheckbox: 'Chcę otrzymać fakturę',
    invoiceCheckboxInfo: '(Wybór możliwy tylko dla nowej wizyty)',
    invoiceTaxNumberReminder: 'W pole poniżej proszę wpisać numer NIP',
    invoiceInformation: 'Klient wystąpił\no wystawienie faktury VAT',
    alternativeTimeText: 'Wybierz co najmniej jeden alternatywny termin dla klienta',
    appraisalHelperText: 'Dodaj wszystkie usługi do koszyka i poproś klienta o potwierdzenie',
    paymentMethods: 'Metody płatności',
    paymentCard: 'Karta',
    p24: 'Przelewy24',
    pressToPay: 'Kliknij aby zapłacić',
    addCardText: 'Dodaj kartę',
    termsReservationFirstPart:
      'Klikając „Rezerwuję” wyrażasz zgodę na dokonanie rezerwacji wybranego Serwisu i akceptujesz ',
    termsReservationSecondPart:
      '. W celu wykonania Serwisu, wyrażasz zgodę na przekazanie twoich danych osobowych wybranemu warsztatowi.',
    termsInfoTerms: 'Regulamin Meldmoto',
    termsInfoIncluding: ', w tym z ',
    termsAcceptingServiceFirstPart:
      'Klikając „Kupuję i płacę” złożysz zamówienie realizacji Serwisu. Kontynuując, dobrowolnie zgadzasz się z ',
    termsAcceptingServiceSecondPart:
      ', a także wyrażasz zgodę na otrzymanie rachunku w formie elektronicznej na podany adres e-mail. W przypadku płatności przez MangoPay zgadasz się również z ',
    mangopayTerms: 'Regulaminem płatności',
    tempsRejectService:
      'Klikając „Odrzucam” zgadzasz się na zryczałtowana płatność za usługę Wyceny zgodnie z ',
    lumpSumPayment: 'Zryczałtowana płatność za usługę Wyceny',
    lumpSumPaymentAmount: 'Zryczałtowana płatność za Wycenę 50,00 zł',
    appraisalInfoText:
      'Z chwilą dostarczenia Pojazdu do Warsztatu, Warsztat przystępuje do Wyceny. W przypadku akceptacji Wyceny, zostaje ona wliczona w cenę Serwisu.\nW przypadku odrzucenia Wyceny, zgadzasz się na obciążenie w wysokości 50,00 złotych brutto na rzecz Warsztatu. Więcej szczegółów w ',
    appraisal: 'Usługa Wyceny',
    appraisalValue: '50',
    termsInfoTermsIn: 'Regulaminie Meldmoto',
    noConnectedPaymentsText: 'Przed rezerwacją wprowadź dane do płatności oraz załóż portfel.',
  },
  auth: {
    termsInfoFirst: 'Klikając „Zaloguj się”, dobrowolnie zgadzasz się z ',
    termsInfoSecond:
      ', a także wyrażasz zgodę na otrzymanie rachunku w formie elektronicznej na podany adres e-mail.',
    termsInfoAnd: ' Meldmoto oraz',
    termsInfoTerms: 'Regulaminem',
    termsInfoPolicy: ' Polityką prywatności',
  },
  consent: {
    title: 'Witamy w MeldMoto !',
    descriptionText:
      'Aby zapewnić Ci jak najlepsze korzystanie z naszej aplikacji, korzystamy z cookies do następujących celów:',
    descriptionTextRowOne: '\u2713  Działania aplikacji i świadczenia naszych usług',
    descriptionTextRowTwo:
      '\u2713  Poprawa wydajności działania aplikacji dzięki zastosowaniu analityki  ',
    descriptionTextRowThree:
      '\u2713  Dostarczenia Ci materiałów reklamowych, dostosowanych do Twoich oczekiwań',
    buttonsDescription:
      'Jeśli wybierzesz opcję „Zezwól na wszystkie”, będziemy stosować pliki cookies, a Twoje dane mogą zostać przekazane naszym zaufanym partnerom. Jeśli odmówisz, wykorzystamy tylko niezbędne pliki cookies do poprawnego funkcjonowania Strony. Wybierz “Ustawienia preferencji”, i dokonaj szczegółowych wyborów. Masz pytania? Więcej informacji znajdziesz w naszej ',
    preferencesDescription:
      'Zapoznaj się z informacją, jakie pliki cookies stosujemy w ramach naszej aplikacji. Wybierz odpowiadają Ci ustawienia. Zawsze możesz powrócić do wyboru ustawień. Zachęcamy również do zapoznania się z naszą ',
    preferencesDescriptionPartTwo:
      ', w których wyjaśniamy wszelkie kwestie związane z ochroną danych osobowych.\n\nMasz pytanie? Skontaktuj się z nami: hello@meldmoto.pl',
    cookiesNecessaryTitle: 'Cookies niezbędne (cookies własne)',
    cookiesNecessaryText: `Cookies niezbędne służą do prawidłowego działania Aplikacji, zapewnienia jej funkcjonalności i bezpieczeństwa. Niestety, nie możesz wyłączyć tych ciasteczek ponieważ po ich wyłączeniu nasza Aplikacja nie będzie poprawnie działać.`,
    cookiesAnalyticsTitle: 'Cookies analityczne i statystyczne',
    cookiesAnalyticsText: `Cookies potrzebne do zbierania informacji o sposobie korzystania z naszej Aplikacji w celu optymalizacji jej działania, prowadzenia statystyk. Jeśli nie wyrazisz zgody na wykorzystywanie tych cookies, nie będziemy w stanie monitorować działania Aplikacji. Bez tych plików niektóre funkcje mogą stać się niedostępne.`,
    cookiesMarketingTitle: 'Cookies  marketingowe, w tym związane z profilowaniem',
    cookiesMarketingText: `Cookies reklamowe pozwalają dostarczyć Klientowi i Osobie Odwiedzającej treści reklamowe dostosowane do ich preferencji i zainteresowań, czy sposobu zachowania na naszej Stronie Internetowej. Korzystamy z działań remarketingowych z użyciem plików cookies stron trzecich. Cookies pozwalają tworzyć profile Osób Odwiedzających i Klientów, wyświetlania naszych reklam w innych miejscach w Internecie, a także mierzenia efektywności kampanii reklamowych. Pliki mogą zawierać informacje, że nasza Aplikacja została otwarta na danym urządzeniu, w danej lokalizacji. Część informacji możemy przekazywać naszym zaufanym partnerom. Nie wyrażenie zgody na ten rodzaj przetwarzania danych nie wpłynie w żaden sposób na funkcjonowanie Aplikacji.`,
    confirmAll: 'Zezwól na wszystkie',
    showPreferences: 'Ustawienia preferencji',
    hidePreferences: 'Zamknij',
    savePreferences: 'Zapisz preferencje',
  },
};
