import PropTypes from 'prop-types';
import { Button, Card, Flex, Text } from 'theme-ui';

import { SERVICE_SCOPES, STRINGS, VISIT_STATUSES } from '../../../constants';
import styles from './CartConfirmationCard.style';

const CartConfirmationCard = (props) => {
  const { status, serviceScopeStatus, askConfirmServiceScopeHandler, price, color, cardStyle } =
    props;

  const renderAskConfirmButton = () => {
    const disabled = price === undefined || price === 0;
    if (status === VISIT_STATUSES.APPRAISAL) {
      return (
        <Flex sx={styles.askConfirmationButtonContainer}>
          <Button
            disabled={disabled}
            onClick={askConfirmServiceScopeHandler}
            sx={disabled ? styles.disabledAskButton : styles.askButton}
          >
            {STRINGS.visit.askConfirmButtonText}
          </Button>
          {disabled && <Text sx={styles.emptyCartAlert}>{STRINGS.visit.emptyCartAlert}</Text>}
        </Flex>
      );
    }
    return null;
  };

  const renderWorkshopConfirmationStatusBar = () => {
    if (status === VISIT_STATUSES.SERVICE_CONFIRMATION_ASKED) {
      return (
        <Flex sx={styles.askedStatusContainer}>
          <Text sx={styles.askedStatusText}>{STRINGS.visit.cartAskedWorkshopStatus}</Text>
        </Flex>
      );
    }
    if (
      status === VISIT_STATUSES.SERVICE_REJECTED ||
      (status === VISIT_STATUSES.READY &&
        serviceScopeStatus === SERVICE_SCOPES.SERVICE_SCOPE_REJECTED) ||
      (status === VISIT_STATUSES.FINISHED &&
        serviceScopeStatus === SERVICE_SCOPES.SERVICE_SCOPE_REJECTED)
    ) {
      return (
        <Flex sx={styles.rejectedStatusContainer}>
          <Text sx={{ ...styles.rejectedStatusText, color }}>
            {STRINGS.visit.cartRejectedWorkshopStatus}
          </Text>
        </Flex>
      );
    }
    const serviceAdditionalStatus =
      serviceScopeStatus === SERVICE_SCOPES.SERVICE_SCOPE_ACCEPTED ||
      serviceScopeStatus === SERVICE_SCOPES.SERVICE_SCOPE_ACCEPTED_ADDITIONAL ||
      serviceScopeStatus === SERVICE_SCOPES.SERVICE_SCOPE_REJECTED_ADDITIONAL;
    if (
      status === VISIT_STATUSES.SERVICE_ACCEPTED ||
      (status === VISIT_STATUSES.READY && serviceAdditionalStatus) ||
      (status === VISIT_STATUSES.FINISHED && serviceAdditionalStatus)
    ) {
      return (
        <Flex sx={styles.confirmedStatusContainer}>
          <Text sx={{ ...styles.confirmedStatusText, color }}>
            {STRINGS.visit.cartConfirmedWorkshopStatus}
          </Text>
        </Flex>
      );
    }
    return null;
  };

  return (
    <Card variant="cartConfirmationCard" sx={styles.mainContainer}>
      <Flex sx={styles.labelContainer}>
        <Text sx={styles.label}>{STRINGS.visit.cartConfirmText}</Text>
        {status === VISIT_STATUSES.APPRAISAL && (
          <Text sx={styles.description}>{STRINGS.visit.cartConfirmWorkshopDescription}</Text>
        )}
      </Flex>
      <Flex sx={{ ...styles.container, ...cardStyle }}>
        {renderAskConfirmButton()}
        {renderWorkshopConfirmationStatusBar()}
      </Flex>
    </Card>
  );
};

CartConfirmationCard.propTypes = {
  status: PropTypes.string.isRequired,
  serviceScopeStatus: PropTypes.string.isRequired,
  askConfirmServiceScopeHandler: PropTypes.func,
  color: PropTypes.string.isRequired,
  price: PropTypes.number,
  cardStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

CartConfirmationCard.defaultProps = {
  askConfirmServiceScopeHandler: undefined,
  price: 0,
  cardStyle: {},
};

export default CartConfirmationCard;
