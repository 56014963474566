export default {
  mainContainer: {
    flexDirection: 'column',
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'space-between',
    lineHeight: 1.3,
  },
  container: {
    flexDirection: 'column',
  },
  cartItemContainer: {
    flexDirection: 'column',
    my: '5px',
  },
  error: {
    textAlign: 'center',
  },
  labelContainer: {
    flex: 1,
    flexDirection: 'row',
    mb: '1rem',
  },
  label: {
    fontSize: ['16px', null, '18px', '20px'],
  },
  additionalSectionContainer: {
    flex: 1,
    flexDirection: 'column',
    mt: '1rem',
  },
  additionalLabelContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  additionalLabel: {},
  additionalHeader: {},
  cartContainer: {
    flexDirection: 'column',
  },
  itemHeaderContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemNameContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'start',
    textAlign: 'left',
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: ['14px', null, '16px'],
  },
  strikeThroughHeaderText: {
    color: 'text_secondary',
    textDecorationLine: 'line-through',
    flex: 1,
  },
  subHeaderText: {
    flex: 1,
  },
  strikeThroughSubHeaderText: {
    color: 'text_secondary',
    textDecorationLine: 'line-through',
    flex: 1,
  },
  sumTotalText: {
    flex: 1,
  },
  strikeThroughSumTotalText: {
    color: 'text_secondary',
    textDecorationLine: 'line-through',
    flex: 1,
  },
  sumDescriptionText: {
    textAlign: 'left',
    color: 'text_secondary',
    fontSize: 1,
  },
  itemRowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemDetailsRowsOuterContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: 'text_secondary',
    fontSize: ['14px', null, '16px'],
  },
  itemDetailsRowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemRejectedText: {
    textTransform: 'uppercase',
  },
  itemQuantityText: {
    flex: 1,
  },
  itemQuantityResultText: {
    ml: '10px',
  },
  summaryContainer: {
    flexDirection: 'column',
    mb: '1rem',
  },
  itemNameText: {
    flex: 1,
  },
  itemText: {
    flex: 1,
  },
  strikeThroughItemNameText: {
    color: 'text_secondary',
    textDecorationLine: 'line-through',
    flex: 1,
  },
  strikeThroughItemText: {
    color: 'text_secondary',
    textDecorationLine: 'line-through',
    flex: 1,
  },
  emptyPriceText: {
    flex: 1,
  },
  editButton: {
    width: null,
    alignSelf: 'center',
  },

  descriptionAdditionalService: {
    mb: '10px',
    fontSize: 1,
  },
  additionalServiceContainer: {
    mt: '1rem',
    alignItems: 'center',
    flexDirection: 'column',
  },
  additionalServiceButton: {
    width: null,
  },
  additionalServiceButtonText: {
    flex: 1,
  },
  rejectedScopeInfoContainer: {},
  rejectedScopeInfoText: {
    textAlign: 'center',
    flex: 1,
  },
};
