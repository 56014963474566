export default {
  headerContainer: {
    justifyContent: 'space-between',
  },
  infoContainer: {
    flexDirection: 'column',
  },
  dateContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dateValue: {
    lineHeight: 1.4,
    fontSize: 3,
  },
  monthValue: {
    lineHeight: 1.2,
    fontSize: ['14px', null, '16px'],
  },
  yearValue: {
    lineHeight: 1.2,
    fontSize: ['14px', null, '16px'],
  },
  categoryContainer: {
    mr: '10px',
    alignItems: 'center',
  },
  labelText: {
    fontSize: ['12px', '14px', null, '16px'],
  },
  categoryText: {
    color: 'primary',
    ml: '5px',
    fontSize: ['12px', '14px', null, '16px'],
  },
  contentContainer: {
    bg: 'background_secondary',
    p: '5px 1rem',
    borderRadius: '10px',
    my: '10px',
  },
  value: {
    fontSize: ['12px', '14px', null, '16px'],
  },
};
