/* eslint-disable react/jsx-props-no-spreading */
import 'react-datepicker/dist/react-datepicker.css';

import { IoIosClose } from 'react-icons/io';
import { Box, Button, Card, Flex, IconButton, Input, Spinner, Text } from 'theme-ui';

// import { STRINGS as COMMON_STRINGS } from '../../constants';
import { STRINGS } from '../../constants/workshop';
import styles from './CustomerModal.style';

const CartModal = (props) => {
  const {
    visible,
    setVisible,
    fields,
    action,
    children,
    title,
    isLoading,
    isReady,
    setIsModalLoading,
  } = props;

  const renderField = (item, index) => {
    const { defaultValue, register, name, label, validation, fieldError } = item;

    return (
      <Flex key={index} sx={styles.fieldContainer}>
        <Text variant="forms.label" sx={styles.label}>
          {label}
        </Text>
        <Input
          sx={styles.input}
          placeholder={label}
          defaultValue={defaultValue}
          variant="forms.input"
          {...register(name, validation)}
        />
        {fieldError && <Text variant="error">{fieldError.message}</Text>}
      </Flex>
    );
  };

  if (visible && !isLoading) {
    return (
      <Box variant="layout.modalBackground" sx={styles.container}>
        <Card variant="modalCard" sx={styles.modal}>
          <IconButton onClick={() => setVisible(false)} sx={styles.closeButton}>
            <IoIosClose size={48} />
          </IconButton>
          <Flex sx={styles.contentContainer}>
            <Text variant="modalTitle">{title}</Text>
            {children && children}
            {fields.map((item, index) => renderField(item, index))}
            <Button
              disabled={!isReady}
              variant="primary"
              sx={isReady ? styles.button : styles.disabledButton}
              onClick={action}
            >
              {STRINGS.settings.saveText}
            </Button>
          </Flex>
        </Card>
      </Box>
    );
  }
  if (visible && isLoading) {
    return (
      <Box variant="layout.modalBackground" sx={styles.container}>
        <Card variant="modalCard" sx={styles.modal}>
          <IconButton
            onClick={() => {
              setIsModalLoading(false);
              setVisible(false);
            }}
            sx={styles.closeButton}
          >
            <IoIosClose size={48} />
          </IconButton>
          <Box sx={styles.spinner}>
            <Spinner size={60} />
          </Box>
        </Card>
      </Box>
    );
  }
  return null;
};

export default CartModal;
