import { IoIosClose, IoLogoUsd } from 'react-icons/io';
import { Box, Card, Divider, Flex, IconButton, Text } from 'theme-ui';

import { STRINGS, VISIT_STATUSES } from '../../constants';
import colorSwitch from '../../utils/visitColorSwitch';
import styles from './CalendarLegendModal.style';

const CalendarLegendModal = (props) => {
  const { visible, setVisible } = props;

  const renderRow = (status) => {
    return (
      <>
        <Flex sx={styles.row}>
          <Box sx={{ ...styles.colorBox, ...{ bg: colorSwitch(status) } }} />
          <Text sx={styles.rowText}>{STRINGS.VISIT_STATUS.WORKSHOP[status]}</Text>
        </Flex>
      </>
    );
  };

  const renderPaymentRow = () => {
    return (
      <>
        <Flex sx={styles.row}>
          <IoLogoUsd size="30px" />
          <Text sx={styles.rowText}>{STRINGS.VISIT_PAID_STATUS.COMPLETED}</Text>
        </Flex>
      </>
    );
  };

  if (visible) {
    return (
      <Box variant="layout.modalBackground" sx={styles.container}>
        <Card variant="modalCard" sx={styles.modal}>
          <IconButton onClick={() => setVisible(false)} sx={styles.closeButton}>
            <IoIosClose size={32} />
          </IconButton>
          <Text sx={styles.title}>{STRINGS.calendar.legend}</Text>
          {renderRow(VISIT_STATUSES.NEW)}
          {renderRow(VISIT_STATUSES.CANCELLED)}
          {renderRow(VISIT_STATUSES.REJECTED)}
          {renderRow(VISIT_STATUSES.PROPOSED_TIME)}
          {renderRow(VISIT_STATUSES.ASKED_NEW_TIME)}
          {renderRow(VISIT_STATUSES.PROPOSED_NEW_TIME)}
          <Divider sx={styles.divider} />
          {renderRow(VISIT_STATUSES.ACCEPTED)}
          {renderRow(VISIT_STATUSES.APPRAISAL)}
          {renderRow(VISIT_STATUSES.SERVICE_CONFIRMATION_ASKED)}
          {renderRow(VISIT_STATUSES.SERVICE_ACCEPTED)}
          {renderRow(VISIT_STATUSES.SERVICE_REJECTED)}
          {renderRow(VISIT_STATUSES.READY)}
          {renderRow(VISIT_STATUSES.FINISHED)}
          {renderRow(VISIT_STATUSES.DELETED)}
          <Divider sx={styles.divider} />
          {renderPaymentRow()}
        </Card>
      </Box>
    );
  }
  return null;
};

export default CalendarLegendModal;
