import { IoIosPaper } from 'react-icons/io';
import { AspectRatio, Box, Button, Divider, Flex, Image, Text } from 'theme-ui';

import { FUEL_TYPES, STRINGS } from '../../../constants';
import { getDayFromDate, getTimeFromDate } from '../../../utils/formatHours';
import CopyToClipboardButton from '../../others/CopyToClipboardButton';
import { Link } from '../../others/Link';
import MenuRowElement from '../../others/MenuRowElement';
import styles from './CarDetailCard.style';

const CarDetailCard = (props) => {
  const { item, visitId, createdAt, ownershipId, startAction, deleteCarHandler, historyNavigator } =
    props;

  const {
    id,
    make,
    model,
    variant,
    version,
    vin,
    productionYear,
    fuel,
    engineCapacity,
    enginePowerKw,
    curbWeight,
    firstRegistrationDate,
    color,
    registrationNumber,
    mileageEntry,
    image,
  } = item ?? {};

  const renderImage = () => {
    if (image && image.url) {
      return (
        <Box sx={styles.imageContainer}>
          <Box sx={styles.bgImageContainer}>
            <AspectRatio ratio={8 / 5} sx={styles.imageInnerContainer}>
              <Image sx={styles.image} src={image.url} />
            </AspectRatio>
          </Box>
        </Box>
      );
    }
    return (
      <Box sx={styles.imageContainer}>
        <Box sx={styles.bgImageContainer}>
          <AspectRatio ratio={8 / 5} sx={styles.missingPhotoContainer}>
            <Text sx={styles.missingPhoto}>{STRINGS.car.missingPhoto}</Text>
          </AspectRatio>
        </Box>
      </Box>
    );
  };

  const renderHeader = () => {
    const makeModel = `${make} ${model}`;
    return (
      <Flex sx={styles.headerContainer}>
        {renderImage()}
        <Flex sx={styles.headerInfo}>
          <Text sx={styles.makeModel}>{makeModel}</Text>
          <Divider />
        </Flex>
      </Flex>
    );
  };

  const renderRow = (label, value, optional) => {
    if (value) {
      const renderValue = optional ? `${value} ${optional}` : value;
      return (
        <Flex sx={styles.textRow}>
          <Text sx={styles.label}>{label}</Text>
          <Flex sx={styles.rowCopyContainer}>
            <Text sx={styles.value}>{renderValue}</Text>
            <CopyToClipboardButton text={renderValue} />
          </Flex>
        </Flex>
      );
    }
    return <></>;
  };

  const renderDate = () => {
    const convertedDate = getDayFromDate(new Date(createdAt));
    const time = getTimeFromDate(new Date(createdAt));
    const { date, month, year } = convertedDate;
    return (
      <Flex sx={styles.dateContainer}>
        <Text sx={styles.dateText}>{`${date} ${month} ${year}  ${time}`}</Text>
      </Flex>
    );
  };

  const renderInfo = () => {
    return (
      <Flex sx={styles.infoContainer}>
        {renderRow(STRINGS.car.make, make)}
        {renderRow(STRINGS.car.model, model)}
        {renderRow(STRINGS.car.variant, variant)}
        {renderRow(STRINGS.car.version, version)}
        {renderRow(STRINGS.car.vin, vin)}
        {mileageEntry &&
          renderRow(STRINGS.car.mileage, mileageEntry.mileage, STRINGS.car.kilometers)}
        {renderRow(STRINGS.car.registrationNumber, registrationNumber)}
        {renderRow(STRINGS.car.productionYear, productionYear)}
        {renderRow(STRINGS.car.fuel, FUEL_TYPES[fuel])}
        {renderRow(STRINGS.car.engineCapacity, engineCapacity)}
        {renderRow(STRINGS.car.enginePowerKw, enginePowerKw, STRINGS.car.powerKw)}
        {renderRow(STRINGS.car.curbWeight, curbWeight, STRINGS.car.kilograms)}
        {renderRow(STRINGS.car.firstRegistrationDate, firstRegistrationDate)}
        {renderRow(STRINGS.car.color, color)}
      </Flex>
    );
  };

  const visitActionContainer = () => {
    if (visitId) {
      const url = `/visits/${make}/${model}/${visitId}`;
      return (
        <Flex sx={styles.startVisitContainer}>
          <Text sx={styles.existingVisitText}>{STRINGS.visit.visitAlreadyExists}</Text>
          <Link
            key={url}
            path={url}
            href={url}
            variant="buttons.primary"
            sx={styles.link}
            target="_blank"
          >
            {STRINGS.visit.openVisitButton}
          </Link>
        </Flex>
      );
    }
    return (
      <Flex sx={styles.startVisitContainer}>
        <Button onClick={startAction} sx={styles.startButton}>
          {STRINGS.visit.beginVisit}
        </Button>
      </Flex>
    );
  };

  const renderFooter = () => {
    return (
      <Flex sx={styles.footerContainer}>
        <Flex sx={styles.footerContainer.container}>
          <Divider />
          <Flex sx={styles.footerContainer.inner}>
            {visitActionContainer()}
            <Button onClick={() => deleteCarHandler(ownershipId)} sx={styles.deleteButton}>
              {STRINGS.common.remove}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const renderHistoryButton = () => {
    return (
      <MenuRowElement
        action={() => historyNavigator(id, make, model)}
        text={STRINGS.car.menuHistory}
      >
        <IoIosPaper size={24} />
      </MenuRowElement>
    );
  };

  return (
    <Flex sx={styles.container}>
      {renderHeader()}
      {renderDate()}
      {renderInfo()}
      {renderFooter()}
      {renderHistoryButton()}
    </Flex>
  );
};

export default CarDetailCard;
