export default {
  container: {
    px: [2, null, 4, 6, 2],
  },
  cardContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
  },
  infoContainer: {},
  paymentStatusContainer: {
    flexDirection: 'column',
    flex: 1,
    mx: '1rem',
  },
  paymentStatusText: {
    textTransform: 'uppercase',
    fontSize: ['14px', null, null, '16px'],
    fontWeight: 'bold',
  },
  paymentCompletedResultsText: {
    fontSize: ['14px', null, null, '16px'],
  },
  actionContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    mx: '1rem',
  },
  actionContainerAdditional: {
    flexDirection: 'column',
    mx: '1rem',
    // flex: 1,
  },
  serviceCartContainer: {},
  actionButtonContainer: {
    alignItems: 'center',
  },
  actionButton: {},
  disabledActionButton: {
    bg: 'button_bg_pressed',
  },
  actionClientButtonText: {},
  actionWorkshopButtonText: {},
  checkButton: {
    color: 'text',
  },
  checkText: {
    mr: '5px',
    fontSize: ['12px', null, null, '14px'],
  },

  paymentCompletedResultsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  paymentCompletedResultsLabel: {},
  serviceScopeContainer: {
    flexDirection: 'column',
  },
  serviceScopeText: {
    lineHeight: 1.2,
    textAlign: 'center',
    fontSize: ['14px', null, null, '16px'],
  },
  serviceScopeSmallText: {
    mt: '0.25rem',
    textAlign: 'center',
    fontSize: ['12px', null, null, '14px'],
  },
};
