export default {
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  headerContainer: {
    flex: 0.5,
    py: 2,
    flexDirection: 'column',
  },
  headerInfo: {
    pt: '1rem',
    flexDirection: 'column',
  },
  imageContainer: {
    px: [0, null, 4, 6, 0, 4, 6],
    borderRadius: '10px',
  },
  bgImageContainer: {
    boxShadow: (t) => `0 3px 6px ${t.colors.shadow}`,
    borderRadius: '10px',
  },
  imageInnerContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    borderRadius: '10px',
  },
  image: {
    display: 'flex',
    flex: 1,
    objectFit: 'cover',
    borderRadius: '10px',
  },
  missingPhotoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    bg: 'content_bg',
    p: 4,
  },
  missingPhoto: {
    fontSize: '25px',
    color: 'text',
  },
  makeModel: {
    fontSize: ['16px', '18px'],
    fontWeight: 'bold',
    flexWrap: 'wrap',
  },
  infoContainer: {
    flexDirection: 'column',
    flex: 1,
  },
  textRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  rowCopyContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    fontSize: ['12px', '14px'],
  },
  value: {
    fontSize: ['14px', null, '16px'],
  },

  placeholderImage: {
    borderWidth: '3px',
  },
  imagePlaceholderText: {
    textAlign: 'center',
  },
  footerContainer: {
    flex: 1,
    // flexDirection: 'column',
    alignItems: 'flex-start',
    container: {
      flex: 1,
      flexDirection: 'column',
    },
    inner: {
      mt: '1rem',
      flex: 1,
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
  },
  startVisitContainer: {
    flexDirection: 'column',
  },
  dateContainer: {},
  dateText: {
    color: 'text_secondary',
    fontSize: '16px',
  },
  deleteButton: {},
  startButton: {},
  existingVisitText: {},
  link: {
    textDecoration: 'none',
  },
};
