/* eslint-disable no-unused-vars */
import React from 'react';
import { Flex, Text } from 'theme-ui';

import { FUEL_TYPES, STRINGS } from '../../../constants';
import COLORS from '../../../theme';
import { getDayFromDate, getTimeFromDate } from '../../../utils/formatHours';
import styles from './CarPreviewWorkshopCard.style';

const CarPreviewWorkshopCard = (props) => {
  const { item, action, active } = props;
  const { car, createdAt } = item ?? {};
  const {
    make,
    model,
    vin,
    productionYear,
    fuel,
    color,
    registrationNumber,
    mileageEntry,
    ownershipId,
  } = car ?? {};

  const renderRow = (label, value, optional) => {
    if (value) {
      const renderValue = optional ? `${value} ${optional}` : value;
      return (
        <Flex sx={styles.textRow}>
          <Text sx={active ? styles.activeLabel : styles.label}>{label}</Text>
          <Text sx={active ? styles.activeValue : styles.value}>{renderValue}</Text>
        </Flex>
      );
    }
    return <></>;
  };

  const renderDate = () => {
    const convertedDate = getDayFromDate(new Date(createdAt));
    const time = getTimeFromDate(new Date(createdAt));
    const { date, month, year } = convertedDate;
    return (
      <Flex sx={styles.dateContainer}>
        <Text sx={styles.dateText}>{`${date} ${month} ${year}  ${time}`}</Text>
      </Flex>
    );
  };

  const renderContent = () => {
    return (
      <Flex onClick={() => action(item)} sx={styles.contentContainer}>
        <Flex sx={styles.rows}>
          {renderRow(STRINGS.car.registrationNumber, registrationNumber)}
          {mileageEntry &&
            renderRow(STRINGS.car.mileage, mileageEntry.mileage, STRINGS.car.kilometers)}
          {renderRow(STRINGS.car.productionYear, productionYear)}
          {renderRow(STRINGS.car.vin, vin)}
          {renderRow(STRINGS.car.fuel, FUEL_TYPES[fuel])}
          {renderRow(STRINGS.car.color, color)}
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex sx={active ? styles.activeContainer : styles.container}>
      <Flex sx={styles.makeModelContainer}>
        <Text sx={styles.makeModel}>{make}</Text>
        <Text sx={styles.makeModel}>{model}</Text>
      </Flex>
      {renderDate()}
      <Flex sx={styles.separatorContainer} />
      {renderContent()}
    </Flex>
  );
};

export default CarPreviewWorkshopCard;
