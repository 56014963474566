import { Flex, Text } from 'theme-ui';

import { STRINGS } from '../../../constants';
import styles from './RejectionReasonCard.style';

const RejectionReasonCard = (props) => {
  const { rejectionReason, cardStyle } = props;

  return (
    <Flex sx={{ ...styles.container, ...cardStyle }}>
      <Text sx={styles.label}>{STRINGS.visit.rejectionReason}</Text>
      <Flex sx={styles.textContainer}>
        <Text numberOfLines={4} ellipsizeMode="tail" style={styles.rejectionReasonText}>
          {rejectionReason}
        </Text>
      </Flex>
    </Flex>
  );
};

export default RejectionReasonCard;
