import PropTypes from 'prop-types';
import { Box, Button, Card, Divider, Flex, Text } from 'theme-ui';

import { STRINGS } from '../../../../constants';
import styles from './VisitRegularServiceCard.style';

const VisitRegularServiceCard = (props) => {
  const { typ, services, action, editVisitServicesPriceListForTypeNavigator, cardStyle } = props;

  const localeString = 'pl';
  const localeCurrency = 'PLN';

  const renderHeader = () => {
    let typeText;
    if (typ in STRINGS.WORKSHOP_TYPES) {
      typeText = STRINGS.WORKSHOP_TYPES[typ];
    } else {
      typeText = STRINGS.WORKSHOP_TYPE_COMMON.COMMON;
    }
    return (
      <Flex sx={styles.typeHeaderContainer}>
        <Text variant="title" sx={styles.headerTypeText}>
          {typeText}
        </Text>
        <Flex sx={{ flexDirection: 'column' }}>
          {STRINGS.REGULAR_SERVICES[typ] && (
            <Button
              variant="miniHeader"
              sx={styles.editButton}
              onClick={() => editVisitServicesPriceListForTypeNavigator(typ)}
            >
              <Text sx={styles.editButtonText}>{STRINGS.workshop.edit}</Text>
            </Button>
          )}
          <Text sx={styles.headerText}>{STRINGS.workshop.priceWithVat}</Text>
        </Flex>
      </Flex>
    );
  };

  const renderPrice = (price, priceWithFee) => {
    const formattedPrice = new Intl.NumberFormat(localeString, {
      style: 'currency',
      currency: localeCurrency,
    }).format(price);

    const formattedPriceWithFee = new Intl.NumberFormat(localeString, {
      style: 'currency',
      currency: localeCurrency,
    }).format(priceWithFee);

    return (
      <Flex sx={styles.itemPriceContainer}>
        <Text sx={styles.itemPriceForWorkshopText}>{formattedPrice}</Text>
        <Flex sx={styles.clientPriceContainer}>
          <Text sx={styles.itemPriceForClientDescriptionText}>
            {STRINGS.visit.clientPriceDescription}
          </Text>
          <Text sx={styles.itemPriceForClientText}>{formattedPriceWithFee}</Text>
        </Flex>
      </Flex>
    );
  };

  const renderItem = (id, serviceValue, name, price, priceWithFee) => {
    const preloadData = {
      id,
      serviceValue,
      name,
      priceEach: price,
      priceEachWithFee: priceWithFee,
      quantity: 1,
    };

    return (
      <Box key={id}>
        <Divider sx={styles.itemDivider} />
        <Flex key={serviceValue} sx={styles.itemRowContainer}>
          <Text sx={styles.itemNameText}>{name}</Text>
          <Flex sx={styles.priceAndButtonContainer}>
            {renderPrice(price, priceWithFee)}
            {action && (
              <Button
                variant="mini"
                onClick={() => action(false, false, true, preloadData)}
                sx={styles.itemOrderButton}
              >
                {STRINGS.visit.selectItem}
              </Button>
            )}
          </Flex>
        </Flex>
      </Box>
    );
  };

  const renderServices = () => {
    if (services?.length > 0) {
      return Object.entries(STRINGS.REGULAR_SERVICES[typ]).map(([serviceValue, label]) => {
        const filtered = services.filter((elem) => elem.name === serviceValue);
        if (filtered?.length > 0) {
          const { id, price, priceWithFee } = filtered[0];
          return renderItem(id, serviceValue, label, price, priceWithFee);
        }
        return null;
      });
    }
    return (
      <Flex sx={styles.noItemsContainer}>
        <Text sx={styles.noItemsText}>{STRINGS.visit.noItemsToChoose}</Text>
      </Flex>
    );
  };

  return (
    <Card variant="priceListCard" sx={{ ...styles.container, ...cardStyle }}>
      {renderHeader()}
      {renderServices()}
    </Card>
  );
};

VisitRegularServiceCard.propTypes = {
  typ: PropTypes.string.isRequired,
  services: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  action: PropTypes.func,
  editVisitServicesPriceListForTypeNavigator: PropTypes.func,
  cardStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

VisitRegularServiceCard.defaultProps = {
  editVisitServicesPriceListForTypeNavigator: undefined,
  cardStyle: {},
  action: undefined,
};

export default VisitRegularServiceCard;
