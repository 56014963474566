import { VISIT_STATUSES, WORKSHOP_VISIT_COLORS } from '../constants';

const colorSwitch = (status, isCalendarVisit = false) => {
  if (isCalendarVisit && status === VISIT_STATUSES.FINISHED) {
    return 'white';
  }
  return WORKSHOP_VISIT_COLORS[status];
};

export default colorSwitch;
