export default {
  mainContainer: {
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    lineHeight: 1.3,
    my: '1rem',
  },
  labelContainer: {
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    mb: '1rem',
  },
  container: {},
  label: {
    textAlign: 'center',
  },

  description: {
    textAlign: 'center',
    fontSize: '12px',
  },
  askedStatusContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  askedStatusText: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  confirmedStatusContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  confirmedStatusText: {
    fontWeight: 'bold',
  },
  descriptionAdditionalService: {},
  additionalServiceContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  additionalServiceButton: {
    width: null,
  },
  additionalServiceButtonText: {},
  rejectedStatusContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  rejectedStatusText: {
    fontWeight: 'bold',
  },
  askConfirmationButtonContainer: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
  },
  askButton: {
    width: null,
    alignSelf: 'center',
    color: 'text',
  },
  disabledAskButton: {
    width: null,
    alignSelf: 'center',
  },
  askButtonText: {},
  confirmationButtonContainer: {},
  confirmButton: {
    width: null,
    alignItems: 'center',
  },
  confirmButtonText: {},
  rejectButton: {
    width: null,
  },
  rejectButtonText: {},
  termsTextContainer: {},
  termsInfo: {
    textAlign: 'center',
  },
  termsButton: {},
  emptyCartAlert: {
    mt: '1rem',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
};
