export default {
  P: 'Benzyna',
  D: 'Diesel',
  M: 'Mieszanka benzyny',
  LPG: 'LPG',
  CNG: 'CNG',
  H: 'Wodór',
  LNG: 'LNG',
  BD: 'Bio Diesel',
  E85: 'E85',
  EE: 'Elektryczny',
  999: 'Inny',
  'P EE': 'Hybrydowy',
};
