export default {
  container: {
    flexDirection: 'row',
    mx: '5px',
    pl: ['10px', null, null, '20px'],
    boxShadow: (t) => `0 3px 6px ${t.colors.shadow}`,
    '&:hover': {
      boxShadow: (t) => `0 4px 8px ${t.colors.shadow}`,
    },
    position: 'relative',
  },
  visitContent: {
    bg: 'content_bg',
    flex: 1,
    borderRadius: '10px',
    flexDirection: 'column',
    p: '0.5rem',
    textAlign: 'center',
    '&:hover': {
      bg: 'content_bg_hover',
    },
  },
  visitStatusHeader: {
    flexDirection: 'column',
    textAlign: 'center',
    mt: '5px',
    fontWeight: 'bold',
    fontSize: ['14px', null, null, '16px'],
  },
  messageAreaContainer: {
    mb: '5px',
  },
  messageValue: {
    fontSize: ['12px', null, null, '16px'],
    textAlign: 'left',
    maxHeight: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  contentContainer: {
    flexDirection: 'column',
    display: 'flex',
    flex: 1,
  },
  carAndWorkshopContainer: {
    flexDirection: 'column',
    display: 'flex',
    flex: 1,
    mx: '0.5rem',
  },
  carInfoContainer: {
    flexDirection: 'column',
    display: 'flex',
    flex: 1,
  },
  makeModel: {
    textAlign: 'left',
    color: 'text',
    fontWeight: 'bold',
    fontSize: ['14px', null, null, '16px'],
  },
  carInfoRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  carInfo: {
    color: 'text',
    fontSize: ['12px', null, null, '14px'],
  },
  link: {
    textDecoration: 'none',
  },
  paymentContainer: {
    position: 'absolute',
    right: ['0.25rem', '0.5rem', null, null, '1rem'],
    top: '0.5rem',
    color: 'text',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentText: {
    fontSize: ['12px', null, null, '14px'],
    display: ['none', null, null, null, 'flex'],
    mr: '0.25rem',
  },
};
