export default {
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  headerContainer: {
    my: '1em',
  },
  grid: {
    px: [2, null, 4, 6, 2],
    gridTemplateColumns: ['repeat(1, 1fr)', null, null, null, 'repeat(2, 1fr)'],
  },
  headerText: {
    textAlign: 'center',
    fontWeight: 'bold',
    flex: 1,
    fontSize: 4,
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  imageContainer: {
    px: [2, null, 4, 6, 2],
    borderRadius: '10px',
  },
  bgImageContainer: {
    boxShadow: (t) => `0 3px 6px ${t.colors.shadow}`,
    borderRadius: '10px',
  },
  imageInnerContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    borderRadius: '10px',
  },
  image: {
    display: 'flex',
    flex: 1,
    objectFit: 'cover',
    borderRadius: '10px',
  },
  missingPhotoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    bg: 'content_bg',
    p: 4,
  },
  missingPhoto: {
    fontSize: '25px',
    color: 'text',
  },

  carInfoContainer: {
    flexDirection: 'column',
    flex: 1,
    mb: '1rem',
    px: [null, null, null, null, '0.5rem'],
  },
  makeModelContainer: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flex: 1,
    alignItems: 'center',
  },
  makeModel: {
    fontSize: ['16px', '18px'],
    fontWeight: 'bold',
    flexWrap: 'wrap',
  },
  infoContainer: {
    flexDirection: 'column',
    flex: 1,
  },

  textRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  rowCopyContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    fontSize: ['14px', null, '16px'],
  },
  value: {
    fontSize: ['14px', null, '16px'],
  },
  popupMenuContainer: {
    position: 'absolute',
  },
};
