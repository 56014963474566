import PropTypes from 'prop-types';
import { Box, Card, Flex, Heading, Text, useColorMode } from 'theme-ui';

import { STRINGS } from '../../constants';
import { getOpeningHours } from '../../utils/formatHours';
import styles from './WorkshopOpeningHours.style';

export default function WorkshopOpeningHours(props) {
  // eslint-disable-next-line no-unused-vars
  const [colorMode, setColorMode] = useColorMode();
  const { hours, currentType } = props;
  const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = hours ?? {};

  const hoursStrings = {
    noInfo: STRINGS.workshop.noInfoText,
    closed: STRINGS.workshop.closed,
    opened24h: STRINGS.workshop.open24h,
  };

  const convertHoursByType = (data) => {
    const dataByType = data?.filter((elem) =>
      elem?.workshopTypesForDay?.find((typ) => typ.name === currentType),
    );

    if (dataByType?.length < 1) {
      const commonHours = data?.filter((elem) => elem.workshopTypesForDay.length < 1);
      if (commonHours?.length > 0) {
        return commonHours;
      }
      return null;
    }
    return dataByType;
  };

  const renderDay = (day, data) => {
    const convertedHours = convertHoursByType(data);
    const openingHours = getOpeningHours(convertedHours, hoursStrings);
    return (
      <Flex sx={styles.container.day}>
        <Text sx={styles.container.dayName}>{day}</Text>
        <Box sx={styles.container.hour}>
          {openingHours?.map((elem) => (
            <Text key={elem} sx={styles.container.openingHours}>
              {elem}
            </Text>
          ))}
        </Box>
      </Flex>
    );
  };

  return (
    <Card variant="workshopInfoCard" sx={colorMode === 'light' ? styles.card : styles.cardDark}>
      <Heading as="h2">{STRINGS.workshop.openingHoursLabel}</Heading>
      <Flex sx={styles.container}>
        {renderDay(STRINGS.WEEK_DAYS[0], monday)}
        {renderDay(STRINGS.WEEK_DAYS[1], tuesday)}
        {renderDay(STRINGS.WEEK_DAYS[2], wednesday)}
        {renderDay(STRINGS.WEEK_DAYS[3], thursday)}
        {renderDay(STRINGS.WEEK_DAYS[4], friday)}
        {renderDay(STRINGS.WEEK_DAYS[5], saturday)}
        {renderDay(STRINGS.WEEK_DAYS[6], sunday)}
      </Flex>
    </Card>
  );
}

WorkshopOpeningHours.propTypes = {
  hours: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  currentType: PropTypes.string,
};

WorkshopOpeningHours.defaultProps = {
  currentType: null,
};
