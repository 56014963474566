export default {
  container: {
    bg: 'content_bg',
    color: 'text',
    borderRadius: '10px',
    boxShadow: (t) => `0 2px 4px ${t.colors.shadow}`,
    flexDirection: 'column',
    cursor: 'pointer',
    transition: '0.4s',
    m: '0.5rem',
    p: '0.25rem',
    '&:hover': {
      bg: 'content_bg_hover',
      boxShadow: (t) => `0 4px 8px ${t.colors.shadow}`,
    },
  },
  activeContainer: {
    bg: 'content_bg_selected',
    color: 'text',
    borderRadius: '10px',
    boxShadow: (t) => `inset 0 -4px 8px ${t.colors.shadow}`,
    flexDirection: 'column',
    cursor: 'pointer',
    transition: '0.4s',
    m: '0.5rem',
    p: '0.25rem',
    '&:hover': {
      bg: 'content_bg_hover',
      boxShadow: (t) => `0 4px 8px ${t.colors.shadow}`,
    },
  },
  error: {
    marginBottom: '10px',
    textAlign: 'center',
  },
  separatorContainer: {
    mx: '10px',
  },
  contentContainer: {
    alignItems: 'stretch',
    mt: '5px',
    flexDirection: 'column',
  },
  rows: {
    mx: '10px',
    flexDirection: 'column',
  },
  textRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  detailsButtonContainer: {
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
  makeModelContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: '10px',
    flex: 1,
  },
  makeModel: {
    textAlign: 'center',
    mx: '2px',
  },
  dateContainer: {
    mx: '10px',
  },
  dateText: {
    color: 'text_secondary',
    fontSize: '14px',
  },
  label: {
    color: 'text_secondary',
  },
  activeLabel: {
    color: 'text',
  },
  value: {
    color: 'text_secondary',
  },
  activeValue: {
    color: 'text',
  },
};
