import PropTypes from 'prop-types';
import { IoIosRefresh } from 'react-icons/io';
import { Button, Flex, Text } from 'theme-ui';

import { STRINGS } from '../../constants';
import DimLoadingIndicator from '../others/DimLoadingIndicator';
import styles from './RefreshButton.style';

const RefreshButton = (props) => {
  const { onRefresh, isRefreshing } = props;

  return (
    <>
      {isRefreshing && <DimLoadingIndicator />}
      <Flex sx={styles.buttonContainer}>
        <Button variant="refresh" sx={styles.button} onClick={onRefresh}>
          <Flex sx={styles.container}>
            <Text sx={styles.buttonText}>{STRINGS.settings.refreshText}</Text>
            <Flex
              sx={{
                ...styles.iconContainer,
                animationPlayState: !isRefreshing && 'paused',
              }}
            >
              <IoIosRefresh height={styles.iconSizes} />
            </Flex>
          </Flex>
        </Button>
      </Flex>
    </>
  );
};

RefreshButton.propTypes = {
  onRefresh: PropTypes.func.isRequired,
  isRefreshing: PropTypes.bool.isRequired,
};

export default RefreshButton;
