export default {
  NEW: 'NEW',
  CANCELLED: 'CANCELLED',
  REJECTED: 'REJECTED',
  PROPOSED_TIME: 'PROPOSED_TIME',
  ASKED_NEW_TIME: 'ASKED_NEW_TIME',
  PROPOSED_NEW_TIME: 'PROPOSED_NEW_TIME',
  ACCEPTED: 'ACCEPTED',
  APPRAISAL: 'APPRAISAL',
  SERVICE_CONFIRMATION_ASKED: 'SERVICE_CONFIRMATION_ASKED',
  SERVICE_ACCEPTED: 'SERVICE_ACCEPTED',
  SERVICE_REJECTED: 'SERVICE_REJECTED',
  READY: 'READY',
  FINISHED: 'FINISHED',
  DELETED: 'DELETED',
};
