import PropTypes from 'prop-types';
import { useState } from 'react';
import Sticky from 'react-stickynode';
import { Box } from 'theme-ui';

import Footer from './Footer';
import Header from './Header';
import styles from './Layout.style';
import Sidebar from './Sidebar';

const Layout = ({ isAuth, children }) => {
  const [isOpened, setOpened] = useState(false);

  const toggleDrawer = () => {
    setOpened((prev) => !prev);
  };

  return (
    <Box sx={styles.screen}>
      <Sticky innerZ={1001} top={0}>
        <Header isOpened={isOpened} toggleDrawer={toggleDrawer} isAuth={isAuth} />
      </Sticky>
      <Box variant="layout.main">
        <Sidebar isOpened={isOpened} isAuth={isAuth} />
        <Box sx={styles.pageContainer} className={isOpened ? 'opened' : 'closed'}>
          <Box sx={styles.pageInnerContainer} className={isOpened ? 'opened' : 'closed'}>
            {children}
          </Box>
        </Box>
      </Box>
      {!isAuth && <Footer />}
    </Box>
  );
};

Layout.propTypes = {
  isAuth: PropTypes.bool,
};

Layout.defaultProps = {
  isAuth: true,
};

export default Layout;
