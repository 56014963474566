export default {
  GREY_BG: '#222222',
  MEDIUM_DARK_GREY_BG: '#272727',
  DARK_GREY_BG: '#171717',
  LIGHT_GREY_BG: '#575757',
  LOADING_DIM_BG: 'rgba(0, 0, 0, 0.75)',
  SCANNING_BG: 'rgba(0, 0, 0, 0.25)',

  ACCENT: '#F4C714',
  ACCENT_DISABLED: 'rgba(244, 199, 20, 0.33)',
  LIGHT_BUTTON: '#D7D7D7',
  LIGHT_GREY: '#999999',

  LIGHT_TEXT: '#D7D7D7',
  PLACEHOLDER_TEXT: '#999999',
  PLACEHOLDER_BLACK_TEXT: '#444444',
  BLACK_TEXT: '#000000',
  WHITE_TEXT: '#FFFFFF',

  BLACK: '#000000',
  WHITE: '#FFFFFF',

  CONFIRM_COLOR: '#016FB9',
  ERROR_COLOR: '#F72C25',
  SUCCESS_COLOR: '#1C7C54',
  TERTIARY_COLOR: '#FB9101',
};
