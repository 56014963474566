/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import pl from 'date-fns/locale/pl';
import React, { forwardRef } from 'react';

import 'react-datepicker/dist/react-datepicker.css';

import { IoIosClose } from 'react-icons/io';
import { Box, Button, Card, Flex, IconButton, Input, Spinner, Text, Textarea } from 'theme-ui';

import { STRINGS as COMMON_STRINGS, FIELD_TYPES } from '../../constants';
import { STRINGS } from '../../constants/workshop';
import styles from './CartModal.style';

const CartModal = (props) => {
  const {
    visible,
    setVisible,
    fields,
    action,
    children,
    title,
    errors,
    isLoading,
    isReady,
    setIsModalLoading,
  } = props;

  const localeString = 'pl';
  const localeCurrency = 'PLN';

  const priceConverter = () => {
    const defaultFee = 0.06;
    const multiply = fields[1].value * fields[2].value * (1 + defaultFee);
    const finalPrice = multiply > 0 && typeof multiply === 'number' ? multiply : 0;

    return Number.isNaN(finalPrice) || Number.isNaN(parseFloat(finalPrice))
      ? COMMON_STRINGS.visit.invalidPrice
      : new Intl.NumberFormat(localeString, {
          style: 'currency',
          currency: localeCurrency,
        }).format(finalPrice);
  };

  const renderFinalPrice = () => {
    return (
      <Flex sx={styles.finalContainer}>
        <Text sx={styles.label}>{COMMON_STRINGS.visit.priceForClientFrom}</Text>
        <Flex sx={styles.finalPriceContainer}>
          <Text sx={styles.finalPriceText}>{priceConverter()}</Text>
        </Flex>
      </Flex>
    );
  };

  const renderField = (item) => {
    const { type, defaultValue, register, name, label, validation, fieldError, setValue } = item;
    return (
      <Flex key={name} sx={styles.fieldContainer}>
        <Text variant="forms.label" sx={styles.label}>
          {label}
        </Text>
        <Input
          sx={styles.input}
          placeholder={label}
          defaultValue={defaultValue}
          variant="forms.input"
          {...register(name, validation)}
        />
        {errors?.[name]?.type === 'pattern' && (
          <Text variant="error">{errors?.[name]?.message}</Text>
        )}
        {fieldError && <Text variant="error">{fieldError.message}</Text>}
      </Flex>
    );
  };

  if (visible && !isLoading) {
    return (
      <Box variant="layout.modalBackground" sx={styles.container}>
        <Card variant="modalCard" sx={styles.modal}>
          <IconButton onClick={() => setVisible(false)} sx={styles.closeButton}>
            <IoIosClose size={48} />
          </IconButton>
          <Flex sx={styles.contentContainer}>
            <Text variant="modalTitle">{title}</Text>
            {children && children}
            {fields.map((item) => renderField(item))}
            <Button
              disabled={!isReady}
              variant="primary"
              sx={isReady ? styles.button : styles.disabledButton}
              onClick={action}
            >
              {STRINGS.settings.saveText}
            </Button>
          </Flex>
          {renderFinalPrice()}
        </Card>
      </Box>
    );
  }
  if (visible && isLoading) {
    return (
      <Box variant="layout.modalBackground" sx={styles.container}>
        <Card variant="modalCard" sx={styles.modal}>
          <IconButton
            onClick={() => {
              setIsModalLoading(false);
              setVisible(false);
            }}
            sx={styles.closeButton}
          >
            <IoIosClose size={48} />
          </IconButton>
          <Box sx={styles.spinner}>
            <Spinner size={60} />
          </Box>
        </Card>
      </Box>
    );
  }
  return null;
};

export default CartModal;
