export default {
  container: {},
  itemHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerText: {
    color: 'text_secondary',
    fontSize: ['12px', null, '14px'],
  },
  itemRowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemNameText: {
    flex: 1,
    fontWeight: 'bold',
  },
  itemText: {
    fontWeight: 'bold',
  },
  itemClientDescriptionText: {
    color: 'heading',
    flex: 1,
  },
  itemClientText: {
    color: 'heading',
  },
  itemFooterContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    mt: '0.5rem',
  },
  quantityContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  quantityItemText: {
    ml: '5px',
    fontWeight: 'heading',
  },
  editContainer: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  deleteItemButton: {
    ml: '1rem',
  },
  deleteItemButtonText: {},
};
