export default {
  WORKSHOP_URL: 'https://meldmoto.pl/warsztat/',
  MANGOPAY_TERMS_URL: 'https://www.mangopay.com/terms/MANGOPAY_Terms-PL.pdf',
  IDENTITY_PROOF_TUTORIAL_URL: 'https://docs.mangopay.com/guide/guidelines-for-identity-documents',
  TERMS_WORKSHOP_URL: 'https://meldmoto.pl/regulamin-warsztat',
  TERMS_CLIENT_URL: 'https://meldmoto.pl/regulamin-klient',
  COOKIES_URL: 'https://meldmoto.pl/polityka-cookies',
  POLICY_WORKSHOP_URL: 'https://meldmoto.pl/polityka-prywatnosci-warsztat',
  POLICY_CLIENT_URL: 'https://meldmoto.pl/polityka-prywatnosci-klient',
};
