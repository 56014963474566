export default {
  copyContainer: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    bg: 'button_bg_pressed',
    borderRadius: '25px',
    p: '2px',
    m: '4px',
    '&:hover': {
      bg: 'button_bg',
    },
  },
  copiedInfoContainer: {
    px: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'button_bg_pressed',
    position: 'absolute',
    top: '-2rem',
    borderRadius: '5px',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '-10px',
      width: 0,
      height: 0,
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderTopWidth: '10px',
      borderTopColor: 'button_bg_pressed',
      borderTopStyle: 'solid',
    },
  },
  copyButton: {
    cursor: 'pointer',
    m: 0,
    opacity: 0.5,
    transition: '0.4s',
    '&:hover': {
      opacity: 1,
    },
  },
  close: {
    m: 0,
    p: 0,
  },
};
