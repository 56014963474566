/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import pl from 'date-fns/locale/pl';
import React, { forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { IoIosClose } from 'react-icons/io';
import {
  Box,
  Button,
  Card,
  Flex,
  IconButton,
  Input,
  Select,
  Text,
  Textarea,
  useColorMode,
} from 'theme-ui';

import { STRINGS as COMMON_STRINGS, FIELD_TYPES } from '../../constants';
import { STRINGS } from '../../constants/workshop';
import MaskedInput from '../form/MaskedInput';
import DimLoadingIndicator from '../others/DimLoadingIndicator';
import styles from './FormModal.style';

registerLocale('pl', pl);

const FormModal = (props) => {
  const {
    visible,
    setVisible,
    fields,
    title,
    actionText,
    action,
    children,
    isLoading,
    isReady,
    setIsModalLoading,
  } = props;

  const [colorMode, setColorMode] = useColorMode();

  // eslint-disable-next-line react/display-name
  const DateInput = forwardRef(({ value, onClick }, ref) => (
    <Button variant="primary" sx={styles.customDateButton} onClick={onClick} ref={ref}>
      {value || STRINGS.visitDetail.chooseDate}
    </Button>
  ));

  const renderField = (item) => {
    const {
      type,
      value,
      register,
      name,
      label,
      validation,
      fieldError,
      setValue,
      defaultValue,
      placeholder,
      pickerValues,
    } = item;

    if (type === FIELD_TYPES.INPUT_TEXT) {
      return (
        <Flex key={name} sx={styles.fieldContainer}>
          <Text variant="forms.label" sx={styles.label}>
            {label}
          </Text>
          <Input
            sx={colorMode === 'light' ? styles.input : styles.inputDark}
            defaultValue={defaultValue}
            placeholder={placeholder}
            variant="forms.input"
            {...register(name, validation)}
          />
          {fieldError && <Text variant="error">{fieldError.message}</Text>}
        </Flex>
      );
    }
    if (type === FIELD_TYPES.MASKED_INPUT_TEXT) {
      return (
        <Flex key={name} sx={styles.fieldContainer}>
          <Text variant="forms.label" sx={styles.label}>
            {label}
          </Text>
          <MaskedInput
            inputStyle={colorMode === 'light' ? styles.input : styles.inputDark}
            mask={item?.mask}
            register={register}
            name={name}
            validation={validation}
            defaultValue={defaultValue}
            placeholder={placeholder}
          />
          {fieldError && <Text variant="error">{fieldError.message}</Text>}
        </Flex>
      );
    }
    if (type === FIELD_TYPES.INPUT_TEXT_AREA) {
      return (
        <Flex key={name} sx={styles.fieldContainer}>
          <Text variant="forms.label" sx={styles.label}>
            {label}
          </Text>
          <Textarea
            sx={colorMode === 'light' ? styles.textarea : styles.textareaDark}
            variant="forms.textarea"
            {...register(name, validation)}
            defaultValue={defaultValue}
            placeholder={placeholder}
          />
          {fieldError && <Text variant="error">{fieldError.message}</Text>}
        </Flex>
      );
    }
    if (type === FIELD_TYPES.PICKER) {
      return (
        <Flex key={name} sx={styles.fieldContainer}>
          <Text variant="forms.label" sx={styles.label}>
            {label}
          </Text>
          <Select
            defaultValue={COMMON_STRINGS.common.selectItem}
            onChange={(e) => setValue(e.target.value)}
          >
            <option value={null}>{COMMON_STRINGS.common.selectItem}</option>
            {pickerValues.map((option) => (
              <option key={value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </Flex>
      );
    }
    if (type === FIELD_TYPES.DATE_PICKER) {
      return (
        <Flex sx={styles.fieldContainer}>
          <Text variant="forms.label" sx={styles.label}>
            {label}
          </Text>
          <Flex sx={styles.pickerContainer}>
            <DatePicker
              locale="pl"
              selected={value}
              onChange={(date) => setValue(date)}
              showTimeSelect
              timeIntervals={15}
              customInput={<DateInput />}
              dateFormat="EEEE, d MMMM yyyy, p"
            />
          </Flex>
        </Flex>
      );
    }
    return null;
  };

  if (visible) {
    return (
      <Box variant="layout.modalBackground" sx={styles.container}>
        <Card variant="modalCard" sx={styles.modal}>
          {isLoading && <DimLoadingIndicator />}
          <IconButton onClick={() => setVisible(false)} sx={styles.closeButton}>
            <IoIosClose size={48} />
          </IconButton>
          <Flex sx={styles.contentContainer}>
            <Text variant="modalTitle" sx={styles.modalTitle}>
              {title}
            </Text>
            {children && children}
            {fields.map((item) => renderField(item))}
            <Button
              disabled={!isReady}
              variant="primary"
              sx={isReady ? styles.button : styles.disabledButton}
              onClick={action}
            >
              {actionText}
            </Button>
          </Flex>
        </Card>
      </Box>
    );
  }
  return null;
};

export default FormModal;
