import PropTypes from 'prop-types';
import { Button, Card, Flex, Text } from 'theme-ui';

import { STRINGS } from '../../../../constants';
import styles from './VisitEditCartCard.style';

const VisitEditCartCard = (props) => {
  const { cartItem, preloadDataAndShowModal, deleteServiceCartItemHandler } = props;
  const { id, name, priceEach, priceEachWithFee, quantity, isAccepted } = cartItem;
  const localeString = 'pl';
  const localeCurrency = 'PLN';

  const formattedPrice = new Intl.NumberFormat(localeString, {
    style: 'currency',
    currency: localeCurrency,
  }).format(priceEach);
  const formattedPriceEachWithFee = new Intl.NumberFormat(localeString, {
    style: 'currency',
    currency: localeCurrency,
  }).format(priceEachWithFee);

  const renderHeader = () => {
    return (
      <Flex sx={styles.itemHeaderContainer}>
        <Text sx={styles.headerText}>{STRINGS.visit.itemService}</Text>
        <Text sx={styles.headerText}>{STRINGS.visit.pricePerItemLabel}</Text>
      </Flex>
    );
  };

  const renderFooter = () => {
    return (
      <Flex sx={styles.itemFooterContainer}>
        <Flex sx={styles.quantityContainer}>
          <Text sx={styles.headerText}>{STRINGS.visit.quantity}</Text>
          <Text sx={styles.quantityItemText}>{quantity}</Text>
        </Flex>
        {isAccepted === null ? (
          <Flex sx={styles.editContainer}>
            <Button
              variant="mini"
              sx={styles.deleteItemButton}
              onClick={() => preloadDataAndShowModal(true, false, false, cartItem)}
            >
              {STRINGS.workshop.edit}
            </Button>
            <Button
              variant="mini"
              sx={styles.deleteItemButton}
              onClick={() => deleteServiceCartItemHandler(cartItem)}
            >
              {STRINGS.workshop.deleteText}
            </Button>
          </Flex>
        ) : (
          <Flex sx={styles.acceptanceContainer}>
            <Text sx={styles.acceptanceText}>
              {isAccepted ? STRINGS.visit.acceptedCartItem : STRINGS.visit.rejectedCartItem}
            </Text>
          </Flex>
        )}
      </Flex>
    );
  };

  return (
    <Card variant="cartCard" key={id} sx={styles.container}>
      {renderHeader()}
      <Flex sx={styles.itemRowContainer}>
        <Text sx={styles.itemNameText}>{name}</Text>
        <Text sx={styles.itemText}>{formattedPrice}</Text>
      </Flex>
      <Flex sx={styles.itemRowContainer}>
        <Text sx={styles.itemClientDescriptionText}>{STRINGS.visit.priceForClient}</Text>
        <Text sx={styles.itemClientText}>{formattedPriceEachWithFee}</Text>
      </Flex>
      {renderFooter()}
    </Card>
  );
};

VisitEditCartCard.propTypes = {
  cartItem: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default VisitEditCartCard;
