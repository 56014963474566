export const icons = [
  { name: 'WORKSHOP', icon: '1_mechanika.svg' },
  { name: 'AIR_CONDITION', icon: '3_klimatyzacja.svg' },
  { name: 'DETAILING', icon: '5_detailing.svg' },
  { name: 'CAR_INSPECTION', icon: '6_przeglądy.svg' },
  { name: 'ELECTRICAL', icon: '8_elektryka.svg' },
  { name: 'TIRE_SHOP', icon: '2_wulkanizacja.svg' },
  { name: 'ELECTRONICS', icon: '11_elektronika.svg' },
  { name: 'VEHICLE_CONTROL_STATION', icon: '21_stacja kontroli.svg' },
  { name: 'EMERGENCY', icon: '17_awaria pilna.svg' },
  { name: 'LPG', icon: '19_lpg.svg' },
  { name: 'HYBRID', icon: '18_hybrid.svg' },
  { name: 'BODYWORK', icon: '14_blacharstwo.svg' },
  { name: 'CAR_GLASS', icon: '12_szyby.svg' },
  { name: 'GEARBOX', icon: '10_skrzynie biegow.svg' },
  { name: 'UPHOLSTERY', icon: '9_tapicerka.svg' },
  { name: 'ADAS', icon: '15_adas kierownica.svg' },
  { name: 'REGENERATION', icon: '22_regeneracja.svg' },
  { name: 'AUDIO', icon: '20_audio.svg' },
  { name: 'CNG', icon: '16_cng.svg' },
  { name: 'TUNING', icon: '7_tuning.svg' },
  { name: 'OFF_ROAD', icon: '23_off road.svg' },
  { name: 'MOTORCYCLE', icon: '25_motor.svg' },
];
