export default {
  container: {
    flexDirection: 'column',
    flex: 1,
  },
  inputContainer: {
    p: '0.5rem',
  },
  cardsContainer: {
    flexDirection: 'column',
    overflowY: 'scroll',
  },
  input: {
    height: 36,
    p: '1rem',
    bg: 'button_bg',
  },
};
