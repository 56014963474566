export default {
  container: {
    justifyContent: 'center',
    transition: '0.2s',
    '&:hover': {
      transform: 'scale(1.01)',
    },
  },
  valueContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconContainer: {
    backgroundColor: 'text',
    color: 'background',
    height: '100%',
    p: '5px',
    AspectRatio: 1 / 1,
    borderRadius: '5px',
  },
  textContainer: {
    flex: 1,
    px: '1rem',
    justifyContent: 'space-between',
  },
  valueText: {
    flex: 1,
    textAlign: 'left',
  },
  counterContainer: {},
  counterText: {},
};
