export default {
  container: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'shadowModal',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1001,
  },
};
