export default {
  container: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  menuContainer: {
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginVertical: '5px',
  },
  activeBar: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    bg: 'content_bg_selected',
    boxShadow: (t) => `inset 0 -2px 4px ${t.colors.shadow}`,
    border: '0.5px solid transparent',
    '&:hover': {
      bg: 'content_bg_hover',
      boxShadow: (t) => `inset 0 -2px 4px ${t.colors.shadow}`,
      border: '0.5px solid transparent',
    },
  },
  edgeActiveBar: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    bg: 'content_bg_selected',
    boxShadow: (t) => `inset 0 -2px 4px ${t.colors.shadow}`,
    border: '0.5px solid transparent',
    '&:hover': {
      bg: 'content_bg_hover',
      boxShadow: (t) => `inset 0 -2px 4px ${t.colors.shadow}`,
      border: '0.5px solid transparent',
    },
  },
  bar: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      bg: 'content_bg_hover',
    },
  },
  edgeBar: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      bg: 'content_bg_hover',
    },
  },
  barText: {
    textAlign: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  activeBarText: {
    textAlign: 'center',
    color: 'primary',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  childContainer: {
    flexDirection: 'column',
    flexGrow: 1,
    mt: '10px',
  },
};
