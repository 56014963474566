import {
  IoIosCalendar,
  IoIosCar,
  IoIosDesktop,
  IoIosHome,
  IoIosListBox,
  IoIosSettings,
} from 'react-icons/io';

import STRINGS from './strings';

export default [
  {
    name: STRINGS.menuItems.dashboard,
    icon: IoIosDesktop,
    url: '/',
  },
  {
    name: STRINGS.menuItems.visits,
    icon: IoIosListBox,
    url: '/visits',
  },
  {
    name: STRINGS.menuItems.calendar,
    icon: IoIosCalendar,
    url: '/calendar',
  },
  {
    name: STRINGS.menuItems.cars,
    icon: IoIosCar,
    url: '/cars',
  },
  {
    name: STRINGS.menuItems.workshop,
    icon: IoIosHome,
    url: '/workshop',
  },
  {
    name: STRINGS.menuItems.settings,
    icon: IoIosSettings,
    url: '/settings/profile',
  },
];
