export default {
  container: {},
  typeHeaderContainer: {
    mb: '1rem',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  editButton: {
    alignSelf: 'flex-end',
  },
  editButtonText: {
    color: 'primary',
  },
  headerTypeText: {
    flex: 1,
    alignSelf: 'flex-start',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  headerText: {
    fontSize: ['12px', null, '14px'],
    color: 'text_secondary',
  },
  itemDivider: {
    m: 0,
    borderColor: 'content_bg_selected',
  },
  itemRowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',

    my: '10px',
  },
  itemNameText: {
    flex: 1,
    fontSize: ['12px', null, '14px', '16px'],
  },
  priceAndButtonContainer: {
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  itemPriceContainer: {
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  itemPriceText: {},
  clientPriceContainer: {
    color: 'heading',
    flexDirection: 'row',
    alignItems: 'flex-end',
    fontSize: '14px',
    lineHeight: 1.2,
    mb: '5px',
  },
  itemPriceForWorkshopText: {},
  itemPriceForClientDescriptionText: {
    mr: '5px',
  },
  itemPriceForClientText: {},
  itemOrderButton: {},
  itemOrderText: {},
  noItemsContainer: {
    alignItems: 'center',
  },
  noItemsText: {
    textAlign: 'center',
    flex: 1,
  },
};
