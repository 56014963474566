/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Box, Card, Flex, Text } from 'theme-ui';

import { PAYMENT_STATUSES, STRINGS } from '../../../constants';
import { getTimeFromDate } from '../../../utils/formatHours';
import { Link } from '../../others/Link';
import styles from './CalendarTooltip.style';

const CalendarTooltip = (props) => {
  const { resource, children, month = false, week = false } = props;
  const { id, car, messageFromClient, messageFromWorkshop, scheduledTime, paymentStatus } =
    resource;
  const { make, model, productionYear, fuel, registrationNumber } = car ?? {};
  const url = `/visits/${make}/${model}/${id}`;
  const [show, setShow] = useState(false);
  const scheduledDate = new Date(scheduledTime);
  const time = getTimeFromDate(scheduledDate);

  // const watchTooltip = (e) => {
  //   const toolTipElement = document.getElementById('calendarTooltipCard')
  //   toolTipElement.style.left = `${e.pageX}px`;
  //   toolTipElement.style.top = `${e.pageY}px`
  // }

  const renderMessage = (label, message) => {
    if (message) {
      return (
        <Flex sx={styles.messageContainer}>
          <Text sx={styles.label}>{label}</Text>
          <Flex sx={styles.message}>
            <Text sx={styles.messageText}>{message}</Text>
          </Flex>
        </Flex>
      );
    }
    return null;
  };

  const renderTooltip = () => {
    if (show) {
      return (
        <Card variant="tooltipCard" id="calendarTooltipCard" sx={styles.tooltipContainer}>
          <Flex sx={styles.car}>
            <Flex sx={styles.car.container}>
              <Link path={url} href={url} sx={styles.link} target="_blank">
                <Text sx={styles.car.container.makeModel}>{make}</Text>
                <Text sx={styles.car.container.makeModel}>{model}</Text>
              </Link>
            </Flex>
            <Flex sx={styles.car.container}>
              <Link path={url} href={url} sx={styles.link} target="_blank">
                <Text sx={styles.car.container.date}>{time}</Text>
                <Text sx={styles.car.container.info}>{registrationNumber ?? ''}</Text>
                <Text sx={styles.car.container.info}>{productionYear ?? ''}</Text>
              </Link>
            </Flex>
          </Flex>
          {renderMessage(STRINGS.visit.messageFromWorkshopLabel, messageFromWorkshop)}
          {renderMessage(STRINGS.visit.messageFromClientLabel, messageFromClient)}
          <Flex sx={styles.paymentContainer}>
            {paymentStatus === PAYMENT_STATUSES.COMPLETED && (
              <Text sx={styles.infoPayment}>{STRINGS.VISIT_PAID_STATUS.COMPLETED}</Text>
            )}
          </Flex>
          <Box sx={styles.triangle} />
        </Card>
      );
    }
    return null;
  };

  return (
    <Flex
      sx={styles.container}
      onMouseOver={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      // onMouseMove={watchTooltip}
    >
      {renderTooltip()}
      {children}
    </Flex>
  );
};

export default CalendarTooltip;
