import { Flex, Spinner } from 'theme-ui';

import styles from './LoadingIndicator.style';

const LoadingIndicator = () => {
  return (
    <Flex sx={styles.container}>
      <Spinner />
    </Flex>
  );
};

export default LoadingIndicator;
