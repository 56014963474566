export default {
  container: {
    height: '100%',
  },
  tooltipContainer: {
    position: 'absolute',
    zIndex: 10,
    bottom: 'calc(100% + 10px)',
    minWidth: '250px',
    maxWidth: '400px',
    flexDirection: 'column',
  },
  messageContainer: {
    flexDirection: 'column',
    flex: 1,
  },
  label: {
    color: 'text',
    overflow: 'hidden',
    fontSize: ['8px', '10px'],
  },
  message: {
    bg: 'background',
    borderRadius: '5px',
    py: '2px',
    px: '4px',
    flex: 1,
    flexWrap: 'wrap',
  },
  messageText: {
    maxHeight: '50px',
    color: 'text',
    overflowWrap: 'anywhere',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: ['9px', '11px'],
  },
  triangle: {
    position: 'absolute',
    width: 0,
    height: 0,
    left: '10%',
    bottom: '-10px',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '10px solid green',
    borderTopColor: 'card_bg',
  },
  car: {
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    container: {
      overflow: 'hidden',
      textOverflow: 'clip',
      lineHeight: '1',
      makeModel: {
        overflow: 'hidden',
        overflowWrap: 'anywhere',
        textOverflow: 'ellipsis',
        textAlign: 'left',
        color: 'text',
        fontSize: ['9px', '11px'],
        mr: '0.25rem',
      },
      info: {
        overflow: 'hidden',
        overflowWrap: 'anywhere',
        textOverflow: 'ellipsis',
        textAlign: 'left',
        color: 'text',
        fontSize: ['9px', '11px'],
        mx: '0.25rem',
      },
      date: {
        fontWeight: 'bold',
        overflow: 'hidden',
        overflowWrap: 'anywhere',
        textOverflow: 'ellipsis',
        textAlign: 'left',
        color: 'text',
        fontSize: ['9px', '11px'],
        mr: '0.25rem',
      },
    },
  },
  infoPayment: {
    fontSize: ['9px', '11px'],
    color: 'text_secondary',
    fontWeight: 'bold',
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: 'text',
    },
  },
};
