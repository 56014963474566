export default {
  container: {
    position: 'fixed',
    zIndex: 1000,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    overFlow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: ['360px', null, null, null, '500px'],
  },
  contentContainer: {
    flexDirection: 'column',
    flex: 1,
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: '0',
    top: '0',
    color: 'text',
    size: 48,
    m: 0,
    cursor: 'pointer',
  },
  titleText: {
    textAlign: 'center',
  },
  labelText: {
    textTransform: 'uppercase',
  },
  itemsContainer: {
    flexDirection: 'column',
    mb: '1rem',
  },
  inputContainer: {
    alignItems: 'stretch',
    flexDirection: 'column',
    my: '1rem',
  },
  input: {
    borderColor: 'border_color',
  },
  label: {},
  inputField: {
    textAlign: 'center',
    borderWidth: 0.5,
  },
  itemHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  headerText: {
    mx: '7px',
    textAlign: 'center',
    fontSize: '12px',
  },
  valueContainer: {
    justifyContent: 'space-between',
    py: '2px',
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  radioButtonContainer: {
    flex: 1,
  },
  lastMileageEntryContainer: {
    justifyContent: 'space-between',
    bg: 'background',
    px: '1rem',
    py: '5px',
    borderRadius: '5px',
    mt: '5px',
  },
  error: {
    textAlign: 'center',
  },
  inputError: {
    color: 'error',
  },
  button: {},
  disabledButton: {},
  radioTrue: {
    color: 'success',
  },
  radioFalse: {
    color: 'error',
  },
};
