export default {
  dropContainer: {
    padding: 0,
  },
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
  },
  itemButton: {
    width: '100%',
    height: '100%',
  },
  itemContainer: {
    padding: 0,
  },
};
