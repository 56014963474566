import { Button, Flex, Text } from 'theme-ui';

import { STRINGS, VISIT_STATUSES } from '../../../constants';
import AppointmentTime from './AppointmentTime';
import RejectionReasonCard from './RejectionReasonCard';
import styles from './VisitClientInfo.style';

const VisitClientInfo = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { visitDetail, action, color, isClient, children } = props;
  const {
    scheduledTime,
    timePreference,
    timePropositions,
    alternativeTimePropositions,
    status,
    workshopRejectedVisit,
  } = visitDetail ?? {};
  const { actionButton, actionFunc } = action ?? {};
  const { firstProposition, secondProposition } = timePropositions ?? {};
  const {
    firstProposition: firstAlternativeProposition,
    secondProposition: secondAlternativeProposition,
  } = alternativeTimePropositions ?? {};
  const rejectedFirstProposition = workshopRejectedVisit?.firstProposition ?? null;
  const rejectedSecondProposition = workshopRejectedVisit?.secondProposition ?? null;
  const rejectionReason = workshopRejectedVisit?.rejectionReason ?? null;

  const renderTitle = () => {
    if (status === VISIT_STATUSES.REJECTED) {
      return STRINGS.visit.visitTimeAlternativeLabel;
    }
    if (status === VISIT_STATUSES.ASKED_NEW_TIME) {
      return STRINGS.visit.previousVisitsLabel;
    }
    if (status === VISIT_STATUSES.PROPOSED_NEW_TIME) {
      return STRINGS.visit.visitNewTimeLabel;
    }
    return STRINGS.visit.visitTimeLabel;
  };

  const renderAppointmentTime = (index, time, label, showYear = true) => {
    if (time) {
      return (
        <Flex sx={styles.appointmentContainer}>
          <AppointmentTime
            scheduledTime={time}
            label={label}
            showYear={showYear}
            labelStyle={showYear ? styles.labelStyle : {}}
          />
        </Flex>
      );
    }
    return null;
  };

  const renderRejectionReason = () => {
    return <RejectionReasonCard rejectionReason={rejectionReason} />;
  };

  const renderVisitStatus = () => {
    if (status === VISIT_STATUSES.NEW || status === VISIT_STATUSES.CANCELLED) {
      return (
        <Flex sx={styles.timePreferenceRow}>
          <Flex sx={styles.timePreferenceLabelContainer}>
            <Text sx={styles.timePreferenceLabel}>{STRINGS.visit.preferredVisitTime}</Text>
          </Flex>
          <Text sx={styles.timePreferenceText}>
            {STRINGS.VISIT_TIME_PREFERENCE[timePreference]}
          </Text>
        </Flex>
      );
    }
    if (status === VISIT_STATUSES.PROPOSED_TIME && (!!firstProposition || !!secondProposition)) {
      return (
        <Flex sx={styles.timeColumn}>
          {[firstProposition, secondProposition].map((item, index) => {
            return renderAppointmentTime(
              index,
              item,
              `${STRINGS.visit.visitTime} ${index + 1}`,
              false,
            );
          })}
        </Flex>
      );
    }
    if (
      status === VISIT_STATUSES.PROPOSED_NEW_TIME &&
      (!!firstAlternativeProposition || !!secondAlternativeProposition)
    ) {
      return (
        <Flex sx={styles.timeColumn}>
          {[firstAlternativeProposition, secondAlternativeProposition].map((item, index) => {
            return renderAppointmentTime(
              index,
              item,
              `${STRINGS.visit.visitTime} ${index + 1}`,
              false,
            );
          })}
        </Flex>
      );
    }
    if (status === VISIT_STATUSES.ASKED_NEW_TIME && (!!firstProposition || !!secondProposition)) {
      return (
        <Flex sx={styles.timeColumn}>
          {[firstProposition, secondProposition].map((item, index) => {
            return renderAppointmentTime(
              index,
              item,
              `${STRINGS.visit.visitTime} ${index + 1}`,
              false,
            );
          })}
        </Flex>
      );
    }
    if (
      status === VISIT_STATUSES.REJECTED &&
      (!!rejectedFirstProposition || !!rejectedSecondProposition)
    ) {
      return (
        <Flex sx={styles.timeColumn}>
          {[rejectedFirstProposition, rejectedSecondProposition].map((item, index) => {
            return renderAppointmentTime(
              index,
              item,
              `${STRINGS.visit.visitTime} ${index + 1}`,
              false,
            );
          })}
          {renderRejectionReason()}
        </Flex>
      );
    }
    if (scheduledTime) {
      return renderAppointmentTime(0, scheduledTime, STRINGS.VISIT_STATUS.CLIENT[status]);
    }
    return <></>;
  };

  return (
    <Flex sx={styles.container}>
      <Flex sx={{ ...styles.headerExternalContainer, borderColor: color }}>
        <Text sx={styles.headerText}>{renderTitle()}</Text>
        <Flex sx={styles.headerContainer}>{renderVisitStatus()}</Flex>
      </Flex>
      {actionButton && (
        <Flex sx={styles.actionButtonContainer}>
          <Button variant="primary" onClick={actionFunc} sx={{ bg: color }}>
            {actionButton}
          </Button>
        </Flex>
      )}
      {children}
    </Flex>
  );
};

export default VisitClientInfo;
