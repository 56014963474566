/* eslint-disable react/no-array-index-key */
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { Box, Container, Text } from 'theme-ui';

import { STRINGS } from '../../constants';
import { Link } from '../others/Link';
import styles from './Footer.style';

export default function Footer() {
  const social = [
    { path: 'https://www.facebook.com/MeldMoto', icon: <FaFacebookF /> },
    { path: 'https://www.instagram.com/meldmoto', icon: <FaInstagram /> },
  ];

  return (
    <footer>
      <Box variant="layout.footer" sx={styles.footer}>
        <Container sx={styles.footer.container}>
          <Box sx={styles.footer.footerTopArea}>
            <Box sx={styles.footer.social}>
              {social.map(({ path, icon }, i) => (
                <Box id={path} as="span" key={i}>
                  <Link path={path} href={path} sx={styles.footer.social.icon}>
                    {icon}
                  </Link>
                </Box>
              ))}
            </Box>
            <Text sx={styles.footer.copyright}>{STRINGS.common.copyright}</Text>
          </Box>
        </Container>
      </Box>
    </footer>
  );
}
