export default {
  container: {
    flexDirection: 'column',
    borderTop: 'solid 1px',
    borderTopColor: 'border_color',
    mt: '1rem',
    py: '0.5rem',
  },
  reviewHeader: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  progress: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    left: {},
    star: {
      mx: 1,
    },
    right: {},
    name: {
      mx: 2,
    },
    car: {
      fontSize: '14px',
      color: 'secondary',
      p: 0,
    },
  },
  content: {
    my: 1,
    text: {},
  },
  footer: {
    alignItems: 'center',
    flex: 1,
    reviewDate: {
      fontSize: '14px',
      color: 'secondary',
    },
  },
  reportedText: {
    color: 'error',
    mx: '1rem',
    fontSize: 1,
  },
};
