import PropTypes from 'prop-types';
import { Button, Flex, Text } from 'theme-ui';

import { SERVICE_SCOPES, STRINGS } from '../../../constants';
import styles from './AdditionalCartConfirmationCard.style';

const AdditionalCartConfirmationCard = (props) => {
  const { status, askConfirmAdditionalServiceHandler, color, cardStyle } = props;

  const renderAskConfirmButton = () => {
    if (status === SERVICE_SCOPES.SERVICE_SCOPE_UNKNOWN_ADDITIONAL) {
      return (
        <Flex sx={styles.askConfirmationButtonContainer}>
          <Button sx={styles.askButton} onClick={askConfirmAdditionalServiceHandler}>
            {STRINGS.visit.askConfirmButtonText}
          </Button>
        </Flex>
      );
    }
    return null;
  };

  const renderWorkshopConfirmationStatusBar = () => {
    if (status === SERVICE_SCOPES.SERVICE_SCOPE_ASKED_ADDITIONAL) {
      return (
        <Flex sx={styles.askedStatusContainer}>
          <Text sx={styles.askedStatusText}>{STRINGS.visit.cartAskedWorkshopStatus}</Text>
        </Flex>
      );
    }
    if (status === SERVICE_SCOPES.SERVICE_SCOPE_ACCEPTED_ADDITIONAL) {
      return (
        <Flex sx={styles.confirmedStatusContainer}>
          <Text sx={{ ...styles.confirmedStatusText, color }}>
            {STRINGS.visit.cartConfirmedWorkshopStatus}
          </Text>
        </Flex>
      );
    }
    if (status === SERVICE_SCOPES.SERVICE_SCOPE_REJECTED_ADDITIONAL) {
      return (
        <Flex sx={styles.rejectedStatusContainer}>
          <Text sx={{ ...styles.rejectedStatusText, color }}>
            {STRINGS.visit.cartRejectedWorkshopStatus}
          </Text>
        </Flex>
      );
    }
    return null;
  };

  return (
    <Flex sx={styles.mainContainer}>
      <Flex sx={styles.labelContainer}>
        <Text sx={styles.label}>{STRINGS.visit.additionalCartConfirmText}</Text>
        <Text sx={styles.description}>
          {STRINGS.visit.additionalCartConfirmWorkshopDescription}
        </Text>
      </Flex>
      <Flex sx={{ ...styles.container, ...cardStyle }}>
        {renderAskConfirmButton()}
        {renderWorkshopConfirmationStatusBar()}
      </Flex>
    </Flex>
  );
};

AdditionalCartConfirmationCard.propTypes = {
  status: PropTypes.string.isRequired,
  askConfirmAdditionalServiceHandler: PropTypes.func,
  color: PropTypes.string.isRequired,
  cardStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

AdditionalCartConfirmationCard.defaultProps = {
  askConfirmAdditionalServiceHandler: undefined,
  cardStyle: {},
};

export default AdditionalCartConfirmationCard;
