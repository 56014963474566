import { IoIosArrowBack, IoIosMenu, IoIosMoon, IoIosPerson, IoIosSunny } from 'react-icons/io';
import { Box, Container, Flex, IconButton, useColorMode } from 'theme-ui';

import LogoYellow from '../../assets/logo-yellow.svg';
import { MENU_ITEMS, STRINGS } from '../../constants';
import { Link } from '../others/Link';
import Logo from '../others/Logo';
import styles from './Header.style';

export default function Header(props) {
  const { isOpened, toggleDrawer, isAuth } = props;
  const [colorMode, setColorMode] = useColorMode();

  const renderItem = () => {
    if (!isAuth) {
      return null;
    }
    const item = MENU_ITEMS.find((elem) => elem.name === STRINGS.menuItems.settings);
    const { url, name } = item;
    return (
      <Link path={url} href={url} sx={styles.link}>
        <Flex sx={styles.link.container}>
          <IconButton aria-label={name} sx={styles.link.icon}>
            <IoIosPerson size={32} />
          </IconButton>
        </Flex>
      </Link>
    );
  };

  const renderMenuButton = () => {
    if (!isAuth) {
      return null;
    }
    if (isOpened) {
      return (
        <IconButton aria-label="Close menu" onClick={toggleDrawer} sx={styles.menuButton}>
          <IoIosArrowBack size={32} />
        </IconButton>
      );
    }
    return (
      <IconButton aria-label="Open menu" onClick={toggleDrawer} sx={styles.menuButton}>
        <IoIosMenu size={32} />
      </IconButton>
    );
  };

  return (
    <header>
      <Box variant="layout.header" sx={styles.header} id="header">
        <Flex sx={styles.outerContainer}>
          <Flex sx={styles.link.container}>{renderMenuButton()}</Flex>
          <Container sx={styles.container}>
            <Logo id="logo" src={LogoYellow} />
            {renderItem()}
          </Container>
          <Flex sx={styles.link.container}>
            <IconButton
              aria-label="Color mode"
              sx={styles.colorModeButton}
              onClick={() => setColorMode(colorMode === 'light' ? 'dark' : 'light')}
            >
              {colorMode === 'light' ? (
                <IoIosMoon id="darkMode" size={32} />
              ) : (
                <IoIosSunny id="lightMode" size={32} />
              )}
            </IconButton>
          </Flex>
        </Flex>
      </Box>
    </header>
  );
}
