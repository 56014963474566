export default {
  card: {},
  cardDark: {
    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.5)',
  },
  centeredFlex: {
    justifyContent: 'start',
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    padding: 0,
    bg: 'background_secondary',
  },
  missingPhotoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    bg: 'background_secondary',
    p: 4,
  },
  missingPhoto: {
    font: 25,
    color: 'text',
  },
  imageOpinionContainer: {
    alignSelf: 'flex-end',
    position: 'absolute',
    backgroundColor: 'background_secondary',
    zIndex: 100,
    width: 100,
    height: 60,
    marginTop: -60,
    borderTopRightRadius: '10px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  info: {
    px: '1rem',
    pb: '1rem',
    justifyContent: 'center',
    flexDirection: 'column',
    header: {
      color: 'primary',
      fontSize: 25,
      textAlign: 'center',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    about: {
      flexDirection: 'column',
      mt: 2,
      description: {
        color: 'heading',
      },
    },
    contactBox: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
    contact: {
      flexDirection: 'column',
      flexWrap: 'wrap',
      container: {
        mt: 1,
        flexDirection: 'row',
        alignItems: 'center',
        icon: {
          size: 25,
          color: 'heading',
        },
        text: {
          mx: 1,
          fontSize: '15px',
          color: 'heading',
        },
      },
    },
  },

  contactValuesContainer: {
    width: '100%',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
  },
  contactElementContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  rateText: {
    m: 0,
    lineHeight: '20px',
    '&:hover': {
      color: 'text_secondary',
    },
  },
  opinionText: {
    m: 0,
    lineHeight: '20px',
    '&:hover': {
      color: 'text_secondary',
    },
  },
  contactText: {
    margin: 0,
    fontSize: '1em',
  },
  link: {
    textDecoration: 'none',
    color: 'text',
  },
  showOpinionsText: {
    fontSize: '12px',
    '&:hover': {
      color: 'text_secondary',
    },
  },
};
