export default {
  header: {
    fontWeight: 'normal',
    py: 3,
  },
  outerContainer: {
    mx: '0.5rem',
  },
  menuButton: {
    mx: '0.5rem',
    cursor: 'pointer',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  nav: {
    mx: 'auto',
    display: 'none',
    '@media screen and (min-width: 1024px)': {
      display: 'block',
    },
    a: {
      fontSize: '16px',
      fontWeight: '400',
      px: 25,
      cursor: 'pointer',
      lineHeight: '1.2',
      '&.active': {
        color: 'secondary',
      },
    },
  },
  centerFlex: {
    alignItems: 'center',
  },
  colorModeButton: {
    mx: '0.5rem',
    cursor: 'pointer',
  },
  profileButton: {
    mx: 0,
    cursor: 'pointer',
  },
  link: {
    container: {
      alignItems: 'center',
      justifyContent: 'flex-start',
      py: '5px',
      '&:hover': {
        bg: 'header_sidebar_hover',
        borderRadius: '5px',
        pt: '5px',
      },
    },
    icon: {
      color: 'text',
      cursor: 'pointer',
      mx: '0.5rem',
    },
  },
};
