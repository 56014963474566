import { Card, Flex, Text } from 'theme-ui';

import { STRINGS } from '../../../constants/workshop';
import { getDayFromDate, getTimeFromDate } from '../../../utils/formatHours';
import styles from './PreviousTimeCard.style';

const PreviousTimeCard = (props) => {
  const { previousPropositions } = props;

  const renderPreviousTime = (scheduledTime) => {
    const scheduledDate = new Date(scheduledTime);
    const time = getTimeFromDate(scheduledDate);
    const convertedDate = getDayFromDate(scheduledDate);
    const { date, month, day } = convertedDate;
    return (
      <Flex sx={styles.previousTimeContainer}>
        <Flex sx={styles.previousTimeRow}>
          <Text sx={styles.previousMainText}>{time}</Text>
          <Text sx={styles.previousSubText}>{day}</Text>
        </Flex>
        <Flex sx={styles.previousTimeRow}>
          <Text sx={styles.previousMainText}>{date}</Text>
          <Text sx={styles.previousSubText}>{month}</Text>
        </Flex>
      </Flex>
    );
  };

  if (previousPropositions) {
    return (
      <Card variant="previousTimeCard" sx={styles.container}>
        <Text sx={styles.previousText}>{STRINGS.visits.previousTime}</Text>
        <Flex sx={styles.previousContainer}>
          {previousPropositions.firstProposition &&
            renderPreviousTime(previousPropositions.firstProposition)}
          {previousPropositions.secondProposition &&
            renderPreviousTime(previousPropositions.secondProposition)}
        </Flex>
      </Card>
    );
  }
  return null;
};

export default PreviousTimeCard;
