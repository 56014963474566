import PropTypes from 'prop-types';
import { IoMdCheckmark, IoMdClose } from 'react-icons/io';
import { Button, Card, Divider, Flex, Text } from 'theme-ui';

import { STRINGS } from '../../../constants';
import styles from './ServiceReportCard.style';

const ServiceReportCard = (props) => {
  const { serviceReport, isEditable, isVisible, createReport, cardStyle } = props;
  const { brake, coolant, lights, mileage, oil, tires } = serviceReport ?? {};
  const mileageInKilometers = mileage?.mileage;

  const emptyItem = () => {
    return <Text sx={styles.emptyItemText}>-</Text>;
  };

  const renderItem = (label, value) => {
    return (
      <Flex sx={styles.itemRowContainer}>
        <Text sx={styles.itemNameText}>{label}</Text>
        {typeof value === 'boolean' ? renderItemValue(value) : emptyItem()}
      </Flex>
    );
  };

  const renderMileage = () => {
    const mileageValue = `${mileageInKilometers} ${STRINGS.car.kilometers}`;
    return (
      <Flex sx={styles.itemRowContainer}>
        <Text sx={styles.headerText}>{STRINGS.visit.mileage}</Text>
        {mileageInKilometers ? <Text sx={styles.headerText}>{mileageValue}</Text> : emptyItem()}
      </Flex>
    );
  };

  const renderItemValue = (status) => {
    const iconComponent = status ? (
      <IoMdCheckmark color="green" size={24} />
    ) : (
      <IoMdClose color="red" size={24} />
    );
    return <Flex sx={styles.itemValue}>{iconComponent}</Flex>;
  };

  const renderReport = () => {
    if (!isVisible) {
      return (
        <Flex sx={styles.noReportContainer}>
          <Text sx={styles.unavailableText}>{STRINGS.visit.reportNonVisible}</Text>
        </Flex>
      );
    }
    return (
      <>
        <Flex sx={styles.reportContainer}>
          {renderMileage()}
          {renderItem(STRINGS.visit.oil, oil)}
          {renderItem(STRINGS.visit.brake, brake)}
          {renderItem(STRINGS.visit.coolant, coolant)}
          {renderItem(STRINGS.visit.tires, tires)}
          {renderItem(STRINGS.visit.lights, lights)}
        </Flex>
      </>
    );
  };

  const renderNoReport = () => {
    if (!isEditable) {
      return (
        <Flex sx={styles.noReportClientContainer}>
          <Text sx={styles.unavailableText}>{STRINGS.visit.reportUnavailable}</Text>
        </Flex>
      );
    }
    return (
      <Flex sx={styles.noReportContainer}>
        <Button onClick={createReport} sx={styles.createReportButton}>
          {STRINGS.visit.createReport}
        </Button>
      </Flex>
    );
  };

  return (
    <Flex sx={styles.mainContainer}>
      <Card variant="reportCard" sx={styles.contentContainer}>
        <Flex sx={styles.labelContainer}>
          <Text sx={styles.label}>{STRINGS.visit.report}</Text>
        </Flex>
        <Divider sx={styles.separator} />
        <Flex sx={{ ...styles.container, ...cardStyle }}>
          {serviceReport?.id ? renderReport() : renderNoReport()}
        </Flex>
      </Card>
    </Flex>
  );
};

ServiceReportCard.propTypes = {
  serviceReport: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  isEditable: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool,
  createReport: PropTypes.func,
  cardStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ServiceReportCard.defaultProps = {
  createReport: null,
  cardStyle: {},
  isVisible: true,
};

export default ServiceReportCard;
