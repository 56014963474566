import { useState } from 'react';
import { Flex, Input } from 'theme-ui';

import { STRINGS } from '../../../../constants';
import VisitRegularServiceCard from './VisitRegularServiceCard';
import styles from './WorkshopServicesPriceList.style';

const WorkshopServicesPriceList = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const { regularServices, preloadDataAndShowModal, editPriceListNavigator, panelHeight } = props;

  const renderInput = () => {
    return (
      <Flex sx={styles.inputContainer}>
        <Input
          placeholder={STRINGS.visit.search}
          sx={styles.input}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </Flex>
    );
  };

  const renderCards = () => {
    if (regularServices && !searchValue) {
      return Object.entries(regularServices).map(([typ, services]) => {
        return (
          <VisitRegularServiceCard
            key={typ}
            typ={typ}
            services={services}
            action={preloadDataAndShowModal}
            editVisitServicesPriceListForTypeNavigator={() => editPriceListNavigator(typ)}
          />
        );
      });
    }
    if (regularServices && searchValue) {
      return Object.entries(regularServices).map(([typ, services]) => {
        const filteredServices = Object.fromEntries(
          // eslint-disable-next-line no-unused-vars
          Object.entries(STRINGS.REGULAR_SERVICES[typ]).filter(([_, v]) =>
            v.toLowerCase().includes(searchValue.toLowerCase()),
          ),
        );

        const searchedServices = Object.keys(filteredServices).reduce((prev, current) => {
          if (services.find((e) => e.name === current)) {
            prev.push(services.find((e) => e.name === current));
          } else {
            prev.push({ id: undefined, name: current, typ, price: undefined });
          }
          return prev;
        }, []);

        if (searchedServices.length === 0) {
          return null;
        }

        return (
          <VisitRegularServiceCard
            key={typ}
            typ={typ}
            services={searchedServices}
            action={preloadDataAndShowModal}
            editVisitServicesPriceListForTypeNavigator={() => editPriceListNavigator(typ)}
          />
        );
      });
    }
    return null;
  };

  return (
    <Flex sx={styles.container}>
      {renderInput()}
      <Flex sx={{ ...styles.cardsContainer, height: panelHeight }}>{renderCards()}</Flex>
    </Flex>
  );
};

export default WorkshopServicesPriceList;
