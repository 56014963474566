const linkKeyFrames = `keyframes({ from: { top: -300px, opacity:0 }, to: { top:0, opacity: 1 } })`;

export default {
  container: {
    position: 'fixed',
    zIndex: 3000,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.4)',
    overFlow: 'auto',
  },
  modal: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'background',
    p: '10px',
    borderRadius: '15px',
    width: ['360px', null, null, null, '500px'],
    hight: 'auto',
    m: '40vh auto',
    animation: `${linkKeyFrames} 0.6s linear  alternate`,
  },
  closeButton: {
    position: 'absolute',
    right: '0',
    top: '0',
    color: 'black',
    p: 0,
    m: 0,
  },
  title: {
    color: 'text',
    fontSize: 4,
    mb: '5px',
  },
  content: {
    lineHeight: 1.2,
    textAlign: 'center',
  },
  button: {
    mt: '1rem',
  },
};
