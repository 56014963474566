export default {
  container: {
    backgroundColor: 'background_secondary',
    flexDirection: 'row',
    p: '1rem',
    flex: 1,
    borderRadius: '10px',
    boxShadow: (t) => `0 3px 6px ${t.colors.shadow}`,
  },
  containerInactive: {
    backgroundColor: 'background_secondary',
    opacity: '0.5',
    flexDirection: 'row',
    p: '1rem',
    flex: 1,
    borderRadius: '10px',
    boxShadow: (t) => `inset 0 -3px 6px ${t.colors.shadow}`,
  },
  delete: {},
  deleteText: {},
  imagePlaceholder: {
    backgroundColor: 'primary',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: '4rem',
    height: '4rem',
    borderRadius: '10px',
  },
  imagePlaceholderInactive: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  nameText: {
    flex: 1,
  },
  nameTextInactive: {
    flex: 1,
  },
  switchLabel: {},
  switchLabelInactive: {},
  employeeInfoContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    px: '1rem',
  },
  nameAndActionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: '10px',
  },
  switchContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  switchText: {},
  loadingIndicator: {},

  imageContainer: {
    flex: 1,
    backgroundColor: 'primary',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    borderWidth: 2,
    objectFit: 'cover',
    display: 'flex',
    flex: 1,
  },
  leftContainer: {
    flexDirection: 'column',
    alignSelf: 'center',
    flex: 0.2,
  },
};
