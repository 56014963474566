export default {
  container: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  innerContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  imageContainer: {
    flex: 1,
    backgroundColor: 'primary',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    borderWidth: 2,
    objectFit: 'cover',
    display: 'flex',
    flex: 1,
  },
  textContainer: {
    flex: 1,
    flexWrap: 'wrap',
    overflowWrap: 'anywhere',
    px: '1rem',
  },
  valueContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  nameText: {},
  slugText: {
    fontSize: 1,
    color: 'text_secondary',
  },
  emailText: {},
  leftContainer: {
    flexDirection: 'column',
    alignSelf: 'center',
    width: '50px',
    height: '50px',
  },
  nameContainer: {
    flex: 1,
    flexDirection: 'column',
    lineHeight: 1.2,
  },
  buttonContainer: {},
};
