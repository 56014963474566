export default {
  container: {
    my: '0.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  previousContainer: {
    justifyContent: 'space-evenly',
    my: '0.5rem',
  },
  previousTimeRow: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    mx: '5px',
  },
  previousText: {
    textAlign: 'center',
  },
  previousMainText: {
    lineHeight: 1.2,
    fontWeight: 'bold',
  },
  previousSubText: {
    lineHeight: 1.2,
  },
};
