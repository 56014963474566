import { keyframes } from '@emotion/react';

const rotateStart = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

export default {
  buttonContainer: {
    justifyContent: 'flex-end',
  },
  button: {},
  container: {
    flex: 1,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonText: {
    display: ['none', null, null, null, null, null, 'block'],
    m: 0,
    p: 0,
    mr: '5px',
    fontSize: ['14px', null, null, null, '16px'],
  },
  iconContainer: {
    size: [16, null, 18, null, 20],
    animation: `${rotateStart} 0.5s linear infinite`,
  },
  iconSizes: [16, null, 18, null, 20],
};
