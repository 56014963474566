export default {
  footer: {
    container: {
      width: '100%',
      alignItems: 'stretch',
    },
    footerTopArea: {
      display: 'flex',
      flexWrap: 'wrap',
      pt: [3, null, 4],
      pb: ['10px', null, null, '20px'],
      px: [0, null, null, null, 4],
    },
    social: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'text',
        fontSize: 25,
        mr: '15px',
        transition: 'all 0.25s',
        cursor: 'pointer',
        ':last-child': {
          mr: '0',
        },
        '&:hover': {
          color: 'primary',
        },
      },
    },
    copyright: {
      fontSize: ['14px', null, 1],
      width: '100%',
      textAlign: 'center',
      color: 'text',
      a: {
        textDecoration: 'none',
        color: 'inherit',
        pl: 1,
        transition: 'all 0.25s',
        '&:hover': {
          color: 'primary',
        },
      },
    },
  },
};
