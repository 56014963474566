export default {
  card: {
    flex: 1,
    '@media screen and (max-width: 1024px)': {
      height: ['60vh', '40vh', null],
      flex: 0,
    },
    mt: 0,
    p: '1rem',
    borderRadius: '10px',
  },
  cardDark: {
    flex: 1,
    '@media screen and (max-width: 1024px)': {
      height: ['60vh', '40vh', null],
      flex: 0,
    },
    mt: 0,
    p: '1rem',
    borderRadius: '10px',
    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.5)',
  },
  marker: {
    justifyContent: 'center',
    position: 'absolute',
    width: 50,
    height: 50,
    left: -50 / 2,
    top: -50,
    sign: {
      backgroundColor: 'black',
      height: null,
      color: 'white',
      width: '250px',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '0.5rem',
      borderRadius: '1em',
      position: 'absolute',
      top: '-40px',
      address: {
        margin: 0,
        fontFamily: 'Montserrat, sans-serif',
      },
      name: {
        fontFamily: 'Montserrat, sans-serif',
        margin: 0,
        fontSize: 14,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        my: '0.2rem',
        color: 'primary',
      },
    },
  },
};
