import { IoIosClose } from 'react-icons/io';
import { Box, Button, Card, Flex, IconButton, Input, Label, Radio, Text } from 'theme-ui';

import { STRINGS } from '../../constants';
import DimLoadingIndicator from '../others/DimLoadingIndicator';
import styles from './ServiceReportModal.style';

const ServiceReportModal = (props) => {
  const { reportHolder } = props;
  const {
    isModalVisible,
    setIsModalVisible,
    reportValues,
    onReportValueChange,
    createReportHandler,
    fields,
    isModalLoading,
    // eslint-disable-next-line no-unused-vars
    setIsModalLoading,
    mileageEntry,
    watchMileage,
  } = reportHolder;
  // eslint-disable-next-line no-unused-vars
  const { brake, coolant, lights, mileage, oil, tires } = reportValues;

  const isReady =
    Object.values(reportValues).every((value) => typeof value === 'boolean') && watchMileage;

  const renderMileage = () => {
    return (
      <Flex sx={styles.inputContainer}>
        <Label>{fields.label}</Label>
        <Input
          sx={styles.input}
          variant={null}
          inputMode="numeric"
          type="number"
          placeholder={fields.placeholder}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...fields.register(fields.name, fields.validation)}
        />
        {fields?.error?.message && <Text sx={styles.inputError}>{fields.error.message}</Text>}
        {fields?.error?.type === 'validate' && (
          <Text sx={styles.inputError}>{STRINGS.VALIDATORS.HISTORY_ENTRY_REPORT_VALUE}</Text>
        )}
      </Flex>
    );
  };

  const renderHeader = () => {
    return (
      <Flex sx={styles.itemHeaderContainer}>
        <Text sx={styles.headerText}>{STRINGS.visit.conditionGood}</Text>
        <Text sx={styles.headerText}>{STRINGS.visit.conditionBad}</Text>
      </Flex>
    );
  };

  const renderItem = (fieldName, name, value) => {
    return (
      <Flex sx={styles.valueContainer}>
        <Text sx={styles.labelText}>{name}</Text>
        <Flex sx={styles.rowContainer}>
          <Flex sx={styles.radioButtonContainer}>
            <Label>
              <Radio
                sx={styles.radioTrue}
                name={name}
                value={value ? 'true' : 'false'}
                onClick={() => onReportValueChange(fieldName, true)}
              />
            </Label>
          </Flex>
          <Flex sx={styles.radioButtonContainer}>
            <Label>
              <Radio
                sx={styles.radioFalse}
                name={name}
                value={value ? 'true' : 'false'}
                onClick={() => onReportValueChange(fieldName, false)}
              />
            </Label>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  if (isModalVisible) {
    return (
      <Box variant="layout.modalBackground" sx={styles.container}>
        <Card variant="modalCard" sx={styles.modal}>
          {isModalLoading && <DimLoadingIndicator />}
          <IconButton onClick={() => setIsModalVisible(false)} sx={styles.closeButton}>
            <IoIosClose size={48} />
          </IconButton>
          <Flex sx={styles.contentContainer}>
            <Text variant="modalTitle">{STRINGS.visit.createReport}</Text>

            {mileageEntry && (
              <Flex sx={styles.lastMileageEntryContainer}>
                <Text sx={styles.lastMileageEntryLabel}>{STRINGS.visit.lastMileage}</Text>
                <Text
                  sx={styles.lastMileageEntryText}
                >{`${mileageEntry?.mileage} ${STRINGS.car.kilometers}`}</Text>
              </Flex>
            )}

            {renderMileage()}

            <Flex sx={styles.descriptionContainer}>
              <Text sx={styles.descriptionText}>{STRINGS.visit.reportDescription}</Text>
            </Flex>

            <Flex sx={styles.itemsContainer}>
              {renderHeader(STRINGS.visit.conditionGood, STRINGS.visit.conditionBad)}
              {renderItem('oil', STRINGS.visit.oil, oil)}
              {renderItem('brake', STRINGS.visit.brake, brake)}
              {renderItem('coolant', STRINGS.visit.coolant, coolant)}
              {renderItem('tires', STRINGS.visit.tires, tires)}
              {renderItem('lights', STRINGS.visit.lights, lights)}
            </Flex>
            <Button
              disabled={!isReady}
              variant={isReady ? 'primary' : 'disabledButton'}
              onClick={createReportHandler}
            >
              {STRINGS.visit.createReport}
            </Button>
          </Flex>
        </Card>
      </Box>
    );
  }
  return null;
};

export default ServiceReportModal;
