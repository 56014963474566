import PropTypes from 'prop-types';
import { IoIosStar, IoMdMore } from 'react-icons/io';
import { Flex, Text } from 'theme-ui';

import { STRINGS } from '../../constants';
import { WorkshopReview } from '../../models';
import { getDayFromDate } from '../../utils/formatHours';
import DropDown from '../others/DropDown';
import styles from './WorkshopReviewCard.style';

export default function WorkshopReviewCard(props) {
  const { data, action } = props;
  // eslint-disable-next-line camelcase
  const { id, reported, rate, name, content, make, model, releasedDate } = data;
  const starCount = [1, 2, 3, 4, 5];

  const renderHeader = () => {
    const pickColor = (number) => {
      if (number <= rate) return '#F4C714';
      return 'heading_secondary';
    };
    const convertedName = name || STRINGS.workshop.reviewUnknown;
    return (
      <Flex sx={styles.progress}>
        <Text sx={styles.progress.left}>
          <p sx={styles.progress.left.count}>{rate}</p>
        </Text>
        <Flex sx={styles.progress.star}>
          {starCount.map((elem) => (
            <IoIosStar key={elem} color={pickColor(elem)} size="0.8rem" />
          ))}
        </Flex>
        <Text sx={styles.progress.name}>{convertedName}</Text>
        <Text sx={styles.progress.car}>{`${make} ${model}`}</Text>
      </Flex>
    );
  };

  const renderContent = () => {
    return (
      <Flex sx={styles.content}>
        <Text sx={styles.content.text}>{content}</Text>
      </Flex>
    );
  };

  const renderFooter = () => {
    const createdAtDate = new Date(releasedDate);
    const validConvertedDate = getDayFromDate(new Date(createdAtDate));
    const { date, month, year } = validConvertedDate;

    return (
      <Flex sx={styles.footer}>
        <Text
          sx={styles.footer.reviewDate}
        >{`${STRINGS.workshop.reviewReleased} ${date} ${month} ${year}`}</Text>
        {!reported ? (
          <Text sx={styles.reportedText}>{STRINGS.workshop.reviewReported}</Text>
        ) : (
          <DropDown items={[{ action: () => action(id), name: STRINGS.workshop.reviewReport }]}>
            <Flex sx={{ color: 'text' }}>
              <IoMdMore size={22} />
            </Flex>
          </DropDown>
        )}
      </Flex>
    );
  };

  return (
    <Flex sx={styles.container}>
      {renderHeader()}
      {renderContent()}
      {renderFooter()}
    </Flex>
  );
}

WorkshopReviewCard.propTypes = {
  action: PropTypes.func,
  data: PropTypes.instanceOf(WorkshopReview).isRequired,
};

WorkshopReviewCard.defaultProps = {
  action: null,
};
