export default {
  container: {
    position: 'fixed',
    zIndex: 1100,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    overFlow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: ['360px', null, null, null, '500px'],
  },
  contentContainer: {
    flexDirection: 'column',
    flex: 1,
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: '0',
    top: '0',
    color: 'text',
    size: 48,
    m: 0,
    cursor: 'pointer',
  },
  fieldContainer: {
    flexDirection: 'column',
    p: '10px 0.5rem',
  },
  label: {
    fontWeight: 'light',
  },
  input: {
    height: 0,
    p: '1.5rem 0.5rem',
    '&:-webkit-autofill': {
      '-webkit-text-fill-color': '#18181b',
    },
    '&:-webkit-autofill:focus': {
      '-webkit-text-fill-color': '#18181b',
    },
  },
  inputDark: {
    height: 0,
    p: '1.5rem 0.5rem',
    '&:-webkit-autofill': {
      '-webkit-text-fill-color': '#fafafa',
    },
    '&:-webkit-autofill:focus': {
      '-webkit-text-fill-color': '#fafafa',
    },
  },
  textarea: {
    '&:-webkit-autofill': {
      '-webkit-text-fill-color': '#18181b',
    },
    '&:-webkit-autofill:focus': {
      '-webkit-text-fill-color': '#18181b',
    },
  },
  textareaDark: {
    '&:-webkit-autofill': {
      '-webkit-text-fill-color': '#fafafa',
    },
    '&:-webkit-autofill:focus': {
      '-webkit-text-fill-color': '#fafafa',
    },
  },

  spinner: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalTitle: {
    px: ['10px', null, null, null, '2rem'],
  },
  listContainer: {
    flexDirection: 'column',
    height: '40vh',
    overflowY: 'scroll',
    px: '5px',
  },
  listSpinnerContainer: {
    flexDirection: 'column',
    height: '40vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoContainer: {
    flexDirection: 'column',
    textAlign: 'center',
  },
};
