/* eslint-disable react/jsx-props-no-spreading */
import InputMask from 'react-input-mask';
import { Input } from 'theme-ui';

const MaskedInput = (props) => {
  const { mask, name, validation, register, defaultValue, placeholder, inputStyle } = props;
  return (
    <InputMask
      mask={mask}
      placeholder={placeholder}
      defaultValue={defaultValue}
      {...register(name, validation)}
    >
      {(inputProps) => <Input {...inputProps} sx={inputStyle} variant="forms.input" />}
    </InputMask>
  );
};

export default MaskedInput;
