import PropTypes from 'prop-types';
import React from 'react';
import { IoIosAdd, IoIosPerson } from 'react-icons/io';
import { AspectRatio, Box, Button, Card, Flex, Image, Text } from 'theme-ui';

import styles from './SearchCard.style';

const SearchCard = (props) => {
  const { data, action } = props;
  const { id, email, name, surname, image, slug } = data ?? {};

  const renderImage = () => {
    if (image) {
      return (
        <Box sx={styles.outerImageContainer}>
          <AspectRatio ratio={1 / 1} sx={styles.imageContainer}>
            <Image sx={styles.image} src={image.url} />
          </AspectRatio>
        </Box>
      );
    }
    return (
      <Box sx={styles.outerImageContainer}>
        <AspectRatio ratio={1 / 1} sx={styles.imageContainer}>
          <IoIosPerson size={36} color="#000" />
        </AspectRatio>
      </Box>
    );
  };

  const renderName = () => {
    if (name?.length === 0 && surname?.length === 0) {
      return (
        <Flex sx={styles.textContainer}>
          <Text sx={styles.emailText}>{email}</Text>
        </Flex>
      );
    }
    return (
      <Flex sx={styles.textContainer}>
        <Text sx={styles.nameText}>{`${name} ${surname}`}</Text>
      </Flex>
    );
  };

  const renderSlug = () => {
    if (slug?.length === 0) {
      return (
        <Flex sx={styles.textContainer}>
          <Text sx={styles.slugText}>{slug}</Text>
        </Flex>
      );
    }
    return null;
  };

  const renderButton = () => {
    return (
      <Flex sx={styles.buttonContainer}>
        <Button onClick={() => action(id)} variant="addButton">
          <IoIosAdd size={22} />
        </Button>
      </Flex>
    );
  };

  return (
    <Card variant="cartCard">
      <Flex sx={styles.container}>
        <Flex sx={styles.leftContainer}>{renderImage()}</Flex>

        <Flex sx={styles.valueContainer}>
          <Flex sx={styles.nameContainer}>
            {renderName()}
            {renderSlug()}
          </Flex>
          {renderButton()}
        </Flex>
      </Flex>
    </Card>
  );
};

SearchCard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  action: PropTypes.func.isRequired,
};

export default SearchCard;
