export default {
  container: {
    flexDirection: 'column',
    alignSelf: 'center',
  },
  headerExternalContainer: {
    flexDirection: 'column',
    backgroundColor: 'content_bg',
    borderBottomWidth: '4px',
    borderBottomStyle: 'solid',
    borderRadius: '10px',
    boxShadow: (t) => `0 2px 8px ${t.colors.shadow}`,
    p: ['10px', null, null, null, '1rem'],
    flex: 1,
  },
  headerContainer: {
    justifyContent: 'center',
  },
  headerText: {
    fontWeight: 'bold',
    mb: '15px',
  },
  actionButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    my: '1rem',
  },
  timeColumn: {
    flex: 1,
    flexDirection: 'column',
  },
  timePreferenceRow: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  timePreferenceLabelContainer: {
    mr: '1rem',
    lineHeight: 1,
  },
  timePreferenceLabel: {},
  timePreferenceText: {
    fontWeight: 'bold',
  },
  labelStyle: {},
  appointmentContainer: {
    my: '5px',
  },
};
