import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IoIosCheckmarkCircleOutline, IoIosCopy } from 'react-icons/io';
import { Flex, IconButton, Text } from 'theme-ui';

import { STRINGS } from '../../constants';
import styles from './CopyToClipboardButton.style';

const CopyToClipboardButton = ({ text }) => {
  const [visible, setVisible] = useState(false);

  const showPopup = () => {
    setVisible(true);
    const timeOut = setTimeout(() => {
      setVisible(false);
      return clearTimeout(timeOut);
    }, 1000);
  };

  const renderCard = () => {
    if (visible) {
      return (
        <Flex sx={styles.copiedInfoContainer}>
          <Text>{STRINGS.common.copied}</Text>
        </Flex>
      );
    }
    return null;
  };
  return (
    <Flex sx={styles.copyContainer}>
      {renderCard()}
      <CopyToClipboard onCopy={showPopup} text={text}>
        <IconButton sx={styles.copyButton}>
          {visible ? <IoIosCheckmarkCircleOutline size={24} /> : <IoIosCopy size={24} />}
        </IconButton>
      </CopyToClipboard>
    </Flex>
  );
};

export default CopyToClipboardButton;
