import { Button, Flex, Text } from 'theme-ui';

import styles from './HorizontalMenu.style';

const HorizontalMenu = (props) => {
  const {
    labels,
    setSelectedMenu,
    selectedMenu,
    outerStyle,
    menuStyle,
    barStyle,
    activeBarStyle,
    activeBarTextStyle,
    barTextStyle,
    children,
  } = props;

  const renderMenuItem = (label, index) => {
    const isActive = index === selectedMenu;
    return (
      <Button
        variant="menuSwitchButton"
        key={label}
        onClick={() => setSelectedMenu(index)}
        testID={label}
        sx={isActive ? { ...styles.activeBar, ...activeBarStyle } : { ...styles.bar, ...barStyle }}
      >
        <Text
          sx={
            isActive
              ? { ...styles.activeBarText, ...activeBarTextStyle }
              : { ...styles.barText, ...barTextStyle }
          }
        >
          {label}
        </Text>
      </Button>
    );
  };

  const renderMenu = () => {
    return (
      <Flex sx={{ ...styles.menuContainer, ...menuStyle }}>
        {labels.map((label, index) => {
          return renderMenuItem(label, index);
        })}
      </Flex>
    );
  };

  const renderContent = () => {
    return <Flex sx={styles.childContainer}>{children[selectedMenu]}</Flex>;
  };

  return (
    <Flex sx={{ ...styles.container, ...outerStyle }}>
      {renderMenu()}
      {renderContent()}
    </Flex>
  );
};

export default HorizontalMenu;
