import GoogleMapReact from 'google-map-react';
import { Card, Flex, Text, useColorMode } from 'theme-ui';

import styles from './WorkshopMap.style';

const Marker = ({ cityText, streetText, name }) => {
  const markerUrl = 'https://img.icons8.com/ios-filled/50/000000/marker.png';
  return (
    <Flex sx={styles.marker}>
      <Flex sx={styles.marker.sign}>
        <Text sx={styles.marker.sign.name}>{name}</Text>
        <Text sx={styles.marker.sign.address}>{cityText}</Text>
        <Text sx={styles.marker.sign.address}>{streetText}</Text>
      </Flex>
      <img alt="marker" src={markerUrl} />
    </Flex>
  );
};

export default function WorkshopMap(props) {
  // eslint-disable-next-line no-unused-vars
  const [colorMode, setColorMode] = useColorMode();
  const { workshop } = props;
  const { street, streetNumber, postcode, city, latitude, longitude } = workshop;
  const center = {
    lat: Number(latitude),
    lng: Number(longitude),
  };
  return (
    <Card variant="workshopInfoCard" sx={colorMode === 'light' ? styles.card : styles.cardDark}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY }}
        defaultCenter={center}
        defaultZoom={15}
      >
        <Marker
          lat={Number(workshop?.latitude)}
          lng={Number(workshop?.longitude)}
          streetText={`${street} ${streetNumber}`}
          cityText={`${postcode} ${city}`}
          name={workshop.name}
        />
      </GoogleMapReact>
    </Card>
  );
}
