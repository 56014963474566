import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import PropTypes from 'prop-types';
import { Button, Flex } from 'theme-ui';

import styles from './DropDown.style';

const DropDown = (props) => {
  const { items, children } = props;

  const renderItem = (data) => {
    const { action, name } = data;

    return (
      <MenuItem key={data.name} style={styles.itemContainer}>
        <Button variant="dropdown" onClick={action} sx={styles.itemButton}>
          {name}
        </Button>
      </MenuItem>
    );
  };

  const renderButton = () => {
    return <MenuButton style={styles.button}>{children}</MenuButton>;
  };

  return (
    <Flex>
      <Menu transition menuButton={renderButton()} style={styles.dropContainer}>
        {items.map((item) => renderItem(item))}
      </Menu>
    </Flex>
  );
};

DropDown.propTypes = {
  items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default DropDown;
