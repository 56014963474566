import PropTypes from 'prop-types';
import React from 'react';
import { IoIosRefresh } from 'react-icons/io';
import { Button, Card, Flex, Text } from 'theme-ui';

import { PAYMENT_STATUSES, SERVICE_SCOPES, STRINGS } from '../../../constants';
import styles from './FixedPaymentCard.style';

const FixedPaymentCard = (props) => {
  const {
    serviceScopeStatus,
    paymentAction,
    checkPaymentStatusHandler,
    serviceCart,
    paymentStatus,
    totalWithFee,
  } = props;
  const localeString = 'pl';
  const localeCurrency = 'PLN';
  const CENTS_DIVIDER = 100;

  const amount = parseFloat(totalWithFee ? totalWithFee / CENTS_DIVIDER : 0);
  const formattedPriceWithFee = new Intl.NumberFormat(localeString, {
    style: 'currency',
    currency: localeCurrency,
  }).format(parseFloat(amount));

  const renderPaymentStatus = () => {
    return (
      <Flex sx={styles.paymentStatusContainer}>
        <Text sx={styles.paymentStatusText}>
          {paymentStatus
            ? STRINGS.VISIT_PAID_STATUS[paymentStatus]
            : STRINGS.VISIT_PAID_STATUS.WAITING}
        </Text>
        <Text sx={styles.paymentCompletedResultsText}>{formattedPriceWithFee}</Text>
      </Flex>
    );
  };

  const renderServiceScopeStatus = () => {
    if (serviceScopeStatus === SERVICE_SCOPES.SERVICE_SCOPE_REJECTED) {
      return (
        <Flex sx={styles.serviceScopeContainer}>
          <Text sx={styles.serviceScopeText}>
            {STRINGS.SERVICE_SCOPE.SERVICE_SCOPE_REJECTED_FIRST}
          </Text>
          <Text sx={styles.serviceScopeSmallText}>
            {STRINGS.SERVICE_SCOPE.SERVICE_SCOPE_REJECTED_SECOND}
          </Text>
        </Flex>
      );
    }
    return (
      <Flex sx={styles.serviceScopeContainer}>
        <Text sx={styles.serviceScopeText}>{STRINGS.SERVICE_SCOPE.SERVICE_SCOPE_ACCEPTED}</Text>
      </Flex>
    );
  };

  const renderButton = () => {
    const isPriceAboveZero = !!serviceCart?.price || !!serviceCart?.priceWithFee || totalWithFee;
    return (
      <Button
        variant={isPriceAboveZero ? 'mini' : 'disabledMiniButton'}
        disabled={!isPriceAboveZero}
        onClick={paymentAction}
      >
        {STRINGS.visit.payRemind}
      </Button>
    );
  };

  const renderAdditionalServicesBlocking = () => {
    return (
      <Flex sx={styles.serviceScopeContainer}>
        <Text sx={styles.serviceScopeSmallText}>
          {STRINGS.visit.additionalServicesWaitForClient}
        </Text>
      </Flex>
    );
  };

  const renderPaymentAction = () => {
    if (serviceScopeStatus === SERVICE_SCOPES.SERVICE_SCOPE_ASKED_ADDITIONAL) {
      return renderAdditionalServicesBlocking();
    }
    if (paymentStatus !== PAYMENT_STATUSES.COMPLETED) {
      return renderButton();
    }
    return renderServiceScopeStatus();
  };

  const renderPaymentRefresh = () => {
    if (paymentStatus !== PAYMENT_STATUSES.COMPLETED) {
      return (
        <Button
          variant="refreshPaymentButton"
          onClick={checkPaymentStatusHandler}
          sx={styles.checkButton}
        >
          <Text sx={styles.checkText}>{STRINGS.visit.checkStatus}</Text>
          <IoIosRefresh size={18} />
        </Button>
      );
    }
    return null;
  };

  return (
    <Flex sx={styles.container}>
      <Card variant="visitPaymentBarCard" sx={styles.cardContainer}>
        <Flex sx={styles.infoContainer}>{renderPaymentStatus()}</Flex>
        {serviceScopeStatus === SERVICE_SCOPES.SERVICE_SCOPE_ASKED_ADDITIONAL ? (
          <Flex sx={styles.actionContainerAdditional}>{renderPaymentAction()}</Flex>
        ) : (
          <Flex sx={styles.actionContainer}>
            {renderPaymentAction()}
            {renderPaymentRefresh()}
          </Flex>
        )}
      </Card>
    </Flex>
  );
};

FixedPaymentCard.propTypes = {
  serviceScopeStatus: PropTypes.string.isRequired,
  paymentAction: PropTypes.func,
  checkPaymentStatusHandler: PropTypes.func,
  serviceCart: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  paymentStatus: PropTypes.string,
  totalWithFee: PropTypes.string,
};

FixedPaymentCard.defaultProps = {
  checkPaymentStatusHandler: null,
  paymentAction: undefined,
  paymentStatus: null,
  totalWithFee: null,
};

export default FixedPaymentCard;
