export default {
  card: {
    p: '1rem',
  },
  cardDark: {
    p: '1rem',
    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.5)',
  },
  container: {
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    pb: '1rem',
    flex: 1,
    height: '100%',
    day: {
      py: 1,
      borderTop: 'solid 1px',
      borderTopColor: 'border_color',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    dayName: {
      color: 'heading',
    },
    hour: {
      flexWrap: 'wrap',
      ml: '1rem',
    },
    openingHours: {
      color: 'heading',
      px: 1,
      '&:nth-child(n+2)': {
        borderLeft: 'solid 3px',
        borderLeftColor: 'border_color',
      },
    },
  },
};
