import { signOut } from 'next-auth/react';
import { IoIosLogOut } from 'react-icons/io';
import { Box, Flex, IconButton, Text } from 'theme-ui';

import { MENU_ITEMS, STRINGS } from '../../constants';
import { Link } from '../others/Link';
import styles from './Sidebar.style';

export default function Sidebar(props) {
  const { isOpened, isAuth } = props;

  const renderItem = (item) => {
    const { url, icon: Icon, name } = item;
    return (
      <Link key={name} path={url} href={url} sx={styles.link}>
        <Flex sx={styles.link.container}>
          <IconButton aria-label={name} sx={styles.link.icon}>
            <Icon size={32} />
          </IconButton>
          {isOpened && <Text sx={styles.link.label}>{name}</Text>}
        </Flex>
      </Link>
    );
  };

  const renderLogOut = () => {
    return (
      <Box onClick={() => signOut({ redirect: false })} sx={styles.link}>
        <Flex sx={styles.link.container}>
          <IconButton aria-label={STRINGS.settings.logoutText} sx={styles.link.icon}>
            <IoIosLogOut size={32} />
          </IconButton>
          {isOpened && <Text sx={styles.link.label}>{STRINGS.settings.logoutText}</Text>}
        </Flex>
      </Box>
    );
  };

  if (!isAuth) {
    return null;
  }

  return (
    <Flex variant="layout.sidebar" sx={styles.container} className={isOpened ? 'opened' : 'closed'}>
      {MENU_ITEMS.map((item) => {
        return renderItem(item);
      })}
      {renderLogOut()}
    </Flex>
  );
}
