import PropTypes from 'prop-types';
import { AspectRatio, Button, Card, Flex, Heading, Image, Text, useColorMode } from 'theme-ui';

import { icons } from '../../assets/icons';
import { STRINGS } from '../../constants';
import styles from './WorkshopTypes.style';

export default function WorkshopTypes(props) {
  // eslint-disable-next-line no-unused-vars
  const [colorMode, setColorMode] = useColorMode();
  const { setCurrentType, currentType, typ } = props;

  const renderTypeButton = (name) => {
    const matchIcon = icons?.find((item) => item?.name === name);
    if (matchIcon) {
      return (
        <Button
          key={name}
          variant="workshopTypeButton"
          onClick={() => setCurrentType(name)}
          sx={currentType === name ? styles.type.buttonActive : styles.type.button}
        >
          <AspectRatio ratio={1 / 1} sx={styles.buttonBox}>
            <Image sx={styles.buttonBox.image} src={`/icons/${matchIcon?.icon}`} />
            <Text sx={styles.buttonBox.text}>{STRINGS.WORKSHOP_TYPES[name]}</Text>
          </AspectRatio>
        </Button>
      );
    }
    return null;
  };
  return (
    <Card variant="workshopInfoCard" sx={colorMode === 'light' ? styles.card : styles.cardDark}>
      <Heading as="h3">{STRINGS.workshop.workshopType}</Heading>
      <Flex sx={styles.type}>{typ.map((item) => renderTypeButton(item))}</Flex>
    </Card>
  );
}

WorkshopTypes.propTypes = {
  setCurrentType: PropTypes.func.isRequired,
  currentType: PropTypes.string,
  typ: PropTypes.arrayOf(PropTypes.string).isRequired,
};
WorkshopTypes.defaultProps = {
  currentType: null,
};
