import PropTypes from 'prop-types';
import { Card, Flex, Text } from 'theme-ui';

import { STRINGS } from '../../../constants';
import { CarMileageEntry, Visit } from '../../../models';
import styles from './HistoryEntryPreview.style';

const HistoryEntryPreview = (props) => {
  const { item, historyEntryDetailsNavigator, children } = props;
  const { id, visitDetail, mileage } = item ?? {};
  const { messageFromClient, messageFromWorkshop } = visitDetail;
  const mileageInKilometers = mileage?.mileage;

  const renderMessage = (message, label) => {
    const messageValue = message || STRINGS.visit.messageMissing;
    return (
      <Flex sx={styles.messageContainer}>
        <Flex sx={styles.labelContainer}>
          <Text sx={styles.messageLabel}>{label}</Text>
        </Flex>
        <Flex sx={styles.messageAreaContainer}>
          <Text variant="clientMessage" style={styles.messageValue}>
            {messageValue}
          </Text>
        </Flex>
      </Flex>
    );
  };

  const renderMileageInfo = () => {
    if (mileageInKilometers) {
      const mileageValue = `${mileageInKilometers} ${STRINGS.car.kilometers}`;
      return (
        <Flex sx={styles.mileageContainer}>
          <Text sx={styles.mileageLabel}>{STRINGS.visit.mileage}</Text>
          <Text sx={styles.mileageText}>{mileageValue}</Text>
        </Flex>
      );
    }
    return (
      <Flex sx={styles.mileageContainer}>
        <Text sx={styles.mileageLabel}>{STRINGS.visit.mileage}</Text>
        <Text sx={styles.mileageLabel}>{STRINGS.workshop.noInfoText}</Text>
      </Flex>
    );
  };

  return (
    <Card
      variant="historyPreviewCard"
      onClick={() => historyEntryDetailsNavigator(id)}
      sx={styles.container}
    >
      <Flex sx={styles.historyContent}>
        <Flex sx={styles.dateContainer}>{children}</Flex>
        <Flex sx={styles.contentContainer}>
          {renderMessage(messageFromClient, STRINGS.visit.messageFromClientLabel)}
          {renderMessage(messageFromWorkshop, STRINGS.visit.messageFromWorkshopLabel)}
          {renderMileageInfo()}
        </Flex>
      </Flex>
    </Card>
  );
};

HistoryEntryPreview.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    visitDetail: PropTypes.instanceOf(Visit),
    mileage: PropTypes.instanceOf(CarMileageEntry),
  }).isRequired,
  historyEntryDetailsNavigator: PropTypes.func.isRequired,
};

export default HistoryEntryPreview;
