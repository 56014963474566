export default {
  card: {
    p: '1rem',
  },
  cardDark: {
    p: '1rem',
    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.5)',
  },
  type: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    button: {
      m: [1, null, 2],
      flex: ['0 1 45%', null, null, '0 1 25%'],
      p: 2,
      cursor: 'pointer',
      borderRadius: '10px',
      transition: '0.5s',
    },
    buttonActive: {
      m: [1, null, 2],
      flex: ['0 1 45%', null, null, '0 1 25%'],
      p: 2,
      cursor: 'pointer',
      borderRadius: '10px',
      transition: '0.5s',
      backgroundColor: 'primary',
    },
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    image: {
      width: '50%',
      my: 1,
    },
    text: {
      lineHeight: '1rem',
      fontSize: ['16px', null, '20px', '16px', '12px', '14px', '16px'],
    },
  },
};
