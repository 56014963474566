export default {
  spinnerBox: {
    my: '150px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerBox: {
    display: 'flex',
    flexDirection: 'column',
    p: '50px',
    pt: ['50px', '100px'],
    pb: 8,
    justifyContent: 'center',
    flexWrap: ['wrap', null, null, 'nowrap'],
    minWidth: ['100%', null, null, '600px'],
  },
  title: {
    textAlign: 'center',
  },
  grid: {},
  inputBox: {
    mb: 3,
  },
  inputField: {
    height: '45px',
    '-webkit-text-fill-color': (t) => `${t.colors.text}`,
    '&:-webkit-autofill': {
      '-webkit-text-fill-color': (t) => `${t.colors.text}`,
    },
    '&:-webkit-autofill:focus': {
      '-webkit-text-fill-color': (t) => `${t.colors.text}`,
    },
  },
  submitBox: {
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    mt: 3,
  },
  credentialErrorBox: {
    alignSelf: 'center',
  },
  credentialErrorText: {
    fontSize: '14px',
    fontWeight: 'heading',
    mb: '1em',
  },
  errorBox: {
    flexDirection: 'row-reverse',
  },
  successfulText: {
    mx: 1,
    fontSize: 1,
    color: 'text',
  },
  inputContainer: {
    alignItems: 'center',
    position: 'relative',
  },
  iconButton: {
    position: 'absolute',
    right: '1rem',
    color: 'text',
    icon: {
      size: '2rem',
      alignSelf: 'center',
    },
  },
  termsContainer: {
    mt: '2rem',
    alignItems: 'center',
    width: ['100%', null, null, '600px'],
    px: '0.5rem',
  },
  termsInfo: {
    textAlign: 'left',
    fontSize: ['12px', null, '14px'],
    lineHeight: 1.5,
    fontWeight: 'light',
  },
  termsButtonText: {
    color: 'primary',
    textDecoration: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
};
