export default {
  // example colors with dark mode
  colors: {
    heading: '#575757', // primary heading color
    heading_secondary: '#000', // heading color
    background_secondary: '#E0E1E3', // secondary background color
    primary: '#F4C714', // primary button and link color
    secondary: '#999999', // secondary color - can be used for hover states
    muted: '#7B8188', // muted color
    accent: '#660099', // a contrast color for emphasizing UI
    change_mode_button: '#000',
    workshop_banner: '#343D48',
    error: '#c00',
    success: '#1C7C54',
    tailwind_bg: '#fafafa',
    tailwind_bg_light: '#f4f4f5',
    tailwind_bg_hover: '#e4e4e7',
    tailwind_mid: '#d4d4d8',
    tailwind_mid_light: '#a1a1aa',
    tailwind_mid_hover: '#71717a',
    tailwind_mid_dark: '#52525b',
    tailwind_text_hover: '#3f3f46',
    tailwind_text_light: '#27272a',
    tailwind_text: '#18181b',

    text: '#18181b', // body color and primary color
    text_secondary: '#71717a', // secondary body color
    text_disabled: '#a1a1aa', // disabled body color
    background: '#e4e4e7', // body background color
    header_sidebar: '#fafafa', // header and sidebar color
    header_sidebar_hover: '#e4e4e7',
    border_color: '#a1a1aa', // border color
    button_bg: '#fafafa',
    button_bg_pressed: '#e4e4e7',
    login_bg: '#fafafa',
    card_bg: '#fafafa',
    card_bg_hover: '#e4e4e7',
    content_card_bg: '#f4f4f5',
    content_bg: '#fafafa',
    content_bg_hover: '#f4f4f5',
    content_bg_selected: '#e4e4e7',
    shadow: 'rgba(0, 0, 0, 0.125)',
    shadowModal: 'rgba(0, 0, 0, 0.25)',
    shadowInvert: 'rgba(255, 255, 255, 0.25)',
    calendar_button: '#3f3f46',
    calendar_button_today: '#27272a',
    calendar_button_active: '#18181b',
    calendar_text: '#fafafa',
    calendar_event_bg: '#e4e4e7',
    calendar_border: '#e4e4e7',

    // highlight	a background color for highlighting text
    modes: {
      dark: {
        heading: '#bbb',
        heading_secondary: '#fff',
        background_secondary: '#000000',
        secondary: '#999999',
        muted: '#111',
        change_mode_button: '#fff',
        error: '#d00',
        success: '#1C7C54',
        tailwind_bg: '#18181b',
        tailwind_bg_light: '#27272a',
        tailwind_bg_hover: '#3f3f46',
        tailwind_mid: '#52525b',
        tailwind_mid_light: '#71717a',
        tailwind_mid_hover: '#a1a1aa',
        tailwind_mid_dark: '#d4d4d8',
        tailwind_text_hover: '#e4e4e7',
        tailwind_text_light: '#f4f4f5',
        tailwind_text: '#fafafa',

        text: '#fafafa',
        text_secondary: '#a1a1aa',
        text_disabled: '#52525b',
        background: '#18181b',
        header_sidebar: '#27272a',
        header_sidebar_hover: '#18181b',
        border_color: '#52525b',
        button_bg: '#27272a',
        button_bg_pressed: '#18181b',
        login_bg: '#27272a',
        card_bg: '#3f3f46',
        card_bg_hover: '#18181b',
        content_card_bg: '#27272a',
        content_bg: '#3f3f46',
        content_bg_hover: '#27272a',
        content_bg_selected: '#18181b',
        shadow: 'rgba(0, 0, 0, 0.5)',
        shadowModal: 'rgba(0, 0, 0, 0.65)',
        shadowInvert: 'rgba(0, 0, 0, 0.5)',
        calendar_button: '#e4e4e7',
        calendar_button_today: '#f4f4f5',
        calendar_button_active: '#fafafa',
        calendar_text: '#18181b',
        calendar_event_bg: '#3f3f46',
        calendar_border: '#52525b',
      },
    },
  },
  breakpoints: ['480px', '640px', '768px', '1024px', '1220px', '1366px', '1620px'],
  fonts: {
    body: '"Montserrat", sans-serif',
    heading: '"Montserrat", sans-serif',
    monospace: '"Menlo", monospace',
  },
  fontSizes: [12, 15, 16, 18, 20, 22, 24, 28, 32, 36, 42, 48, 52, 64],
  fontWeights: {
    body: 'normal',
    heading: 500,
    bold: 700,
    light: 300,
  },
  lineHeights: {
    body: 1.8,
    heading: 1.5,
  },
  letterSpacings: {
    body: 'normal',
    caps: '0.2em',
    heading: '-0.5px',
  },
  space: [0, 5, 10, 15, 20, 25, 30, 50, 80, 100, 120, 150],
  // variants can use custom, user-defined names
  layout: {
    container: {
      maxWidth: ['100%', null, null, '780px', '1020px', '1200px', null, '1310px'],
      px: [0, 6],
    },
    header: {
      bg: 'header_sidebar',
      position: 'fixed',
      color: 'text',
      width: '100%',
      top: 0,
      left: 0,
      boxShadow: (t) => `0 4px 8px ${t.colors.shadow}`,
    },
    sidebar: {
      bg: 'header_sidebar',
      boxShadow: (t) => `2px 0 4px ${t.colors.shadow}`,
    },
    footer: {
      bg: 'header_sidebar',
      boxShadow: (t) => `0 -2px 4px ${t.colors.shadow}`,
    },
    main: {
      pt: '70px',
      display: 'flex',
      flex: 1,
      position: 'relative',
    },
    modalBackground: {
      backgroundColor: 'shadowModal',
    },
  },
  section: {
    keyFeature: {
      py: [8, null, 9, null, null, 10],
    },
    feature: {
      py: [8, null, 9, null, null, 10],
    },
    partner: {
      pt: [2, null, null, 5],
      pb: [8, null, 9, null, null, 10],
    },
    testimonial: {
      py: [8, null, 9, null, null, 10],
      overflow: 'hidden',
    },
    securePayment: {
      overflow: 'hidden',
      position: 'relative',
      pt: 9,
    },
    faq: {
      py: [8, null, 9, null, null, 10],
    },
    contact: {
      py: [8, null, 9, null, null, 10],
    },
  },
  sectionHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mt: -1,
    marginBottom: ['50px', null, '60px', null, '65px', '75px'],
    title: {
      fontSize: ['24px', null, '28px', null, null, '32px', null, '36px'],
      color: 'heading_secondary',
      lineHeight: [1.3, null, null, 1.2],
      textAlign: 'center',
      fontWeight: '700',
      letterSpacing: '-.5px',
    },
    subTitle: {
      fontSize: [0, null, '14px'],
      color: 'heading',
      textAlign: 'center',
      letterSpacing: ['1.5px', null, '2px'],
      textTransform: 'uppercase',
      fontWeight: '700',
      mb: '8px',
      lineHeight: 1.5,
    },
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 4,
      letterSpacing: 'heading',
      color: 'heading',
    },
    heroPrimary: {
      fontSize: ['24px', '28px', '32px', '32px', '36px', '40px', '42px', '48px'],
      lineHeight: 1.2,
      fontWeight: 500,
      mb: [5, null, null, null, '30px'],
    },
    heroSecondary: {
      color: 'text',
      fontSize: [2, 3, 4, '17px', null, 3, '19px', 4],
      lineHeight: [2, null, null, null, 2.2],
      fontWeight: 'body',
      pr: [0, null, null, null, null, '100px', null, '125px'],
      mb: ['35px', null, null, null, '40px', null, null, 7],
    },
    title: {
      // extends the text.heading styles
      variant: 'text.heading',
      fontWeight: 'bold',
      fontSize: [2, 3, 4, '17px', null, 3, '19px', 4],
      color: 'text',
    },
    modalTitle: {
      variant: 'text.heading',
      fontWeight: 'normal',
      fontSize: '20px',
      lineHeight: '30px',
      color: 'text',
      textAlign: 'center',
    },
    error: {
      fontSize: 14,
      color: 'error',
    },
    lead: {
      fontSize: 40,
      fontFamily: 'DM Sans',
      fontWeight: '500',
      lineHeight: '60px',
      letterSpacing: '-1.5px',
      color: '#0F2137',
    },
    muted: {
      lineHeight: '26px',
      color: 'muted',
    },
    secondary: {
      fontWeight: 500,
      color: '#00A99D',
      lineHeight: '40px',
    },
    clientMessage: {
      color: 'text',
      bg: 'content_card_bg',
      p: '0.5rem 1rem',
      pb: '1rem',
      my: '0.5rem',
      borderRadius: '10px',
      boxShadow: (t) => `0 2px 4px ${t.colors.shadow}`,
      display: 'flex',
      flex: 1,
    },
  },
  links: {
    default: {
      cursor: 'pointer',
    },
    bold: {
      fontWeight: 'bold',
    },
    nav: {
      px: 25,
      fontWeight: 'normal',
      cursor: 'pointer',
    },
    footer: {
      display: 'block',
      px: 0,
      color: 'inherit',
      textDecoration: 'none',
    },
    logo: {
      display: 'flex',
    },
  },
  images: {
    avatar: {
      width: 48,
      height: 48,
      borderRadius: 99999,
    },
  },
  // variants for buttons
  buttons: {
    menu: {}, // default variant for MenuButton
    icon: {
      mx: 'calc(0.5rem + 5px)',
      size: 36,
    },
    // you can reference other values defined in the theme
    defaultBtn: {
      borderRadius: '10px',
      fontSize: ['14px', 1, null, null, 2],
      letterSpacings: ['-0.5px', null, null, null, '-0.15px'],
      padding: ['11px 20px 10px', null, null, null, '13px 30px'],
      fontFamily: 'body',
      cursor: 'pointer',
      lineHeight: 1.2,
      transition: 'all 0.25s',
      fontWeight: 500,
      '&:focus': {
        outline: 0,
      },
    },
    disabledButton: {
      bg: 'button_bg_pressed',
      cursor: 'not-allowed',
      color: 'text_disabled',
      '&:hover': {
        bg: 'button_bg_pressed',
        boxShadow: '0 0 0 transparent',
      },
    },
    menuSwitchButton: {
      variant: 'buttons.defaultBtn',
      bg: 'button_bg',
      mx: '5px',
      borderRadius: '8px',
      flex: 1,
      transition: '0.3s',
      color: 'text_secondary',
      borderBottomWidth: '1px',
      borderBottomColor: 'background',
      borderBottomStyle: 'solid',
      border: (t) => `0.5px solid ${t.colors.border_color}`,
      '&:hover': {
        // boxShadow: 'rgba(244, 199, 20, 1) 0px 1px 0px 0px',
        bg: 'button_bg_pressed',
        boxShadow: (t) => `0 2px 4px ${t.colors.shadow}`,
        border: '0.5px solid transparent',
      },
    },
    dropdown: {
      borderRadius: 0,
      width: '100%',
      transition: '0.4s',
      backgroundColor: 'transparent',
      color: 'black',
      py: 0,
      textAlign: 'left',
    },
    primary: {
      variant: 'buttons.defaultBtn',
      color: 'text',
      bg: 'button_bg',
      borderRadius: '8px',
      border: (t) => `0.5px solid ${t.colors.border_color}`,
      '&:hover': {
        bg: 'button_bg_pressed',
        boxShadow: (t) => `0 2px 4px ${t.colors.shadow}`,
        border: '0.5px solid transparent',
      },
    },
    mini: {
      variant: 'buttons.primary',
      borderRadius: '5px',
      fontSize: ['11px', 1, null, null, 1],
      padding: ['5px 20px 5px', null, null, null, '5px 20px'],
    },
    disabledMiniButton: {
      variant: 'buttons.mini',
      bg: 'button_bg_pressed',
      cursor: 'not-allowed',
      color: 'text_disabled',
      '&:hover': {
        bg: 'button_bg_pressed',
        boxShadow: '0 0 0 transparent',
      },
    },
    refreshPaymentButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      fontSize: ['12px', null, null, null, 1],
      bg: 'transparent',
      display: 'flex',
    },
    miniHeader: {
      variant: 'buttons.mini',
      fontWeight: 'heading',
      bg: 'black',
      border: '1px solid black',
      boxShadow: (t) => `0 2px 8px ${t.colors.shadow}`,
      '&:hover': {
        bg: 'black',
        border: '1px solid transparent',
      },
    },
    whiteButton: {
      variant: 'buttons.defaultBtn',
      color: 'heading_secondary',
      bg: 'white',
      '&:hover': {
        boxShadow: 'rgba(0, 0, 0, 0.5) 0px 12px 24px -10px',
      },
    },
    addButton: {
      variant: 'buttons.primary',
      padding: 0,
      width: '2.5rem',
      height: '2.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'text',
      '&:hover': {
        bg: 'button_bg_pressed',
        boxShadow: (t) => `0 2px 4px ${t.colors.shadow}`,
        border: '0.5px solid transparent',
        color: 'text_secondary',
      },
    },
    secondary: {
      variant: 'buttons.defaultBtn',
      border: '1.5px solid white',
      backgroundColor: 'transparent',
      color: 'white',
      '&:hover': {
        bg: 'white',
        color: 'heading_secondary',
      },
    },
    refresh: {
      variant: 'buttons.primary',
      position: [null, null, null, null, 'absolute'],
      right: '1rem',
      padding: ['10px 10px', null, null, null, '10px 15px', null, '13px 20px'],
      mb: '10px',
    },
    textButton: {
      variant: 'buttons.defaultBtn',
      backgroundColor: 'transparent',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      svg: {
        fontSize: [4, 6],
        mr: 2,
      },
    },
    iconShowPasswordButton: {
      backgroundColor: 'transparent',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      cursor: 'pointer',
    },
    workshopTypeButton: {
      backgroundColor: 'text_secondary',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      cursor: 'pointer',
    },
  },
  messages: {
    primary: {
      backgroundColor: 'text',
      color: 'background',
      borderRadius: '45px',
      opacity: 0.8,
      textAlign: 'center',
      fontSize: 2,
    },
  },
  cards: {
    primary: {
      padding: 2,
      borderRadius: 4,
      boxShadow: (t) => `0 2px 8px ${t.colors.shadow}`,
    },
    offer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flex: ['1 1 calc(50% - 16px)', '1 1 20%'],
      minHeight: 130,
      m: 2,
      background: '#FFFFFF',
      border: '1px solid #EDEFF6',
      borderRadius: 5,
    },
    featureCard: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'row',
      p: 3,
    },
    formCard: {
      borderRadius: '10px',
      boxShadow: (t) => `0 8px 24px ${t.colors.shadow}`,
      bg: 'login_bg',
    },
    calendarCard: {
      background: 'content_card_bg',
      borderRadius: '10px',
      p: '1rem',
      boxShadow: '0 8px 24px rgba(0, 0, 0, 0.125)',
      minHeight: '70vh',
      minWidth: '90vw',
    },
    dashboardCard: {
      bg: 'card_bg',
      m: '0.5rem',
      p: '1rem',
      cursor: 'pointer',
      borderRadius: '10px',
      boxShadow: (t) => `0 8px 24px ${t.colors.shadow}`,
      minHeight: '30vh',
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        bg: 'card_bg_hover',
      },
    },
    carsCard: {
      bg: 'content_card_bg',
      m: '0.5rem',
      p: '1rem',
      borderRadius: '10px',
      boxShadow: (t) => `0 8px 24px ${t.colors.shadow}`,
      minHeight: '75vh',
      display: 'flex',
      flex: 1,
    },
    workshopCard: {
      bg: 'content_card_bg',
      m: '0.5rem',
      p: '1rem',
      borderRadius: '10px',
      boxShadow: (t) => `0 8px 24px ${t.colors.shadow}`,
      minHeight: '75vh',
      display: 'flex',
      flex: 1,
    },
    workshopInfoCard: {
      bg: 'card_bg',
      p: '1rem',
      borderRadius: '10px',
      boxShadow: (t) => `0 4px 8px ${t.colors.shadow}`,
      flex: 1,
    },
    visitsCard: {
      bg: 'content_card_bg',
      m: '0.5rem',
      p: '1rem',
      borderRadius: '10px',
      boxShadow: (t) => `0 8px 24px ${t.colors.shadow}`,
      minHeight: '75vh',
      display: 'flex',
      flex: 1,
    },
    visitPreviewCard: {
      display: 'flex',
      borderRadius: '10px',
      my: '10px',
      transition: '0.125s',
    },
    visitDetailCard: {
      bg: 'content_card_bg',
      m: '0.5rem',
      p: '1rem',
      borderRadius: '10px',
      boxShadow: (t) => `0 8px 24px ${t.colors.shadow}`,
      minHeight: '75vh',
      display: 'flex',
      flex: 1,
    },
    visitPaymentBarCard: {
      p: '0.5rem',
      bg: 'card_bg',
      borderRadius: '10px',
      boxShadow: (t) => `0 8px 24px ${t.colors.shadow}`,
      display: 'flex',
      flex: 1,
    },
    modalCard: {
      bg: 'card_bg',
      minHeight: '30vh',
      m: '0.5rem',
      p: '1rem',
      borderRadius: '10px',
      boxShadow: (t) => `0 2px 12px 2px ${t.colors.shadowModal}`,
    },
    wishListCard: {
      boxShadow: (t) => `0 4px 8px ${t.colors.shadow}`,
      flexDirection: 'column',
      bg: 'card_bg',
      p: '0.5rem',
      borderRadius: '10px',
    },
    previousTimeCard: {
      boxShadow: (t) => `0 4px 8px ${t.colors.shadow}`,
      flexDirection: 'column',
      bg: 'content_card_bg',
      p: '0.5rem',
      borderRadius: '10px',
    },
    cartConfirmationCard: {
      boxShadow: (t) => `0 4px 8px ${t.colors.shadow}`,
      flexDirection: 'column',
      bg: 'card_bg',
      p: '0.5rem',
      borderRadius: '10px',
    },
    serviceCartCard: {
      boxShadow: (t) => `0 4px 8px ${t.colors.shadow}`,
      flexDirection: 'column',
      bg: 'card_bg',
      p: '0.5rem',
      borderRadius: '10px',
    },
    servicesPriceListCard: {
      boxShadow: (t) => `0 4px 8px ${t.colors.shadow}`,
      flexDirection: 'column',
      bg: 'card_bg',
      p: '0.5rem',
      width: ['100%', null, null, null, '1024px'],
      alignSelf: 'center',
      m: '1rem auto',
      borderRadius: '10px',
    },
    reportCard: {
      boxShadow: (t) => `0 4px 8px ${t.colors.shadow}`,
      flexDirection: 'column',
      bg: 'card_bg',
      p: '0.5rem',
      borderRadius: '10px',
    },
    menuRowCard: {
      boxShadow: (t) => `0 2px 8px ${t.colors.shadow}`,
      my: '10px',
      cursor: 'pointer',
      bg: 'card_bg',
      borderRadius: '10px',
      p: '0.5rem',
    },
    searchCard: {
      flexDirection: 'column',
      bg: 'card_bg',
      px: '1rem',
      py: '0.5rem',
      my: '0.5rem',
      borderRadius: '10px',
      display: 'flex',
      flex: 1,
      boxShadow: (t) => `0 4px 8px ${t.colors.shadow}`,
    },
    historyPreviewCard: {
      display: 'flex',
      borderRadius: '10px',
      my: '10px',
      transition: '0.125s',
    },
    historyDetailCard: {
      bg: 'content_card_bg',
      m: '0.5rem',
      p: '1rem',
      borderRadius: '10px',
      boxShadow: (t) => `0 8px 24px ${t.colors.shadow}`,
      minHeight: '75vh',
      display: 'flex',
      flex: 1,
    },
    priceListCard: {
      flexDirection: 'column',
      bg: 'card_bg',
      px: '1rem',
      py: '0.5rem',
      m: '0.5rem',
      borderRadius: '10px',
      boxShadow: (t) => `0 4px 8px ${t.colors.shadow}`,
    },
    cartCard: {
      flexDirection: 'column',
      bg: 'card_bg',
      px: '1rem',
      py: '0.5rem',
      my: '0.5rem',
      borderRadius: '10px',
      boxShadow: (t) => `0 4px 8px ${t.colors.shadow}`,
    },
    tooltipCard: {
      flexDirection: 'column',
      bg: 'card_bg',
      p: '0.5rem',
      borderRadius: '5px',
      boxShadow: (t) => `0 4px 8px ${t.colors.shadowModal}`,
    },
    helpDeskCard: {
      flexDirection: 'column',
      bg: 'card_bg',
      px: '1rem',
      py: '0.5rem',
      my: '0.5rem',
      borderRadius: '10px',
      boxShadow: (t) => `0 4px 8px ${t.colors.shadow}`,
    },
    termsCard: {
      flexDirection: 'column',
      bg: 'content_card_bg',
      px: '1rem',
      py: '0.5rem',
      my: '0.5rem',
      borderRadius: '10px',
      boxShadow: (t) => `0 4px 8px ${t.colors.shadow}`,
    },
    cartWrapperCard: {
      flexDirection: 'column',
      bg: 'content_card_bg',
      p: '0.5rem',
      flex: 1,
      borderRadius: '10px',
      boxShadow: (t) => `0 4px 8px ${t.colors.shadow}`,
    },
    settingsCard: {
      bg: 'content_card_bg',
      my: '0.5rem',
      p: '1rem',
      borderRadius: '10px',
      boxShadow: (t) => `0 8px 24px ${t.colors.shadow}`,
      minHeight: '75vh',
      display: 'flex',
      flex: 1,
    },
    invoicesCard: {
      variant: 'cards.formCard',
      my: 2,
    },
    plansCard: {
      variant: 'cards.formCard',
      p: 2,
      my: 2,
    },
  },
  forms: {
    label: {
      fontSize: 1,
      fontWeight: 'bold',
    },
    input: {
      borderRadius: 8,
      borderColor: 'border_color',
      height: 60,
      fontSize: 1,
      fontFamily: 'body',
      color: 'text',
      '&:focus': {
        borderColor: 'border_color',
        boxShadow: (t) => `0 0 0 1px ${t.colors.primary}`,
        outline: 'none',
      },
      priceList: {
        borderRadius: 8,
        borderColor: 'border_color',
        height: 60,
        fontSize: 1,
        fontFamily: 'body',
        color: 'text',
        width: '200px',
        '&:focus': {
          borderColor: 'border_color',
          boxShadow: (t) => `0 0 0 1px ${t.colors.primary}`,
          outline: 'none',
        },
      },
    },
    select: {
      borderRadius: 8,
      borderColor: 'border_color',
      fontSize: 1,
      flex: 1,
      bg: 'transparent',
      fontFamily: 'body',
      color: 'text',
      width: '200px',
      '&:focus': {
        borderColor: 'border_color',
        boxShadow: (t) => `0 0 0 1px ${t.colors.primary}`,
        outline: 'none',
      },
    },
    textarea: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSmoothing: 'antialiased',
      borderRadius: 8,
      borderColor: 'border_color',
      '&:focus': {
        borderColor: 'border_color',
        boxShadow: (t) => `0 0 0 1px ${t.colors.primary}`,
        outline: 'none',
      },
    },
  },

  badges: {
    primary: {
      color: 'background',
      bg: '#28A5FF',
      borderRadius: 30,
      p: '3px 11px',
      fontSize: 1,
      letterSpacing: '-0.5px',
    },
    outline: {
      color: 'primary',
      bg: 'transparent',
      boxShadow: 'inset 0 0 0 1px',
    },
  },

  styles: {
    // To add base, top-level styles to the <body> element, use theme.styles.root.
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSmoothing: 'antialiased',
      '.modal-video-close-btn': {
        cursor: 'pointer',
        top: '-25px',
        right: '-25px',
        width: ' 25px',
        height: '25px',
      },
      '.modal-video-movie-wrap': {
        margin: 6,
        width: 'auto',
      },
    },
    // Divider styles
    hr: {
      border: 0,
      borderBottom: '1px solid',
      borderColor: 'border_color',
    },
    // also you can use other HTML elements style here
    ul: {
      listStyle: 'none',
    },
    srOnly: {
      border: '0 !important',
      clip: 'rect(1px, 1px, 1px, 1px) !important',
      clipPath: 'inset(50%) !important',
      height: '1px !important',
      margin: '-1px !important',
      overflow: 'hidden !important',
      padding: '0 !important',
      position: 'absolute !important',
      width: '1px !important',
      whiteSpace: 'nowrap !important',
    },
    h1: {
      variant: 'text.heading',
      fontSize: 5,
    },
    h2: {
      variant: 'text.heading',
      fontSize: 4,
    },
    h3: {
      variant: 'text.heading',
      fontSize: 3,
    },
    h4: {
      variant: 'text.heading',
      fontSize: 2,
    },
    h5: {
      variant: 'text.heading',
      fontSize: 1,
    },
    h6: {
      variant: 'text.heading',
      fontSize: 0,
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
  },
};
