export default {
  container: {
    flex: 1,
    p: [null, null, null, null, '0.5rem'],
    flexDirection: 'column',
    borderRadius: '10px',
    my: '1rem',
  },
  innerContainer: {
    flexDirection: 'column',
  },
  messageLabel: {
    fontSize: ['12px', '14px', '16px'],
  },
  messageContainer: {
    mb: '5px',
  },
  messageText: {
    fontSize: ['12px', '14px'],
    bg: 'content_bg',
  },
  labelContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: '5px',
  },
  editButton: {
    // color: 'black',
  },
  messageMissingText: {
    textAlign: 'center',
    // pb: '1em',
    fontSize: ['12px', '14px'],
  },
};
