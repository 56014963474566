export default {
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: (t) => `0 2px 12px 2px ${t.colors.shadowModal}`,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
  },
};
