export default {
  container: {
    position: 'fixed',
    zIndex: 1100,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    overFlow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: ['360px', null, null, null, '500px'],
  },
  closeButton: {
    position: 'absolute',
    cursor: 'pointer',
    right: '0',
    top: '0',
    color: 'text',
    p: 0,
    m: 0,
  },
  title: {
    color: 'text',
    fontSize: 4,
    mb: '5px',
  },
  divider: {
    m: '0.5rem',
  },
  content: {
    lineHeight: 1.2,
    textAlign: 'center',
  },
  button: {
    mt: '1rem',
  },
  row: {
    flex: 1,
    width: '100%',
    px: '10px',
    my: '3px',
    bg: 'content_card_bg',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      bg: 'card_bg_hover',
    },
  },
  colorBox: {
    width: '30px',
    height: '30px',
    borderRadius: '5px',
  },
};
