import PropTypes from 'prop-types';
import { Card, Divider, Flex, Text } from 'theme-ui';

import { STRINGS } from '../../../constants';
import styles from './WishListCard.style';

const WishListCard = (props) => {
  const { wishList, showPrices, cardStyle } = props;
  const localeString = 'pl';
  const localeCurrency = 'PLN';

  const emptyPrice = () => {
    return <Text sx={styles.emptyPriceText}>-</Text>;
  };

  const convertPrice = (price) => {
    return price
      ? new Intl.NumberFormat(localeString, { style: 'currency', currency: localeCurrency }).format(
          parseFloat(price),
        )
      : undefined;
  };

  const renderItem = (id, name, typ, quantity, priceEachWithFee, priceTotalWithFee) => {
    const formattedPriceEachWithFee = convertPrice(priceEachWithFee);
    const formattedPriceTotalWithFee = convertPrice(priceTotalWithFee);

    return (
      <Flex key={id} sx={styles.wishListItemContainer}>
        <Flex sx={styles.itemRowContainer}>
          <Flex sx={styles.itemNameContainer}>
            <Text sx={styles.itemNameText}>
              {(typ && STRINGS.REGULAR_SERVICES[typ][name]) ?? name}
            </Text>
          </Flex>
          <Flex>
            {showPrices ? (
              <Text sx={styles.itemText}>{formattedPriceTotalWithFee}</Text>
            ) : (
              emptyPrice()
            )}
          </Flex>
        </Flex>
        <Flex sx={styles.itemDetailsRowsOuterContainer}>
          <Flex sx={styles.itemDetailsRowContainer}>
            <Text sx={styles.itemQuantityText}>{STRINGS.visit.pricePerItem}</Text>
            {showPrices ? (
              <Text sx={styles.itemQuantityResultText}>{formattedPriceEachWithFee}</Text>
            ) : (
              emptyPrice()
            )}
          </Flex>
          <Flex sx={styles.itemDetailsRowContainer}>
            <Text sx={styles.itemQuantityText}>{STRINGS.visit.quantity}</Text>
            <Text sx={styles.itemQuantityResultText}>{quantity}</Text>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const renderHeader = () => {
    if (wishList?.items.length > 0) {
      return (
        <Flex sx={styles.itemHeaderContainer}>
          <Text sx={styles.headerText}>{STRINGS.visit.item}</Text>
          <Text sx={styles.headerText}>{STRINGS.visit.priceFrom}</Text>
        </Flex>
      );
    }
    return (
      <Flex sx={styles.emptyLabelContainer}>
        <Text sx={styles.itemText}>{STRINGS.visit.emptyWishList}</Text>
      </Flex>
    );
  };

  return (
    <Flex sx={styles.mainContainer}>
      <Card variant="wishListCard" sx={styles.contentContainer}>
        <Flex sx={styles.labelContainer}>
          <Text sx={styles.label}>{STRINGS.visit.wishList}</Text>
        </Flex>
        <Flex sx={{ ...styles.container, ...cardStyle }}>
          <Flex sx={styles.cartContainer}>
            {renderHeader()}
            <Divider />
            {wishList?.items.map((item) => {
              return renderItem(
                item?.id,
                item?.name,
                item?.typ,
                item?.quantity,
                item?.priceEachWithFee,
                item?.priceTotalWithFee,
              );
            })}
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
};

WishListCard.propTypes = {
  wishList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  showPrices: PropTypes.bool,
  cardStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

WishListCard.defaultProps = {
  cardStyle: {},
  showPrices: false,
};

export default WishListCard;
