import { Card, Flex, Text } from 'theme-ui';

import { STRINGS } from '../../../constants';
import { getDayFromDate } from '../../../utils/formatHours';
import styles from './HelpDeskTicketCard.style';

const HelpDeskTicketCard = (props) => {
  const { item } = props;
  const { title, category, content, date } = item;

  const renderDate = (ticketDate) => {
    const convertedDate = getDayFromDate(new Date(ticketDate));
    const { date: dateValue, month, year } = convertedDate;
    return (
      <Flex sx={styles.dateContainer}>
        <Text sx={styles.dateValue}>{dateValue}</Text>
        <Text sx={styles.monthValue}>{month}</Text>
        <Text sx={styles.yearValue}>{year}</Text>
      </Flex>
    );
  };

  const renderHeader = () => {
    return (
      <Flex sx={styles.headerContainer}>
        <Flex sx={styles.infoContainer}>
          <Text variant="heading" sx={styles.title}>
            {title}
          </Text>
          <Flex sx={styles.categoryContainer}>
            <Text sx={styles.labelText}>{STRINGS.settings.category}</Text>
            <Text sx={styles.categoryText}>{STRINGS.HELPDESK_TYPES[category]}</Text>
          </Flex>
        </Flex>
        {renderDate(date)}
      </Flex>
    );
  };

  return (
    <Card variant="helpDeskCard" sx={styles.container}>
      {renderHeader()}
      <Flex sx={styles.contentContainer}>
        <Text sx={styles.value}>{content}</Text>
      </Flex>
    </Card>
  );
};

HelpDeskTicketCard.propTypes = {};

export default HelpDeskTicketCard;
