/* eslint-disable no-unused-vars */
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Input,
  Label,
  Spinner,
  Text,
  useColorMode,
} from 'theme-ui';

import { STRINGS } from '../constants';
import styles, { cardElementOptions, darkCardElementOptions } from './CheckoutForm.style';

export default function CheckoutForm(props) {
  const {
    csrfToken,
    handleCardChange,
    createPaymentHandler,
    cardError,
    formError,
    credentialError,
    isLoading,
    isReady,
    error,
  } = props;
  const {
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({ reValidateMode: 'onSubmit' });
  const stripe = useStripe();
  const elements = useElements();
  const [colorMode, setColorMode] = useColorMode();

  const submitForm = async (data, e) => {
    e.preventDefault();
    const card = elements.getElement(CardElement);

    const { paymentMethod, error: stripeError } = await stripe.createPaymentMethod({
      type: 'card',
      card,
    });
    await createPaymentHandler(paymentMethod.id, stripeError);
  };

  return (
    <Card variant="formCard" sx={styles.containerBox}>
      <Heading variant="hero" sx={styles.title}>
        {STRINGS.settings.subscription}
      </Heading>
      <Flex sx={styles.credentialErrorBox}>
        {credentialError && (
          <Text variant="error" sx={styles.credentialErrorText}>
            {credentialError}
          </Text>
        )}
      </Flex>
      {isLoading ? (
        <Flex sx={styles.spinnerContainer}>
          <Spinner size={60} />
        </Flex>
      ) : (
        <Box as="form" onSubmit={handleSubmit(submitForm)}>
          <Input name="csrfToken" type="hidden" defaultValue={csrfToken} />
          <Grid sx={styles.grid}>
            <Box sx={styles.inputBox}>
              <Label sx={styles.cardLabel} htmlFor="card-element">
                {STRINGS.settings.subscriptionCardLabel}
              </Label>
              <Box sx={styles.cardContainer}>
                <CardElement
                  id="card-element"
                  onChange={handleCardChange}
                  options={colorMode === 'light' ? cardElementOptions : darkCardElementOptions}
                />
              </Box>
              {cardError && (
                <Text className="card-errors" variant="error">
                  {cardError}
                </Text>
              )}
            </Box>
          </Grid>
          <Flex sx={styles.submitBox}>
            {!isReady && (
              <Text className="card-errors" variant="error">
                {STRINGS.settings.missingInvoiceDate}
              </Text>
            )}
            {!isSubmitting && !isSubmitSuccessful ? (
              <Button
                type="submit"
                variant="primary"
                disabled={!isReady}
                sx={isReady ? styles.button : styles.disabledButton}
              >
                {STRINGS.settings.subscriptionCheckoutButton}
              </Button>
            ) : (
              <Button type="submit" variant="primary">
                {STRINGS.settings.subscriptionCheckoutButton}
              </Button>
            )}
          </Flex>
          <Flex sx={styles.errorBox}>{formError && <Text variant="error">{formError}</Text>}</Flex>
        </Box>
      )}
    </Card>
  );
}
