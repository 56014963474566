import PropTypes from 'prop-types';
import { useState } from 'react';
import { IoIosCall } from 'react-icons/io';
import { Flex, Text } from 'theme-ui';

import { STRINGS } from '../../../constants';
import styles from './ClientsPhoneCard.style';

const ClientsPhoneCard = (props) => {
  const { number, color } = props;
  const [open, setOpen] = useState(false);

  if (number) {
    return (
      <Flex sx={styles.container}>
        {open && (
          <Flex sx={styles.innerContainer}>
            <Text sx={styles.infoText}>{STRINGS.visit.clientsNumber}</Text>
            <Text sx={styles.numberText}>{number}</Text>
          </Flex>
        )}
        <Flex
          id="phoneButton"
          onClick={() => setOpen((prev) => !prev)}
          sx={{ ...styles.iconContainer, ...{ backgroundColor: color } }}
        >
          <IoIosCall sx={styles.icon} size={30} />
        </Flex>
      </Flex>
    );
  }

  return null;
};

ClientsPhoneCard.propTypes = {
  number: PropTypes.string.isRequired,
  color: PropTypes.string,
};

ClientsPhoneCard.defaultProps = {
  color: 'background_secondary',
};

export default ClientsPhoneCard;
