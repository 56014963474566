export default {
  screen: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100vw',
    minHeight: '100vh',
  },
  pageContainer: {
    p: '20px',
    width: ['100vh', null, null, null, 'calc(100vw - 250px)'],
    transition: '0.5s',
    '&.closed': {
      width: '100vw',
    },
  },
  pageInnerContainer: {
    '&.closed': {
      ml: [null, null, null, null, '-10px', '-60px'],
    },
  },
};
