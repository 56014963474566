import { STRINGS } from '../constants';

export const getTimeFromDate = (date) => {
  if (date instanceof Date) {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  }
  return date;
};

export const getOpeningHours = (data) => {
  if (!data) {
    return [STRINGS.workshop.noInfoText];
  }

  const dayValue = data[0];

  if (dayValue?.closed) {
    return [STRINGS.workshop.closed];
  }
  if (dayValue?.opened24h) {
    return [STRINGS.workshop.open24h];
  }

  const openingHours = data.reduce((filtered, item) => {
    if (item?.opensAt && item?.closesAt) {
      filtered.push(`${item.opensAt} - ${item.closesAt}`);
    }
    return filtered;
  }, []);
  return openingHours;
};

export const getDayFromDate = (date) => {
  if (date instanceof Date) {
    const utcDate = String(date.getDate()).padStart(2, '0');
    const utcMonth = STRINGS.MONTHS[date.getMonth()];
    const day = date.getDay();
    const utcDay = STRINGS.WEEK_DAYS[day === 0 ? 6 : day - 1];
    const utcYear = date.getFullYear();
    return {
      // eslint-disable-next-line no-restricted-globals
      date: isNaN(utcDate) ? undefined : utcDate,
      month: utcMonth,
      day: utcDay,
      // eslint-disable-next-line no-restricted-globals
      year: isNaN(utcYear) ? undefined : utcYear,
    };
  }
  return date;
};

export const getRoundedDate = (minutes, date = new Date()) => {
  const milliseconds = 1000 * 60 * minutes;
  const roundedDate = new Date(Math.round(date.getTime() / milliseconds) * milliseconds);
  return roundedDate;
};
