export default {
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    px: [0, '10px'],
    flex: 1,
  },
  containerNoLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    px: [0, '10px'],
    flex: 1,
  },
  labelContainer: {},
  labelText: {
    color: 'text',
    fontSize: ['12px', '14px', '16px'],
  },
  timeDateContainer: {
    justifyContent: 'center',
  },
  timeContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    mx: '10px',
    flex: 1,
  },
  dateContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    mx: ['10px', null, null, null, '2rem'],
    flex: 1,
  },
  dateYearContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    ml: '5px',
    flex: 1,
  },
  dateValue: {
    fontWeight: 'bold',
    lineHeight: '10px',
    color: 'text',
    fontSize: ['12px', '14px', '16px'],
  },
  dayValue: {
    color: 'text',
    fontSize: ['12px', '14px', '16px'],
  },
  timeValue: {
    fontWeight: 'bold',
    lineHeight: '10px',
    color: 'text',
    fontSize: ['12px', '14px', '16px'],
  },
  monthValue: {
    color: 'text',
    fontSize: ['12px', '14px', '16px'],
  },
  yearValue: {
    mx: '10px',
    color: 'text',
    fontSize: ['12px', '14px', '16px'],
  },
};
