export default {
  container: {
    mx: '1rem',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  dateContainer: {
    alignItems: 'center',
    flexDirection: 'column',
  },
  dateValue: {
    fontSize: 10,
    lineHeight: 1.2,
  },
  yearValue: {
    lineHeight: 1.2,
    fontSize: 5,
  },
  monthValue: {
    lineHeight: 1.2,
    fontSize: 1,
  },
};
