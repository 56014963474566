/* eslint-disable no-unused-vars */
import Router from 'next/router';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';
import {
  Box,
  Button,
  Card,
  Container,
  Flex,
  Grid,
  Heading,
  Input,
  Label,
  Text,
  jsx,
} from 'theme-ui';

import { STRINGS, URLS } from '../constants';
import styles from './LoginForm.style';
import { Link } from './others/Link';

export default function LoginForm(props) {
  const { csrfToken, signIn, error } = props;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({ reValidateMode: 'onSubmit' });

  const [formError, setFormError] = useState(null);
  const [credentialError, setCredentialError] = useState(null);
  const [showFirstField, setShowFirstField] = useState(false);

  async function submitForm(data) {
    const url = new URL(window.location.href);
    const redirectUrl = url.searchParams.get('callbackUrl');

    const res = await signIn('credentials', {
      redirect: !redirectUrl,
      email: data.email,
      password: data.password,
      callbackUrl: `${window.location.href}`,
    });
    if (res?.error) {
      setCredentialError(STRINGS.NEXT_AUTH_ERRORS[res.error] ?? res.error);
      setFormError(null);
    } else {
      setCredentialError(null);
      setFormError(null);
      if (redirectUrl) {
        Router.push(`${redirectUrl ?? ''}`);
      }
    }
  }

  useEffect(() => {
    if (error) {
      setCredentialError(STRINGS.NEXT_AUTH_ERRORS[error]);
    } else {
      setCredentialError(null);
    }
  }, [error]);

  const renderTerms = () => {
    return (
      <Flex sx={styles.termsContainer}>
        <Text sx={styles.termsInfo}>
          {STRINGS.auth.termsInfoFirst}
          <Link
            path={URLS.TERMS_WORKSHOP_URL}
            href={URLS.TERMS_WORKSHOP_URL}
            sx={styles.termsButtonText}
            target="_blank"
          >
            {STRINGS.auth.termsInfoTerms}
          </Link>
          {STRINGS.auth.termsInfoAnd}
          <Link
            path={URLS.POLICY_WORKSHOP_URL}
            href={URLS.POLICY_WORKSHOP_URL}
            sx={styles.termsButtonText}
            target="_blank"
          >
            {STRINGS.auth.termsInfoPolicy}
          </Link>
          {STRINGS.auth.termsInfoSecond}
        </Text>
      </Flex>
    );
  };

  const renderShowButton = (showState, action) => {
    return (
      <Button
        onClick={() => action(!showState)}
        type="button"
        variant="iconShowPasswordButton"
        sx={styles.iconButton}
      >
        <Flex>
          {showState ? (
            <IoIosEyeOff id="closedEye" sx={styles.iconButton.icon} size={20} />
          ) : (
            <IoIosEye id="openedEyeEye" sx={styles.iconButton.icon} size={20} />
          )}
        </Flex>
      </Button>
    );
  };

  return (
    <>
      <Card variant="formCard" sx={styles.containerBox}>
        <Heading variant="heroPrimary" sx={styles.title}>
          {STRINGS.common.loginTitle}
        </Heading>
        <Flex sx={styles.credentialErrorBox}>
          {credentialError && (
            <Text variant="error" sx={styles.credentialErrorText}>
              {credentialError}
            </Text>
          )}
        </Flex>
        <Box as="form" onSubmit={handleSubmit(submitForm)}>
          <Input name="csrfToken" type="hidden" defaultValue={csrfToken} />
          <Grid sx={styles.grid}>
            <Box sx={styles.inputBox}>
              <Label htmlFor="email">{STRINGS.common.emailText}</Label>
              <Flex sx={styles.inputContainer}>
                <Input
                  name="email"
                  id="email"
                  type="email"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('email', {
                    required: true,
                  })}
                  sx={styles.inputField}
                />
              </Flex>
              {errors.email?.type === 'required' && (
                <Text variant="error">{STRINGS.common.requiredEmailField}</Text>
              )}
            </Box>
            <Box sx={styles.inputBox}>
              <Label htmlFor="password">{STRINGS.common.passwordText}</Label>
              <Flex sx={styles.inputContainer}>
                <Input
                  name="password"
                  id="password"
                  type={showFirstField ? 'text' : 'password'}
                  minLength={8}
                  maxLength={25}
                  autoComplete="off"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('password', {
                    required: true,
                  })}
                  sx={styles.inputField}
                />
                {renderShowButton(showFirstField, setShowFirstField)}
              </Flex>
              {errors.password?.type === 'required' && (
                <Text variant="error">{STRINGS.common.requiredPasswordText}</Text>
              )}
            </Box>
          </Grid>
          <Flex sx={styles.submitBox}>
            {!isSubmitting && !isSubmitSuccessful ? (
              <Button type="submit" variant="primary">
                {STRINGS.common.loginText}
              </Button>
            ) : (
              <Button type="submit" variant="primary">
                {STRINGS.common.loginText}
              </Button>
            )}
          </Flex>
          <Flex sx={styles.errorBox}>{formError && <Text variant="error">{formError}</Text>}</Flex>
        </Box>
      </Card>
      {renderTerms()}
    </>
  );
}
