import PropTypes, { instanceOf } from 'prop-types';
import { IoIosStar } from 'react-icons/io';
import { Card, Flex, Heading, Progress, Text, useColorMode } from 'theme-ui';

import { STRINGS } from '../../constants';
import { WorkshopReview } from '../../models';
import styles from './WorkshopOpinions.style';
import WorkshopReviewCard from './WorkshopReviewCard';

export default function WorkshopOpinions(props) {
  // eslint-disable-next-line no-unused-vars
  const [colorMode, setColorMode] = useColorMode();
  const { averageRate, reviews, numberOfReviews, reportReviewHandler } = props;
  const numberOfReviewString =
    numberOfReviews === 1
      ? STRINGS.workshop.reviewScreenInfoEnd
      : STRINGS.workshop.reviewScreenInfoEndSingular;
  const starCount = [1, 2, 3, 4, 5];

  const reviewsCounter = (rate) => {
    return reviews?.filter((item) => item.rate === rate).length;
  };

  const renderProgressBar = (star, numberOfCurrentReviews) => {
    const pickColor = (number) => {
      if (number <= star) return '#F4C714';
      return 'heading_secondary';
    };

    return (
      <Flex key={star} sx={styles.progress}>
        <Flex sx={styles.progress.left}>
          <p sx={styles.progress.left.count}>{star}</p>
        </Flex>
        <Flex sx={styles.progress.star}>
          {starCount.map((elem) => (
            <IoIosStar key={elem} color={pickColor(elem)} size="1rem" />
          ))}
        </Flex>
        <Progress sx={styles.progress.bar} value={numberOfCurrentReviews} max={numberOfReviews} />
        <Flex sx={styles.progress.right}>
          <p sx={styles.progress.right.count}>{numberOfCurrentReviews}</p>
        </Flex>
      </Flex>
    );
  };

  return (
    <Card variant="workshopInfoCard" sx={colorMode === 'light' ? styles.card : styles.cardDark}>
      <Flex sx={styles.container}>
        <Flex sx={styles.container.info}>
          <Heading as="h3">{STRINGS.workshop.reviewLabel}</Heading>
          <Text sx={styles.container.text}>{STRINGS.workshop.reviewInfo}</Text>
        </Flex>
        <Flex sx={styles.rating}>
          <Flex sx={styles.rating.container}>
            <Text sx={styles.rating.text}>{`${Number(averageRate).toFixed(2)}${
              STRINGS.workshop.reviewScope
            }`}</Text>
            <Flex>
              <Text sx={styles.rating.subText}> {numberOfReviews}</Text>
              <Text sx={styles.rating.subText}> {numberOfReviewString}</Text>
            </Flex>
          </Flex>
          <Flex sx={styles.rating.data}>
            {[...starCount].reverse().map((elem) => renderProgressBar(elem, reviewsCounter(elem)))}
          </Flex>
        </Flex>
      </Flex>
      <Flex sx={styles.opinions}>
        {reviews?.map((item) => {
          return <WorkshopReviewCard key={item.id} data={item} action={reportReviewHandler} />;
        })}
      </Flex>
    </Card>
  );
}

WorkshopOpinions.propTypes = {
  averageRate: PropTypes.string,
  reviews: PropTypes.arrayOf(instanceOf(WorkshopReview)),
  numberOfReviews: PropTypes.string,
  reportReviewHandler: PropTypes.func.isRequired,
};

WorkshopOpinions.defaultProps = {
  averageRate: '0',
  reviews: [],
  numberOfReviews: '0',
};
