/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import React from 'react';

import 'react-datepicker/dist/react-datepicker.css';

import { IoIosClose } from 'react-icons/io';
import { Box, Card, Flex, IconButton, Input, Spinner, Text, useColorMode } from 'theme-ui';

import { FIELD_TYPES, STRINGS } from '../../constants';
import DimLoadingIndicator from '../others/DimLoadingIndicator';
import SearchCard from '../workshop/SearchCard';
import styles from './SearchEmployeeModal.style';

const SearchEmployeeModal = (props) => {
  const { searchEmployeesHolder } = props;

  const { visible, setVisible, employeesList, onChange, action, searchIsLoading, isLoading } =
    searchEmployeesHolder;

  const [colorMode, setColorMode] = useColorMode();

  const renderInfo = () => {
    return (
      <Flex sx={styles.infoContainer}>
        <Text variant="modalTitle" sx={styles.modalTitle}>
          {STRINGS.workshop.searchEmployees}
        </Text>
        <Text style={styles.descriptionText}>{STRINGS.workshop.searchEmployeesDescription}</Text>
      </Flex>
    );
  };
  const renderResults = () => {
    if (!searchIsLoading) {
      return (
        <Flex sx={styles.listContainer}>
          {employeesList?.map((item) => (
            <SearchCard key={item?.id} data={item} action={action} />
          ))}
        </Flex>
      );
    }
    return (
      <Flex sx={styles.listSpinnerContainer}>
        <Spinner />
      </Flex>
    );
  };

  const renderInput = () => {
    return (
      <Flex sx={styles.fieldContainer}>
        <Input
          sx={colorMode === 'light' ? styles.input : styles.inputDark}
          variant="forms.input"
          onChange={(e) => onChange(e.target.value)}
        />
      </Flex>
    );
  };

  if (visible) {
    return (
      <Box variant="layout.modalBackground" sx={styles.container}>
        <Card variant="modalCard" sx={styles.modal}>
          {isLoading && <DimLoadingIndicator />}
          <IconButton onClick={() => setVisible(false)} sx={styles.closeButton}>
            <IoIosClose size={48} />
          </IconButton>
          <Flex sx={styles.contentContainer}>
            {renderInfo()}
            {renderInput()}
            {employeesList?.length !== 0 ? (
              renderResults()
            ) : (
              <Text sx={styles.notFoundText}>{STRINGS.workshop.searchEmployeesNotFound}</Text>
            )}
          </Flex>
        </Card>
      </Box>
    );
  }
  return null;
};

export default SearchEmployeeModal;
