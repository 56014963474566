import PropTypes from 'prop-types';
import { Flex, Text } from 'theme-ui';

import { getDayFromDate } from '../../../utils/formatHours';
import styles from './HistoryEntryDate.style';

const HistoryEntryDate = (props) => {
  const { scheduledTime } = props;

  if (scheduledTime) {
    const scheduledDate = new Date(scheduledTime);
    const convertedDate = getDayFromDate(scheduledDate);
    const { date, month, year } = convertedDate;

    return (
      <Flex sx={styles.container}>
        <Flex sx={styles.dateContainer}>
          <Text sx={styles.dateValue}>{date}</Text>
          <Text sx={styles.monthValue}>{month}</Text>
          <Text sx={styles.yearValue}>{year}</Text>
        </Flex>
      </Flex>
    );
  }
  return <></>;
};

HistoryEntryDate.propTypes = {
  scheduledTime: PropTypes.string,
};

HistoryEntryDate.defaultProps = {
  scheduledTime: null,
};

export default HistoryEntryDate;
