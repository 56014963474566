import PropTypes from 'prop-types';
import { Flex } from 'theme-ui';

import styles from './ColoredLeftSideBar.style';

const ColoredLeftSideBar = (props) => {
  const { color } = props;
  return <Flex sx={{ ...styles.container, ...{ backgroundColor: color } }} />;
};

ColoredLeftSideBar.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ColoredLeftSideBar;
