/* eslint-disable no-unused-vars */
import { IoLogoUsd } from 'react-icons/io';
import { Box, Card, Flex, Text } from 'theme-ui';

import {
  COLORS,
  STRINGS as COMMON_STRINGS,
  FUEL_TYPES,
  PAYMENT_STATUSES,
  VISIT_INIT_OPTIONS,
  VISIT_STATUSES,
} from '../../../constants';
import { STRINGS } from '../../../constants/workshop';
import colorSwitch from '../../../utils/visitColorSwitch';
import { Link } from '../../others/Link';
import styles from './VisitPreview.style';

const VisitPreview = (props) => {
  const { item, testID, children } = props;
  const { id, messageFromClient, status, car, initiatedBy, paymentStatus } = item ?? {};
  const { make, model, productionYear, fuel, registrationNumber } = car ?? {};
  const url = `/visits/${make}/${model}/${id}`;

  const renderPaymentInfo = () => {
    if (paymentStatus === PAYMENT_STATUSES.COMPLETED) {
      return (
        <Flex sx={styles.paymentContainer}>
          <Text sx={styles.paymentText}>{COMMON_STRINGS.VISIT_PAID_STATUS.COMPLETED}</Text>
          <IoLogoUsd size={14} />
        </Flex>
      );
    }
    return null;
  };

  const renderClientMessage = () => {
    const messageValue = messageFromClient || STRINGS.visits.messageMissing;
    return (
      <Flex sx={styles.messageAreaContainer}>
        <Text variant="clientMessage" sx={styles.messageValue}>
          {messageValue}
        </Text>
      </Flex>
    );
  };

  const renderCarInfo = () => {
    const makeModel = `${make} ${model}`;
    return (
      <Flex sx={styles.carInfoContainer}>
        <Text sx={styles.makeModel}>{makeModel}</Text>
        <Flex style={styles.carInfoRow}>
          {registrationNumber && <Text sx={styles.carInfo}>{registrationNumber}</Text>}
          {productionYear && <Text sx={styles.carInfo}>{productionYear}</Text>}
          {fuel && <Text sx={styles.carInfo}>{FUEL_TYPES[fuel]}</Text>}
        </Flex>
      </Flex>
    );
  };

  const renderVisitStatus = () => {
    const title =
      initiatedBy === VISIT_INIT_OPTIONS.INIT_WORKSHOP
        ? COMMON_STRINGS.VISIT_STATUS.WORKSHOP_WITHOUT_CUSTOMER[status]
        : COMMON_STRINGS.VISIT_STATUS.WORKSHOP[status];

    return (
      <Text
        sx={{
          ...styles.visitStatusHeader,
          ...{ color: VISIT_STATUSES.FINISHED === status ? COLORS.ACCENT : colorSwitch(status) },
        }}
      >
        {title}
      </Text>
    );
  };

  return (
    <Link key={url} path={url} href={url} sx={styles.link} target="_blank" testID={testID}>
      <Card variant="visitPreviewCard" sx={{ ...styles.container, ...{ bg: colorSwitch(status) } }}>
        <Box sx={styles.visitContent}>
          {renderVisitStatus()}
          {renderPaymentInfo()}
          <Flex sx={styles.contentContainer}>
            {renderClientMessage()}
            {children}
            <Flex sx={styles.carAndWorkshopContainer}>{renderCarInfo()}</Flex>
          </Flex>
        </Box>
      </Card>
    </Link>
  );
};

export default VisitPreview;
