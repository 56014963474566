export default {
  settings: {
    label: 'Ustawienia',
    loginText: 'Zaloguj się',
    logoutText: 'Wyloguj się',
    saveText: 'Zapisz',
    userNameText: 'Nazwa użytkownika',
    slugText: 'Nazwa użytkownika', // Change in the future to slug.
    nameText: 'Imię',
    surnameText: 'Nazwisko',
    nameSurnameText: 'Imię Nazwisko',
    bioText: 'O sobie',
    yourCardText: 'Twoja wizytówka',
    editText: 'Edycja',
    editProfileText: 'Edycja profilu',
    error400Template: 'Request failed with status code 400',
    error400Text: 'Błąd przy zapisywaniu danych',
    error404Template: 'Request failed with status code 404',
    error404Text: 'Problem z pobraniem danych',
    error500Template: 'Request failed with status code 500',
    error500Text: 'Błąd przy zapisywaniu danych',
    errorMissingCredentials: 'Missing credentials.',
    errorDeleteUser:
      'Tymczasowy problem z usunięciem konta. Proszę wysłać prośbę na adres rezygnacja@meldmoto.pl',
  },
  workshop: {
    label: 'Warsztat',
    addContactInfoText: 'Wpisz informacje kontaktowe dotyczące Twojego warsztatu',
    editWorkshopContactButtonText: 'Edytuj kontakt',
    contactPhoneText: 'Telefon kontaktowy',
    contactPhone2Text: 'Telefon kontaktowy 2',
    contactEmailText: 'Adres email',
    contactEmail2Text: 'Adres email 2',
    addPriceOfferInfoText: 'Wpisz informacje o cenniku Twojego warsztatu',
    editWorkshopPriceOfferButtonText: 'Edytuj cennik',
    manHourMinText: 'Roboczogodzina od',
    manHourMaxText: 'Roboczogodzina do',
    nameText: 'Nazwa warsztatu',
    cityText: 'Miejscowość',
    streetText: 'Nazwa ulica',
    streetNumberText: 'Numer budynku',
    postCodeText: 'Kod pocztowy',
    descriptionText: 'Opis',
    addInfoText: 'Wpisz informacje dotyczące Twojego warsztatu',
    addWorkshopButtonText: 'Dodaj warsztat',
    editWorkshopButtonText: 'Edytuj warsztat',
    addOpeningHoursInfoText: 'Ustaw godziny otwarcia',
    editWorkshopOpeningHoursButtonText: 'Edytuj godziny otwarcia',
    additionalSettingsText: 'Dodatkowe ustawienia',
    additionalSettingsButtonText: 'Przejdź do ustawień',
    confirmText: 'Potwierdź',
    cancelText: 'Zamknij',
    errorShowingWorkshop: 'Problem z wyświetleniem warsztatu',
    saveText: 'Zapisz',
    error400Text: 'Błąd przy zapisywaniu danych',
  },
  visits: {
    label: 'Wizyty',
    noVisitsInCalendarToShowText: 'Brak umówionych wizyt',
    noVisitsForDateToShowText: 'Brak wizyt na dziś\nSprawdź kalendarz',
    noVisitsOngoingToShowText: 'Brak wizyt w trakcie\nSprawdź kalendarz',
    noVisitsUnscheduledToShowText: 'Brak nowych i oczekujących wizyt\nSprawdź kalendarz',
    detailsButtonText: 'Więcej',
    refreshVisitsText: 'Kliknij aby odświeżyć',
    refreshText: 'Odśwież',
    loadMoreVisitsText: 'Załaduj więcej wizyt',
    pullToRefresh: 'Pociągnij aby odświeżyć',
    registrationNumber: 'Numer rejestracyny',
    productionYear: 'Rok produkcji',
    fuel: 'Rodzaj paliwa',
    messageMissing: 'Brak wiadomości',
    statusPending: 'Nowa',
    statusWaiting: 'Oczekuąca',
    statusAccepted: 'Zaakceptowana',
    statusRejected: 'Odrzucona',
    stautsInProgress: 'Trwa',
    statusFinished: 'Zakończona',
    deleted: 'Usunięta',
    rejectButtonText: 'Odrzuć wizytę',
    errorShowingVisitDetail: 'Problem z wyświetleniem szczegółów wizyty',
    calendarPlan: 'Plan',
    calendar: 'Kalendarz',
    loading: 'Pobieranie wizyt',
    phoneCall: 'Telefon',
    previousTime: 'Poprzednie propozycje',
  },
  cars: {
    deleteCarAlertTitle: 'Usuwanie pojazdu',
    deleteCarAlertInfo: 'Czy napewno chcesz usunąć pojazd? Nie będzie on widoczny w podglądzie',
    deleteCarAlertYes: 'Tak, usuń',
    deleteCarAlertNo: 'Nie',
    label: 'Podgląd pojazdów',
    noCars: 'Brak pojazdów',
    createVisitButtonText: 'Utwórz wizytę',
    noCarDetailSelected: 'Wybierz auto aby wyświetlić informacje',
    scanCarInAppDescription:
      'Pojazd dodasz w aplikacji mobilnej, skanując kod QR w dowodzie rejestracyjnym',
    addCarDescription: 'Kod QR znajdziesz w dowodzie rejestracyjnym',
    addCarButtonText: 'Dodaj pojazd z kodu QR',
    missingWorkshopOwnership:
      'Brak warsztatu. Załóż warsztat, by móc dodać samochód z kodu QR w dowodzie rejestracyjnym',
    scanRegistrationLabel: 'Zeskanuj dowód rejestracyjny',
    scanRegistrationInfo: 'Zeskanuj kod QR znajdujący sie na dowodzie rejestracyjnym pojazdu.',
    scanBarCodeNoCamerAccess: 'Kamera jest niedostępna',
    scanBarCodeNoCameraPermission: 'Prośba o dostęp do kamery',
    scanBarCodeForegroundText: 'Umieść kod QR kilka centymetrów przed kamerą i złap ostrość',
    scanBarCodeProcessing: 'Przetwarzam...',
    scanBarCodeForgiveAndroid: 'Na Twoim urządzeniu odczytanie kodu może zająć trochę dłużej',
    errorNotCarQRCode: 'Czy to na pewno jest kod QR z dowodu rejestracyjnego?',
    goBack: 'Wróć',
  },
  visitDetail: {
    label: 'Wizyta',
    history: 'Historia serwisowa',
    descriptionText: 'Opis',
    noServiceHistory: 'Brak wpisów w historii',
    preferredVisitTime: 'Preferencja klienta',
    visitTimePropositionsLabel: 'Propozycje terminów',
    chooseVisitTimeText: 'Zaproponuj terminy wizyty',
    chooseAlternativeVisitTimeText: 'Zaproponuj nowe terminy wizyty',
    startVisitText: 'Rozpocznij wizytę',
    completeVisitText: 'Gotowe do odbioru',
    finishVisitText: 'Zakończ wizytę',
    chooseDate: 'Wybierz',
    changeDate: 'Edytuj',
    errorMileageTemplate: 'New car mileage cannot be lower than previous.',
    errorMileageText: 'Przebieg pojazdu nie może być niższy niż poprzedni.',
    openServiceHistory: 'Zobacz historię serwisową',
    visitTimeInfo:
      'Podaj conajmniej jeden termin wizyty.\nDwa terminy będą wygodniejsze dla klienta',
  },
  common: {
    refreshing: 'Odświeżanie',
    error400Template: 'Request failed with status code 400',
    error400Text: 'Błąd przy zapisywaniu danych',
    error400TextRead: 'Problem z pobraniem danych',
    errorMissingWallet: `Missing wallets.`,
    errorMissingPaymentDetails: `Missing payment details.`,
    error404Template: 'Request failed with status code 404',
    error404Text: 'Problem z pobraniem danych',
    errorMissingCredentials: 'Missing credentials.',
  },
  WEEK_DAYS: {
    0: 'Poniedziałek',
    1: 'Wtorek',
    2: 'Środa',
    3: 'Czwartek',
    4: 'Piątek',
    5: 'Sobota',
    6: 'Niedziela',
  },
  history: {
    label: 'Historia serwisowa',
    noEntriesToShowText: 'Brak wpisów w historii',
    labelDetails: 'Szczegóły serwisu',
    loadMoreEntriesText: 'Załaduj więcej wpisów',
  },
  paymentsError: {
    bankAccountCreateError: 'Bank account creation error',
    bankAccountDeactivateError: 'Bank account deactivation error',
    naturalUserCreateError: 'Natural user creation error',
    naturalUserUpdateError: 'Natural user update error',
    legalUserCreateError: 'Legal user creation error',
    legalUserUpdateError: 'Legal user update error',
    walletCreateError: 'Wallet creation error',
    cardRegistrationCreateError: 'Card Registration creation error',
    cardRegisterError: 'Card Register error',
    cardDeactivateError: 'Card deactivation error',
    cardDirectPayInError: 'Card Direct PayIn error',
    cardWebPayInError: 'Card Web PayIn error',
    documentCreateError: 'Document creation error',
    pageCreateError: 'Page creation error',
    documentSubmitError: 'Document submitting error',
    uboDeclarationCreateError: 'Ubo Declaration creation error',
    uboDeclarationSubmitError: 'Ubo Declaration submitting error',
    uboCreateError: 'Ubo creation error',
    uboUpdateError: 'Ubo update error',
    uboDeactivationError: 'Ubo deactivation error',
    payOutCreateError: 'Bank Wire Pay Out creation error',
  },
};
