export default {
  mainContainer: {
    flexDirection: 'column',
  },
  contentContainer: {
    textAlign: 'center',
    lineHeight: 1.3,
  },
  container: {
    flexDirection: 'column',
  },
  error: {
    textAlign: 'center',
  },
  labelContainer: {
    mb: '1rem',
  },
  emptyLabelContainer: {},
  label: {
    fontSize: ['16px', null, '18px', '20px'],
  },
  cartContainer: {
    flexDirection: 'column',
  },
  itemHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: ['14px', null, '16px'],
  },
  subHeaderText: {},
  wishListItemContainer: {
    flexDirection: 'column',
    my: '5px',
  },
  itemNameContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'start',
    textAlign: 'left',
  },
  itemRowContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemDetailsRowsOuterContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: 'text_secondary',
    fontSize: ['14px', null, '16px'],
  },
  itemDetailsRowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemQuantityText: {
    flex: 1,
  },
  itemQuantityResultText: {
    ml: '10px',
  },
  itemNameText: {
    flex: 1,
  },
  itemText: {
    flex: 1,
  },
  emptyPriceText: {
    flex: 1,
  },
};
