import PropTypes from 'prop-types';
import { IoIosArrowForward } from 'react-icons/io';
import { Card, Flex, Text } from 'theme-ui';

import styles from './MenuRowElement.style';

const MenuRowElement = (props) => {
  const {
    children,
    iconContainerColor,
    text,
    action,
    marginHorizontal,
    marginVertical,
    containerStyle,
    textStyle,
  } = props;

  return (
    <Card
      variant="menuRowCard"
      onClick={action}
      sx={{ ...styles.container, ...{ mx: marginHorizontal, my: marginVertical } }}
    >
      <Flex sx={{ ...styles.valueContainer, ...containerStyle }}>
        <Flex sx={{ ...styles.iconContainer, ...{ backgroundColor: iconContainerColor } }}>
          {children}
        </Flex>
        <Flex sx={styles.textContainer}>
          <Text sx={{ ...styles.valueText, ...textStyle }}>{text}</Text>
          <IoIosArrowForward size={28} />
        </Flex>
      </Flex>
    </Card>
  );
};

MenuRowElement.propTypes = {
  text: PropTypes.string.isRequired,
  action: PropTypes.func,
  iconContainerColor: PropTypes.string,
  marginHorizontal: PropTypes.string,
  marginVertical: PropTypes.string,
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  textStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

MenuRowElement.defaultProps = {
  action: undefined,
  iconContainerColor: 'text',
  marginHorizontal: '10px',
  marginVertical: '5px',
  containerStyle: {},
  textStyle: {},
};

export default MenuRowElement;
