export default {
  mainContainer: {
    textAlign: 'center',
    flexDirection: 'column',
  },
  contentContainer: {},
  container: {
    flexDirection: 'column',
    my: '1rem',
  },
  error: {
    textAlign: 'center',
  },
  labelContainer: {
    flex: 1,
    flexDirection: 'row',
    mb: '0.5rem',
  },
  label: {
    fontSize: ['16px', null, '18px', '20px'],
  },
  reportContainer: {
    flexDirection: 'column',
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: ['14px', null, '16px'],
  },
  itemRowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  itemNameText: {
    fontSize: ['14px', null, '16px'],
  },
  itemValue: {},
  itemText: {},
  emptyItemText: {
    fontSize: ['14px', null, '16px'],
  },
  noReportContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  noReportClientContainer: {
    alignItems: 'center',
  },
  unavailableText: {
    textAlign: 'center',
  },
  createReportButton: {
    width: null,
  },
  createReportButtonText: {},
  separator: {
    m: 0,
    p: 0,
  },
};
