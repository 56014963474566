import PropTypes from 'prop-types';
import { IoIosCall, IoIosMail } from 'react-icons/io';
import { AspectRatio, Box, Card, Flex, Heading, Image, Text, useColorMode } from 'theme-ui';

import { STRINGS } from '../../constants';
import { Link } from '../others/Link';
import styles from './WorkshopInfo.style';

export default function WorkshopInfo(props) {
  // eslint-disable-next-line no-unused-vars
  const [colorMode, setColorMode] = useColorMode();
  const { workshop, contact } = props;
  const { name, image, numberOfReviews, averageRate, description } = workshop ?? {};
  const { contactEmail, contactEmail2, contactPhone, contactPhone2 } = contact ?? {};
  const numberOfReviewString =
    numberOfReviews === 1
      ? STRINGS.workshop.reviewScreenInfoEndSingular
      : STRINGS.workshop.reviewScreenInfoEnd;

  const url = `/workshop/reviews?workshop_id=${workshop.workshopId}&average_rate=${averageRate}&number_of_reviews=${numberOfReviews}&workshop_name=${name}`;

  const renderImage = () => {
    if (image && image.url) {
      return (
        <AspectRatio ratio={8 / 5} sx={styles.image}>
          <Image ratio={8 / 5} sx={styles.image} src={image.url} />
        </AspectRatio>
      );
    }
    return (
      <AspectRatio ratio={8 / 5} sx={styles.missingPhotoContainer}>
        <Text sx={styles.missingPhoto}>{STRINGS.workshop.missingPhoto}</Text>
      </AspectRatio>
    );
  };
  const renderContactPhone = () => {
    return (
      <Flex sx={styles.info.contact}>
        {contactPhone && (
          <Flex sx={styles.info.contact.container}>
            <IoIosCall sx={styles.info.contact.container.icon} />
            <Text sx={styles.info.contact.container.text}>{contactPhone}</Text>
          </Flex>
        )}
        {contactPhone2 && (
          <Flex sx={styles.info.contact.container}>
            <IoIosCall sx={styles.info.contact.container.icon} />
            <Text sx={styles.info.contact.container.text}>{contactPhone2}</Text>
          </Flex>
        )}
        {!contactPhone && !contactPhone2 && (
          <Text sx={styles.info.contact.container.text}>{STRINGS.workshop.noInfoPhone}</Text>
        )}
      </Flex>
    );
  };

  const renderContactEmail = () => {
    return (
      <Flex sx={styles.info.contact}>
        {contactEmail && (
          <Flex sx={styles.info.contact.container}>
            <IoIosMail sx={styles.info.contact.container.icon} />
            <Text sx={styles.info.contact.container.text}>{contactEmail}</Text>
          </Flex>
        )}
        {contactEmail2 && (
          <Flex sx={styles.info.contact.container}>
            <IoIosMail sx={styles.info.contact.container.icon} />
            <Text sx={styles.info.contact.container.text}>{contactEmail2}</Text>
          </Flex>
        )}
        {!contactEmail && !contactEmail2 && (
          <Text sx={styles.info.contact.container.text}>{STRINGS.workshop.noInfoEmail}</Text>
        )}
      </Flex>
    );
  };

  const renderAbout = () => {
    return (
      <Flex sx={styles.info.about}>
        <Heading as="h3">{STRINGS.workshop.descriptionLabel}</Heading>
        <Text sx={styles.info.about.description}>{description || STRINGS.workshop.noInfoText}</Text>
      </Flex>
    );
  };

  return (
    <Card variant="workshopInfoCard" sx={colorMode === 'light' ? styles.card : styles.cardDark}>
      <Flex sx={styles.centeredFlex}>
        <Box>
          {renderImage()}
          {!!numberOfReviews && (
            <Link key={url} path={url} href={url} sx={styles.link}>
              <Flex sx={styles.imageOpinionContainer}>
                <Text sx={styles.rateText}>{Number(averageRate).toFixed(2)}</Text>
                <Text sx={styles.opinionText}>{`${
                  numberOfReviews || '0'
                } ${numberOfReviewString}`}</Text>
              </Flex>
            </Link>
          )}
        </Box>
        <Flex sx={styles.info}>
          {!!numberOfReviews && (
            <Link key={url} path={url} href={url} sx={styles.link}>
              <Text sx={styles.showOpinionsText}>{STRINGS.workshop.showOpinionsText}</Text>
            </Link>
          )}
          <Heading as="h1" sx={styles.info.header}>
            {name}
          </Heading>
          <Flex sx={styles.info.contactBox}>
            {renderContactPhone()}
            {renderContactEmail()}
          </Flex>
          {renderAbout()}
        </Flex>
      </Flex>
    </Card>
  );
}

WorkshopInfo.propTypes = {
  workshop: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  contact: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
