import { keyframes } from '@emotion/react';

const wave = keyframes`
  from {
    transform: translateX(100%);
    margin-right: 10px, 
  }
  to {
    transform: translateX(10px);
    margin-right: 0, 
  }
`;

export default {
  container: {
    position: 'fixed',
    bottom: '1rem',
    right: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#000',
    height: '60px',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    zIndex: '100',
  },
  iconContainer: {
    cursor: 'pointer',
    p: '1rem',
    m: 0,
    borderRadius: '10px',
    zIndex: 20,
    color: 'background',
  },
  icon: {},
  innerContainer: {
    color: 'text',
    backgroundColor: 'background_secondary',
    flexDirection: 'column',
    height: '100%',
    p: '1rem',
    borderRadius: '10px 0 0 10px',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translateX(5px)',
    animation: `${wave} 0.5s linear alternate`,
  },
  infoText: {
    fontSize: '12px',
    lineHeight: 1.2,
    p: 0,
    m: 0,
  },
  numberText: {
    lineHeight: 1.2,
  },
};
