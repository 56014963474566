export default {
  day: {
    flex: 1,
    overflow: 'hidden',
    car: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,
      container: {
        lineHeight: 1,
        mb: '0.25rem',
        makeModel: {
          overflow: 'hidden',
          textOverflow: 'clip',
          textAlign: 'left',
          whiteSpace: 'nowrap',
          color: 'text',
          fontWeight: 'bold',
          fontSize: ['10px', '12px'],
          mr: '0.5rem',
        },
        info: {
          overflow: 'hidden',
          textOverflow: 'clip',
          textAlign: 'left',
          whiteSpace: 'nowrap',
          color: 'text',
          fontSize: ['10px', '12px'],
          mr: '0.5rem',
        },
        date: {
          overflow: 'hidden',
          textOverflow: 'clip',
          textAlign: 'left',
          whiteSpace: 'nowrap',
          color: 'text',
          fontSize: ['10px', '12px'],
          fontWeight: 'bold',
          mr: '0.5rem',
        },
      },
    },
    status: {
      px: '0.25rem',
      py: '0.05rem',
      borderRadius: '5px',
      border: '5px solid',
      minWidth: 30,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      text: {
        maxWidth: ['150px', null, null, null, null, '200px'],
        textAlign: 'center',
        fontWeight: 'bold',
        color: 'text',
        fontSize: ['10px', '12px'],
      },
      inverseText: {
        textAlign: 'center',
        fontWeight: 'bold',
        color: 'calendar_text',
        fontSize: ['10px', '12px'],
      },
      infoPayment: {
        overflow: 'hidden',
        textOverflow: 'clip',
        whiteSpace: 'nowrap',
      },
    },
  },

  week: {
    flex: 1,
    overflow: 'hidden',
    car: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,
      container: {
        lineHeight: 1,
        mb: '0.25rem',
        inner: {
          flexDirection: 'column',
        },
        makeModel: {
          overflow: 'hidden',
          textOverflow: 'clip',
          textAlign: 'left',
          whiteSpace: 'nowrap',
          color: 'text',
          fontSize: ['8px', '10px'],
          mr: '0.5rem',
        },
        info: {
          overflow: 'hidden',
          textOverflow: 'clip',
          textAlign: 'left',
          whiteSpace: 'nowrap',
          color: 'text',
          fontSize: ['8px', '10px'],
          mr: '0.5rem',
        },
        date: {
          overflow: 'hidden',
          textOverflow: 'clip',
          textAlign: 'left',
          whiteSpace: 'nowrap',
          color: 'text',
          fontWeight: 'bold',
          fontSize: ['8px', '10px'],
          mr: '0.5rem',
        },
      },
    },
    status: {
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
      border: '5px solid',
      minWidth: 15,
      justifyContent: 'center',
    },
  },

  month: {
    flex: 1,
    bg: (t) => t.colors.calendar_event_bg,
    overflow: 'hidden',
    car: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,
      container: {
        overflow: 'hidden',
        lineHeight: 1,
        mb: '0.25rem',
        makeModel: {
          overflow: 'hidden',
          textOverflow: 'clip',
          textAlign: 'left',
          whiteSpace: 'nowrap',
          color: 'text',
          fontSize: ['8px', '10px'],
          mr: '0.5rem',
        },
        info: {
          overflow: 'hidden',
          textOverflow: 'clip',
          textAlign: 'left',
          whiteSpace: 'nowrap',
          color: 'text',
          fontSize: ['8px', '10px'],
          mr: '0.5rem',
        },
        date: {
          overflow: 'hidden',
          textOverflow: 'clip',
          textAlign: 'left',
          whiteSpace: 'nowrap',
          color: 'text',
          fontSize: ['8px', '10px'],
          fontWeight: 'bold',
          mr: '0.5rem',
        },
      },
    },
    status: {
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
      border: '5px solid',
      minWidth: 15,
      justifyContent: 'center',
    },
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: 'text',
    },
  },
  paymentStatusContainer: {
    mx: '-1rem',
    color: 'calendar_text',
  },
};
