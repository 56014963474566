/* eslint-disable react/jsx-props-no-spreading */
import NextLink from 'next/link';
import { Link as A, NavLink as MenuLink } from 'theme-ui';

export function NavLink({ path, label, children, ...rest }) {
  return (
    <NextLink href={path}>
      <MenuLink {...rest}>{children || label}</MenuLink>
    </NextLink>
  );
}
export function Link({ path, label, children, ...rest }) {
  return (
    <A {...rest} href={path}>
      {children || label}
    </A>
  );
}
