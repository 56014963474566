import PropTypes from 'prop-types';
import { IoIosPerson, IoMdCheckmark, IoMdClose, IoMdMore } from 'react-icons/io';
import { AspectRatio, Box, Flex, Image, Switch, Text } from 'theme-ui';

import { STRINGS } from '../../constants';
import DropDown from '../others/DropDown';
import styles from './EmployeeCard.style';

const EmployeeCard = (props) => {
  const {
    data,
    editActiveEmployeeHandler,
    editOwnerEmployeeHandler,
    deleteEmployeeHandler,
    isOwner,
  } = props;
  const { id, email, name, surname, image, employeeOwner, employeeActive } = data ?? {};

  const renderImage = () => {
    if (image) {
      return (
        <Box sx={styles.outerImageContainer}>
          <AspectRatio ratio={1 / 1} sx={styles.imageContainer}>
            <Image sx={styles.image} src={image.url} />
          </AspectRatio>
        </Box>
      );
    }
    return (
      <Box sx={styles.outerImageContainer}>
        <AspectRatio ratio={1 / 1} sx={styles.imageContainer}>
          <IoIosPerson size={36} color="#000" />
        </AspectRatio>
      </Box>
    );
  };

  const renderEmployeeName = () => {
    if (name?.length === 0 && surname?.length === 0) {
      return email;
    }
    return `${name} ${surname}`;
  };

  const renderNameAndActionButton = () => {
    return (
      <Flex sx={styles.nameAndActionContainer}>
        <Text sx={employeeActive ? styles.nameText : styles.nameTextInactive}>
          {renderEmployeeName()}
        </Text>
        {/* <Button variant="mini" sx={styles.delete} onClick={() => deleteEmployeeHandler(id)}>
            {STRINGS.workshop.deleteText}
          </Button> */}
        {!employeeOwner && isOwner && (
          <DropDown
            items={[{ action: () => deleteEmployeeHandler(id), name: STRINGS.workshop.deleteText }]}
          >
            <Flex sx={{ color: 'text' }}>
              <IoMdMore size={22} />
            </Flex>
          </DropDown>
        )}
      </Flex>
    );
  };

  const renderSwitch = (value, action) => {
    return <Switch onChange={() => action(id, value)} checked={value} testID="switch" />;
  };

  const renderStatus = (label, value, action, icon) => {
    return (
      <Flex sx={styles.switchContainer}>
        <Text sx={employeeActive ? styles.switchLabel : styles.switchLabelInactive}>{label}</Text>
        <Box>{isOwner ? renderSwitch(value, action) : icon}</Box>
      </Flex>
    );
  };

  const renderEmployeeInfo = () => {
    const iconActive = employeeActive ? <IoMdCheckmark /> : <IoMdClose />;
    const iconOwner = employeeOwner ? <IoMdCheckmark /> : <IoMdClose />;
    return (
      <Flex sx={styles.employeeInfoContainer}>
        {renderNameAndActionButton()}
        {renderStatus(
          STRINGS.workshop.activeText,
          employeeActive,
          editActiveEmployeeHandler,
          iconActive,
        )}
        {renderStatus(
          STRINGS.workshop.ownerText,
          employeeOwner,
          editOwnerEmployeeHandler,
          iconOwner,
        )}
      </Flex>
    );
  };

  return (
    <Flex key={id} sx={employeeActive ? styles.container : styles.containerInactive}>
      <Flex sx={styles.leftContainer}>{renderImage()}</Flex>
      {renderEmployeeInfo()}
    </Flex>
  );
};

EmployeeCard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  editActiveEmployeeHandler: PropTypes.func.isRequired,
  editOwnerEmployeeHandler: PropTypes.func.isRequired,
  deleteEmployeeHandler: PropTypes.func.isRequired,
  isOwner: PropTypes.bool.isRequired,
};

export default EmployeeCard;
