import { Flex, Text } from 'theme-ui';

import { getDayFromDate, getTimeFromDate } from '../../../utils/formatHours';
import styles from './AppointmentTime.style';

const AppointmentTime = (props) => {
  const { label, showYear = true, scheduledTime, isCalendar, labelStyle } = props;

  if (scheduledTime) {
    const scheduledDate = new Date(scheduledTime);
    const time = getTimeFromDate(scheduledDate);
    const convertedDate = getDayFromDate(scheduledDate);
    const { date, month, day, year } = convertedDate;

    if (!isCalendar) {
      return (
        <Flex sx={label ? styles.container : styles.containerNoLabel}>
          {label && (
            <Flex sx={{ ...styles.labelContainer, ...labelStyle }}>
              <Text sx={styles.labelText}>{label}</Text>
            </Flex>
          )}
          <Flex sx={styles.timeDateContainer}>
            <Flex sx={styles.timeContainer}>
              <Text sx={styles.timeValue}>{time}</Text>
              <Text sx={styles.dayValue}>{day}</Text>
            </Flex>
            <Flex sx={styles.dateYearContainer}>
              <Flex sx={styles.dateContainer}>
                <Text sx={styles.dateValue}>{date}</Text>
                <Text sx={styles.monthValue}>{month}</Text>
              </Flex>
              {showYear && <Text sx={styles.yearValue}>{year}</Text>}
            </Flex>
          </Flex>
        </Flex>
      );
    }
    return (
      <Flex sx={styles.container}>
        <Flex>
          <Text sx={styles.labelText}>{label}</Text>
        </Flex>
        <Flex sx={styles.timeContainer}>
          <Text sx={styles.timeValue}>{time}</Text>
        </Flex>
      </Flex>
    );
  }
  return <></>;
};

export default AppointmentTime;
